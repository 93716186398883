import { Typography } from '@mui/material';

export const AttributeTokens = ({ data, tt, hideLabel = false }) => {
  const getASANumber = (v) => {
    try {
      let ret = v.split('asset/')
      return ret[1]
    } catch {
      return 'NFT'
    }
  }

  return (
    <>
      {!hideLabel && (
        <Typography color="text.secondary" variant="h3" marginBottom={1}>
          {tt(`qrTemplate.${data.label}`)}
        </Typography>
      )}
      {Array.isArray(data.value) ?
        <div style={{ display: 'flex', flexFlow: 'wrap', width: '100%' }} className='row-content'>
          {data.value.map((v, index) => {
            return (
              <div style={{ background: 'lightgrey', fontSize: '0.8rem', marginRight: '0.5rem', marginBottom: '0.5rem', padding: '0.3rem', borderRadius: '0.5rem' }} key={index}>
                <a style={{ color: '#000', textDecoration: 'none' }}
                  href={v.value}
                  target='_blank' rel="noreferrer">{v.label}</a>
              </div>
            )
          })}
        </div>
        :
        <div style={{ display: 'flex', flexFlow: 'wrap', width: '100%' }} className='row-content'>

          <div style={{ background: 'lightgrey', fontSize: '0.8rem', marginRight: '0.5rem', marginBottom: '0.5rem', padding: '0.3rem', borderRadius: '0.5rem' }} >
            <a style={{ color: '#000', textDecoration: 'none' }}
              href={data.value}
              target='_blank' rel="noreferrer"> Algorand NFT {getASANumber(data.value)}
            </a>
          </div>
        </div>
      }
    </>
  );
};
