import { faMarker, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, InputLabel, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_POINT } from '../../../constants';
import BasicMarker from '../BasicMarker';
import { useTranslation } from 'react-i18next';

function LocationField({ disabled, error, label, name, onChange, value, xs, modeSatelite, helpiLabel, form}) {
  const { t } = useTranslation()
  const [geoJson, setGeoJson] = useState(null);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [markerVisible, setMarkerVisible] = useState(null);
  const [disabledAdd, setDisabledAdd] = useState(null);
  const [disabledDelete, setDisabledDelete] = useState(null);
  const [defaultPoint, setDefaultPoint] = useState(DEFAULT_POINT);
  const [uniqueInStep, setUniqueInStep] = useState(false);

  const updateStates = (geo) => {
    const [lng, lat] = geo?.geometry?.coordinates;
    setLatitude(lat);
    setLongitude(lng);
    setGeoJson(geo);
  };

  const handleChange = (locationTemp) => {
    updateStates(locationTemp);
    onChange(name, JSON.stringify(locationTemp));
  };

  const handleLatChange = (val) => {
    const newGeoJson = { ...geoJson };
    const lat = parseFloat(val?.currentTarget?.value);
    if (Number(lat)===lat){
      newGeoJson.geometry.coordinates[1] = lat;
      handleChange(newGeoJson);
    }
  };

  const handleLongChange = (val) => {
    const newGeoJson = { ...geoJson };
    const long = parseFloat(val?.currentTarget?.value);
    if (Number(long)===long){
      newGeoJson.geometry.coordinates[0] = long;
      handleChange(newGeoJson);
    }
  };

  const handleAdd = () => {
    setMarkerVisible(true);
    setDisabledAdd(true);
    setDisabledDelete(false);
    const geo = defaultPoint;
    handleChange(geo);
  };

  const handleDelete = () => {
    setMarkerVisible(false);
    setDisabledAdd(false);
    setDisabledDelete(true);
    setGeoJson(null);
    onChange(name, '');
  };

  const evaluateHR = () => {
    let flagUnique = false;
    if (form?.config!=null) {
      for (let step of form.config) {
        if (step.sections != null) {
          for (let section of step.sections) {
            if (section.fields != null) {
              for (let field of section.fields) {
                if (name===field) {
                  if (section.fields?.length===1) {
                      flagUnique=true;
                  }
                }
              }
            }
          }
        }
      }
    }
    setUniqueInStep(flagUnique)
  }


  useEffect(() => {
    setMarkerVisible(false);
    setDisabledAdd(false);
    setDisabledDelete(true);
    evaluateHR();
  }, []);

  useEffect(() => {
    if (value && value !== '') {
      try { 
        const geo =  JSON.parse(value)
        setMarkerVisible(true);
        setDisabledAdd(true);
        setDisabledDelete(false);
        updateStates(geo);
      } catch {
      };

    }
  }, [value]);

  return (
    <>
      {uniqueInStep===false &&
      <hr style={{marginTop:"5px",marginBottom:"5px"}}/>
      }
      <Grid item sx={{ pr: 2, mb: 2 }} xs={xs}>
        <Grid item sx={{ pr: 2, mb: 2 }} xs={xs}>
          <Grid item sx={{ pr: 2, mb: 2 }} xs={xs}>
            <InputLabel id={`demo-controlled-open-select-label-${name}`}>
              {label} <small>{t("stepByStep.msg.dragAndDropMarker")}</small>
            </InputLabel>
            {helpiLabel!=null &&
            <small style={{color:'blue',fontWeight:'500'}}>{helpiLabel}</small>
            }
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center">
            <Button
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faMarker} />}
              disabled={disabledAdd}
              onClick={handleAdd}>
              {t("stepByStep.button.addMarker")}
            </Button>
            <Button
              className="ml-1"
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faTrash} />}
              disabled={disabledDelete}
              onClick={handleDelete}>
              {t("stepByStep.button.deleteMarker")}
            </Button>
          </Grid>
        </Grid>
        <BasicMarker
          updateLocation={handleChange}
          markerVisible={markerVisible}
          onChangeCenter={(center)=>{setDefaultPoint(center)}}
          modeSatelite={modeSatelite}
          name={name}
          geoJson={geoJson}
        />
      </Grid>
      {markerVisible && (
        <>
          <Grid item sx={{ pr: 2, mb: 2 }} xs={xs}>
            <TextField
              type="number"
              label={'Latitud'}
              sx={{ width: '100%', mr: 2 }}
              disabled={disabled}
              name={name}
              padFractionalZeros={false}
              normalizeZeros={true}
              radix=","
              mapToRadix={['.']}
              scale={2}
              mask={Number}
              value={latitude}
              onChange={handleLatChange}
            />
          </Grid>
          <Grid item sx={{ pr: 2, mb: 2 }} xs={xs}>
            <TextField
              type="number"
              label={'Longitud'}
              sx={{ width: '100%', mr: 2 }}
              disabled={disabled}
              name={name}
              scale={2}
              padFractionalZeros={false}
              normalizeZeros={true}
              radix=","
              mapToRadix={['.']}
              mask={Number}
              value={longitude}
              onChange={handleLongChange}
            />
          </Grid>
        </>
      )}
      {uniqueInStep===false &&
      <hr/>
      }
    </>
  );
}

export default LocationField;
