import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import UploadContainerEvent from '../../Components/FileUploader/BaseComponents/UploadContainerEvent';
import ManualContainerEvent from '../../Components/FileUploader/BaseComponents/ManualContainerEvent';
import useGlobalContext from '../../Hooks/useGlobalContext';
import {
  ASSETS,
  EVENTS_MASSIVE_MANUAL
} from '../../constants/paths';
import ModalErrorInfo from '../../Components/Feature/ModalErrorInfo';
import { useTranslation } from 'react-i18next'

const BaseUploadContainerEvents = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const location = useLocation();
  const [manualAdd, setManualAdd] = useState(false);
  const [showContainer, setShowContainer] = useState(false);
  const [massiveData, setMassiveData] = useState([]);
  const [filterAttributeModel, setFilterAttributeModel] = useState([])

  // modalMessage_ata
  const [modalErrorInfo, setModalErrorInfo] = useState(false);
  const openModalInfo = () => {
    setModalErrorInfo(true);
  }
  const onModalErrorInfoClosed = () => {
    let redirectPath = "";
    if (props.redirectOnErrorPath) {
      redirectPath = props.redirectOnErrorPath
    } else {
      redirectPath = ASSETS
    }
    // aca deberia ir la redireccion cuando se cierra...
    history.push({
      pathname: redirectPath,
      state: {},
    });
    setModalErrorInfo(false);
  }

  const setManual = () => {
    setManualAdd(
      history.location.pathname === EVENTS_MASSIVE_MANUAL
    );
  };

  useEffect(() => {
    setManual();
    if (location?.state?.rows?.length > 0 && (location?.state?.args?.fields?.length > 0 || location?.state?.args?.isMassive)) {
      setMassiveData(location.state.rows);
      setFilterAttributeModel(location.state.args.fields)
    }
  }, []);

  useEffect(() => {
    setManual();
  }, [history.location.pathname]);

  const { selectedBox, selectedAsset } = useGlobalContext();

  useEffect(() => {
    if (selectedAsset.id !== -1 && selectedBox.id !== -1) {
      setShowContainer(true);
    }
  }, [selectedAsset, selectedBox]);

  const handleUploadOk = (res) => {
    // console.log('OK', res);
  };

  const handleUploadError = (error) => {
    openModalInfo();
  };

  return (
    <>
      {/** modalMessage_ata */}
      <ModalErrorInfo
        open={modalErrorInfo}
        toggleModal={
          () => {
            // cuando se cierra
            onModalErrorInfoClosed();
          }
        }
        modalText={
          t("component.comError")
        }
      />
      {/** modalMessage_ata */}
      {showContainer && !manualAdd && (
        <UploadContainerEvent
          pk={null}
          objectType="asset"

          /*********************/
          // alta masiva de un activo
          //action="add"
          //entityMode="asset"
          /*********************/

          /*********************/
          // alta masiva de un evento, contra columna Id en el excel
          action="update"
          entityMode="eventAsset"
          eventType={1} // ponemos id de evento cuando vamos en modo eventAsset
          /*********************/

          globalModel={selectedAsset.id}
          parentId={selectedBox.id}
          temporalDetailParentId="-1"
          pkReferer={null}
          pkObjectType={selectedAsset.id}
          onUploadOk={handleUploadOk}
          onUploadError={handleUploadError}
        />
      )}
      {showContainer && manualAdd && (
        <ManualContainerEvent
          pk={null}
          action={"update"}
          data={massiveData}
          filterAttributeModel={filterAttributeModel}
          objectType="asset"
          entityMode="asset"
          //eventType={1}
          globalModel={selectedAsset.id}
          parentId={selectedBox.id}
          temporalDetailParentId="-1"
          pkReferer={null}
          pkObjectType={selectedAsset.id}
          onUploadOk={handleUploadOk}
          onUploadError={handleUploadError}
        />
      )}
    </>
  );
};

export default BaseUploadContainerEvents;
