/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import useGlobalContext from '../../Hooks/useGlobalContext';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import BasicFormStepByStep from '../../Components/Generic/basicFormStepByStep';
import { faPlus, faDownload } from '@fortawesome/pro-light-svg-icons';
import { ApiFetch } from '../../Utils/ApiFetch';
import SRA from '../plugins/VacaAR/SRA';
import {
  Grid,
  Stack,
  Button,
  Link,
  Typography,
  Card,
  Avatar,
  IconButton,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faMinusCircle, faTrash } from '@fortawesome/pro-light-svg-icons';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import { ACTIVE_MODEL } from '../../constants';

function BasicFieldCollection(props) {
  const { t } = useTranslation();
  const { userData } = useGlobalContext();
  const globalContext = useGlobalContext();
  const timeZone = userData?.userData?.timezone;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [formMode, setFormMode] = useState('new');
  const [showForm, setShowForm] = useState(false);
  const [pk, setPk] = useState(null);

  const [columns] = React.useState(() => {
    let ret = [];

    ret.push({
      field: 'id',
      headerName: '',
      width: 50,
      renderCell: (params) => {
        return (
          <IconButton
            aria-label="settings"
            onClick={(event) => rowMenuActionshandleClick(event, params.row)}>
            <MoreVertIcon />
          </IconButton>
        );
      },
    });

    for (let field of props.fields) {
      if (field.type === 'file') {
        ret.push({
          field: field.name,
          headerName: field.label,
          width: 200,
          renderCell: (params) => {
            if (!params.row[field.name]) return '';
            let file = JSON.parse(params.row[field.name]);
            const onDownload = () => {
              const link = document.createElement('a');
              link.href = file.publicFileURI;
              link.click();
            };

            return (
              <div>
                <Button
                  sx={{ ml: 2 }}
                  variant="outlined"
                  size="small"
                  startIcon={<FontAwesomeIcon icon={faDownload} />}
                  onClick={() => {
                    onDownload();
                  }}>
                  DESCARGAR
                </Button>
              </div>
            );
          },
        });
      } else if (field.type === 'location') {
        ret.push({
          field: field.name,
          headerName: field.label,
          width: 200,
          renderCell: (params) => {
            let location
            try {
              const locationParse = JSON.parse(params.row[field.name])
              if(locationParse)
                location = `Lat: ${locationParse?.geometry?.coordinates[1]?.toFixed(4)}, Lng: ${locationParse?.geometry?.coordinates[0]?.toFixed(4)}`
              else  location = 'Sin ubicación'
            } catch (error) {
              location = ''
            }

            return (
              <div>{location}</div> 
            );
          },
        });
      } else {
        ret.push({
          field: field.name,
          headerName: field.label,
          width: 200,
        });
      }
    }

    return ret;
  });

  const [rows, setRows] = useState([]);
  const [menuActionSelectedRow, setMenuActionSelectedRow] = useState({});

  const rowMenuActionshandleClick = (event, row) => {
    setMenuActionSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };
  const rowMenuActionshandleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getRows(
      props.globalModel,
      props.objectType,
      props.pkObjectType,
      props.pk,
      props.temporalPkReferer,
    );
  }, [props.objectType]);

  const getRows = (
    model,
    objectType,
    pkObjectType,
    idParent,
    idTemporalParent
  ) => {
    ApiFetch(
      'objects/get/all/' +
        model +
        '/' +
        objectType +
        '/' +
        pkObjectType +
        '/' +
        idParent +
        '/0/' +
        idTemporalParent +
        '/0/1000/id/asc/bindfiles',
      globalContext,
      true,
      async (res) => {
        let resJson = await res.json();
        setRows(resJson.res);
        let ret = '';
        if (resJson.res.length > 0) {
          ret = JSON.stringify(resJson.res);
        }
        props.onChange(ret);
      },
      async (error) => {
        // console.log(error);
      },
      {
        method: 'POST',
        body: JSON.stringify({ filters: [] }),
      }
    );
  };

  const newItem = () => {
    setShowForm(true);
    setFormMode('new');
    setPk(null);
  };

  const editItem = (id) => {
    setShowForm(true);
    setFormMode('update');
    setPk(id);
  };

  const showDeleteDialogItem = (id) => {
    setShowForm(true);
    setFormMode('delete');
    setPk(id);
  };

  const CustomToolbar = () => {
    return (
      <Stack direction="row" justifyContent="left" alignItems="left">
        <Button
          sx={{ ml: 2 }}
          variant="contained"
          disabled={props.disabled}
          size="small"
          onClick={() => {
            newItem();
          }}
          startIcon={<FontAwesomeIcon icon={faPlus} />}>
          {props.labels.labelButtonAdd}
        </Button>
      </Stack>
    );
  };

  function CustomNoRowsOverlay() {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '85px',
        }}>
        {t('stepByStep.msg.noRecords')}
      </div>
    );
  }

  const handleOnChangeSRA = () => {
    getRows(
      props.globalModel,
      props.objectType,
      props.pkObjectType,
      props.pk,
      props.temporalPkReferer
    );
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={rowMenuActionshandleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem
          disabled={props.disabled}
          onClick={() => {
            editItem(menuActionSelectedRow.id);
          }}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faMinusCircle} />
          </ListItemIcon>
          <ListItemText>{t('component.popoverOption.edit')}</ListItemText>
        </MenuItem>
        {props.labels.labelFormTitleDelete ? (
          <MenuItem
            disabled={props.disabled}
            onClick={() => {
              showDeleteDialogItem(menuActionSelectedRow.id);
            }}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faTrash} />
            </ListItemIcon>
            <ListItemText>{t('component.popoverOption.delete')}</ListItemText>
          </MenuItem>
        ) : null}
      </Menu>

      {props.field !== undefined && (
        <div style={{ width: '100%', minHeight: '320px' }}>
          {(props.field.cardMode === undefined ||
            props.field.cardMode === false) && (
            <Grid container spacing={2}>
              <Grid item xs={12} key={2}>
                {props.pluginsContext?.plugins?.length > 0 &&
                  props.model === ACTIVE_MODEL.vacaAr &&
                  props.pluginsContext?.plugins?.includes('VacaAR_SRA') && (
                    <SRA
                      fieldsValues={props.pluginsContext.fieldsValues}
                      fieldsLabels={props.pluginsContext.labels}
                      fields={props.fields}
                      onChange={handleOnChangeSRA}
                      disabled={props.disabled}
                      name={props.field.name}
                      label={props.field.label}
                      formProps={{
                        action: 'new', //TODO: siempre va en alta porque se borran los preexistentes
                        context: 'manual_form',
                        eventType: props.eventType ? props.eventType : -1,
                        globalModel: props.globalModel,
                        objectType: props.objectType,
                        parentId: props.parentId,
                        pk: props.pk,
                        pkObjectType: props.pkObjectType,
                        pkReferer: props.temporalPkReferer,
                        temporalPkReferer: props.temporalPkReferer,
                        timeZone,
                      }}
                    />
                  )}
              </Grid>
              <Grid item xs={12} key={1}>
                {CustomToolbar()}
              </Grid>
              <Grid item xs={12} key={0}>
                <DataGrid
                  style={{
                    backgroundColor: '#f9f9f9',
                    minHeight: '270px',
                  }}
                  rows={rows}
                  columns={columns}
                  pagination
                  autoHeight
                  pageSize={5}
                  rowHeight={35}
                  headerHeight={35}
                  components={{
                    //Toolbar: CustomToolbar,
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                />
              </Grid>
            </Grid>
          )}

          {props.field.cardMode && (
            <Grid container spacing={2}>
              <Grid item xs={12} key={0}>
                {CustomToolbar()}
              </Grid>

              {rows.map((row, index) => {
                let image = <img alt="" />;
                if (props.field.cardModeFields.file != null) {
                  if (
                    row[props.field.cardModeFields.file] !== null &&
                    row[props.field.cardModeFields.file] !== undefined &&
                    row[props.field.cardModeFields.file] !== ''
                  ) {
                    let file = JSON.parse(row[props.field.cardModeFields.file]);
                    if (file?.originalName) {
                      let originalNameSplit = file.originalName.split('.');
                      if (
                        ['JPG', 'PNG', 'JPEG', 'TIFF', 'BMP'].includes(
                          originalNameSplit[
                            originalNameSplit.length - 1
                          ].toUpperCase()
                        )
                      ) {
                        image = (
                          <img
                            style={{
                              maxWidth: '12vh',
                              borderStyle: 'solid',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                              borderWidth: '1px',
                            }}
                            src={file.publicFileURI}
                            alt=""
                          />
                        );
                      }
                    }
                  }
                }

                return (
                  <Grid
                    item
                    xs={props.gridColSize ? props.gridColSize : 4}
                    key={index}>
                    <Card
                      className="pt-2 pl-2 pb-2"
                      style={{
                        display: 'block',
                        transitionDuration: '0.3s',
                        height: '180px',
                      }}>
                      <div className="d-flex flex-row justify-between pr-2">
                        <div className="d-flex flex-row align-start">
                          <Avatar aria-label="recipe" className="mr-2 mt-1">
                            T
                          </Avatar>
                          <span>
                            <Typography
                              variant="subtitle2"
                              component="p"
                              color="primary"
                              className="mb-2">
                              <Link
                                component="button"
                                style={{
                                  textDecoration: 'none',
                                  fontWeight: 'bold',
                                }}
                                onClick={() => {}}>
                                {props.field.cardModeFields.titlePrefix
                                  ? props.field.cardModeFields.titlePrefix
                                  : ''}
                                {row[props.field.cardModeFields.title]}
                              </Link>
                            </Typography>
                            {image}
                            <Typography
                              variant="subtitle2"
                              component="p"
                              className="mt-0 mb-2">
                              {row[props.field.cardModeFields.subTitle]}
                            </Typography>
                          </span>
                        </div>
                        <div>
                          <IconButton
                            aria-label="settings"
                            onClick={(event) =>
                              rowMenuActionshandleClick(event, row)
                            }>
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                      </div>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          )}

          <Dialog
            open={showForm}
            onClose={() => {}}
            fullWidth={false}
            maxWidth={'sm'}>
            <DialogTitle>
              {formMode === 'delete'
                ? props.labels.labelFormTitleDelete
                : props.labels.labelFormTitle}
            </DialogTitle>
            <DialogContent>
              <BasicFormStepByStep
                showStepTitle={false}
                showSectionTitle={false}
                sectionAccordionMode={false}
                fieldColSize={12}
                formMode={'vertical'}
                saveFunc={() => {}}
                show={showForm}
                onCanceled={() => {
                  setShowForm(false);
                }}
                onUpdated={() => {
                  getRows(
                    props.globalModel,
                    props.objectType,
                    props.pkObjectType,
                    props.pk,
                    props.temporalPkReferer
                  );
                  setShowForm(false);
                  rowMenuActionshandleClose();
                }}
                withDefault={false}
                mode={formMode}
                pk={pk}
                pkObjectType={props.pkObjectType}
                pkReferer={props.temporalPkReferer}
                globalModel={props.globalModel}
                objectType={props.objectType}
                objectTypeBindForm={props.objectType}
                parentType={props.parentType}
                parentTypeSubType={props.parentTypeSubType}
                parentId={props.parentId}
                count={0}
                fields={props.fields}
                form={props.form}
                showCancelButton={true}
                cancelButtontext={t('stepByStep.button.close')}
              />
            </DialogContent>
          </Dialog>
        </div>
      )}
    </>
  );
}

export default BasicFieldCollection;
