import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next';

const BasicDialog = ({ open, children, setOpen, title, actionButton }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      {title && <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {t('stepByStep.button.close')}
        </Button>
        {actionButton}
      </DialogActions>
    </Dialog>
  )
}

export default BasicDialog