import { Button } from '@mui/material';
import { formatISO } from 'date-fns';
import { DateTime } from 'luxon';
import moment from 'moment';
import { DATE_FORMATS, FILE_TYPES } from '../constants';

export function getDateFormat(d) {
  for (var prop in DATE_FORMATS) {
    if (moment(d, DATE_FORMATS[prop], true).isValid()) {
      return DATE_FORMATS[prop];
    }
  }
  return null;
}

export function normalizeDate(dateInput, separator) {
  if (dateInput.toString().split(separator).length > 0) {
    let dateInputSplit = dateInput.toString().split(separator);
    let dateInputTemp = [];
    for (let part of dateInputSplit) {
      if (part.length === 1) part = '0' + part;
      dateInputTemp.push(part);
    }
    dateInput = dateInputTemp.join(separator);
  }
  return dateInput;
}

export function handleDefaultValues(
  fieldValue,
  fieldType,
  formattedValue,
  field
) {
  let formatedValue = '';

  switch (fieldType) {
    case 'text':
      formatedValue = fieldValue ? fieldValue.toString() : fieldValue;
      break;
    case 'file':
      formatedValue = fieldValue ? fieldValue : null;
      break;
    case 'date':
    case 'dateTime':
    default:
      formatedValue = fieldValue;
      break;
  }

  return formatedValue;
}

export const renderFileImage = (fieldValue) => {
  let formatedValue = '';
  if (fieldValue == null || fieldValue === '' || fieldValue === 'Cargar Archivo') {
    formatedValue = 'Cargar Archivo';
  } else {
    try {
      let image = <></>;
      let file = JSON.parse(fieldValue);
      if (file?.originalName) {
        let originalNameSplit = file.originalName.split('.');
        if (
          FILE_TYPES.includes(
            originalNameSplit[originalNameSplit.length - 1].toUpperCase()
          )
        ) {
          image = (
            <>{file.originalName}</>
            // TODO: comentado para salida a produccion
            // <img
            //   style={{
            //     maxWidth: '30px',
            //     borderStyle: 'dotted',
            //     borderWidth: '1px',
            //     verticalAlign: 'middle',
            //     marginRight: '5px',
            //   }}
            //   src={file.publicFileURI}
            //   alt={file.originalName}
            // />
          );
        }
      }
      const onDownload = () => {
        const link = document.createElement('a');
        link.href = file.publicFileURI;
        link.click();
      };
      formatedValue = (
        <div>
          {image}
          {false && (
            <Button
              size="small"
              color="secondary"
              onClick={() => {
                onDownload();
              }}
              type="button"
              variant="contained">
              Descargar
            </Button>
          )}
        </div>
      );
    } catch (e) {
      formatedValue = '';
    }
  }
  return formatedValue;
};

const getDateToFill = (newDate, separator = '') => {
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${date}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${year}`;
};

export const renderCell = ({ params, value }) => {
  const type = params.colDef.type;

  switch (type) {
    case 'file':
      return <>{renderFileImage(value)}</>;
    case 'date':
      if (value !== null && value !== '') {
        if (value.length <= 10) {
          // todoivan
          return <>{value}</>;
        }
        let dateTemp = new Date(value);
        return <>{getDateToFill(dateTemp, '-')}</>;
      } else {
        return <>{value}</>;
      }

    default:
      return <>{value}</>;
  }
};

export const calculateLabelDates = (it, timeZone) => {
  if (it === '') {
    return { dateLabel: '', dateValue: '' };
  }

  const currentDate = it instanceof Date ? it : new Date(it);
  const formatedDate = formatISO(currentDate);
  const dateLabel = DateTime.fromISO(formatedDate).setZone(timeZone).toISO();
  const dateValue = DateTime.fromISO(formatedDate).setZone('UTC').toISO();
  return { dateLabel, dateValue };
};
