import React, { useEffect, useState } from 'react';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import {
  LOGOUT,
  USER_REGISTER_COMPLETE,
  BOX_REGISTER,
  USER_EDIT,
  REQUIRED_FIELDS_ERROR
} from '../../constants/paths';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { initModelUser } from '../../Utils/Model';
import Loading from '../Generic/Loading';
import NotSelected from './NotSelected';
import { PATHS_WHITELIST, REQUIRED_FIELDS_PATHS } from '../../constants';
import { ToastContainer, toast } from 'react-toastify';

const PrivateRoute = (props) => {

  const {
    bindValidToken,
    isAuthenticated,
    initiatedItemsComplete,
    userData,
    initTopBar,
    refreshUserData,
    selectedBox,
    selectedAsset,
    selectedChain,
    selectedCompany,
    mandatoryFieldsErrorStack,
  } = useGlobalContext();

  const globalContext = useGlobalContext();
  const location = useLocation();
  const history = useHistory();
  const [showHelp, setShowHelp] = useState(false);

  useEffect(() => {
    if (history.location.state) {
      window.history.replaceState({}, document.title)
    }
  }, [history.location.state])

  useEffect(() => {
    bindValidToken();
    initModelUser(globalContext);
    initTopBar();
    refreshUserData();
  }, []);

  useEffect(() => {
    const path = window.location.pathname
    if (!PATHS_WHITELIST.some((v) => path.includes(v)) &&
      (
        selectedAsset.id === -1 ||
        selectedBox.id === -1 ||
        selectedCompany.id === -1 ||
        selectedChain.id === -1
      )
    ) {
      setShowHelp(true);
    } else {
      setShowHelp(false);
    }
  }, [selectedChain, selectedBox, selectedCompany, selectedAsset, location.pathname]);

  useEffect(() => {
    if (
      location.pathname !== USER_REGISTER_COMPLETE  &&
      userData &&
      userData.userData
    ){
      if (userData.userData.onboardingStep == 1) {
        setShowHelp(false);
        history.push({
          pathname: USER_REGISTER_COMPLETE,
          state: {},
        });
      }
      if (userData.userData.onboardingStep == 2) {
        setShowHelp(false);
        history.push({
          pathname: BOX_REGISTER,
          state: { companyId: userData.userData.onboardingStepCompany },
        });
      }
      // onboardingStep == 3 no hace redirect a USER_REGISTER_COMPLETE por que ya creo empresa y establecimiento
      // onboardingStep == 4 no hace redirect a USER_REGISTER_COMPLETE por que al ser DELEGADO salto los pasos de crear empresa y establecimiento
    }
  }, [userData]);



  useEffect(() => {
    if (location.state?.toast?.type && location.state?.toast?.message) {
      toast[location.state.toast.type](location.state.toast.message
        , {
          className: 'toast-message-body',
          progressClassName: 'toast-message-progress',
          position: 'bottom-left',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }
  }, [location])



  if (isAuthenticated) {
    if(mandatoryFieldsErrorStack.length > 0 && REQUIRED_FIELDS_PATHS.includes(location.pathname)){
      return <Redirect to={REQUIRED_FIELDS_ERROR} />;
    }
    if (!initiatedItemsComplete) {
      return (
        <Loading />
      );
    }
    return showHelp
      ? <NotSelected />
      : <>
        <ToastContainer />
        <Route {...props} />
      </>
  } else {
    return <Redirect from="*" to={LOGOUT} />;
  }

};

export default PrivateRoute;
