import { DEFAULT_LANGUAGES } from "../constants";

export function getInitialLanguage() {
  const savedLanguage = localStorage.getItem('selectedLanguage');

  if (savedLanguage) {
    return savedLanguage;
  }
  
  const browserLanguage = navigator.language || navigator.userLanguage;
  for (const langKey of Object.keys(DEFAULT_LANGUAGES)) {
    if (browserLanguage.startsWith(langKey)) {
      localStorage.setItem('selectedLanguage', langKey);
      return langKey;
    }
  }
  
  const defaultLanguage = 'es';
  localStorage.setItem('selectedLanguage', defaultLanguage);
  return defaultLanguage;
}