import React from "react";
import styled from "styled-components";
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider, Box } from "@mui/material";
import { useHistory } from "react-router-dom";

import useGlobalContext from "../../../Hooks/useGlobalContext";
import { USER_EDIT } from '../../../constants/paths';

const StyledListItemText = styled(ListItemText)`
  .MuiTypography-body1 {
    font-size: 12px;
    font-weight: 700;
  }
  .MuiTypography-body2 {
    font-size: 12px;
    font-weight: 500;
  }
`;

function SidebarUser() {
  let history = useHistory();
  const { userData: Data } = useGlobalContext();

  return (
    <Box>
      <Divider />
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        <ListItem sx={{ cursor: 'pointer' }} onClick={() => { history.push(USER_EDIT); }}>
          <ListItemAvatar>
            <Avatar>{Data?.userData?.firstname?.charAt(0).toUpperCase() + Data?.userData?.lastname?.charAt().toUpperCase()}</Avatar>
          </ListItemAvatar>
          <StyledListItemText
            primary={Data?.userData?.firstname + ' ' + Data?.userData?.lastname}
            secondary={(process.env.REACT_APP_HIDE_NOT_IMPLEMENTED ? 'Usuario de Origino' : "")}
          />
        </ListItem>
      </List>
    </Box>

  );
}

export default SidebarUser;
