import { Checkbox, Typography, Card } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { useEffect, useState } from 'react';

function MultiSelectBoxField(props) {
  const { label, name, checkItem, onChange, noRowsLabel, fieldsLoad, fieldsDisabledOnEdit = [] } = props;
  const [fieldValues, setFieldValues] = useState([]);

  useEffect(() => {
    let optionsTemp = props.fieldValues.filter(item => {

      if (props.fieldFk) {
        return props.valueFk.split(",").includes(item.fk);
      }

      return true;
    }).map(attr => ({ ...attr }))

    if(fieldsDisabledOnEdit && fieldsDisabledOnEdit[name]){
      let funcDisabled = fieldsDisabledOnEdit[name]

      optionsTemp.map(item => {
        return funcDisabled.func(fieldsLoad, item)
      });
    }

    setFieldValues(optionsTemp);
  }, [props.fieldValues, props.fieldFk, props.valueFk]);

  return (
    <>
      <Typography
        variant="h6"
        component="p"
        color="text.secondary"
        className="font-weight-normal mb-2"
      >
        {label}
      </Typography>
      {fieldValues?.map((item) => {

        let StyledCard = styled(Card)`
          border: 1px solid #3969e8;
          box-shadow: none;
          border-radius: 14px;
        `;
        if (item.active===0) {
          StyledCard = styled(Card)`
            border: 1px solid lightgray;
            box-shadow: none;
            border-radius: 14px;
          `;
        }

        return (
        <StyledCard className="d-flex flex-row w-100 align-center justify-between pt-1 pb-1 pl-2 pr-2 mb-2">
          <div className="d-flex flex-row align-center">
            {/*<FontAwesomeIcon icon={faExclamationCircle} size="1x" className="mr-2" />*/}
            <div className="d-flex flex-row">
              <Typography variant="body1" component="p" color="text.primary" className="mr-2">
                {item.img!=null ?
                  <>
                    <img style={{maxWidth:"150px",marginRight:"20px"}} src={item.img} alt='itemImage'/>
                  </>
                :
                  ''
                }
                {item.label}
              </Typography>
              <Typography variant="body1" component="p" color="text.secondary">
                {''}
              </Typography>
            </div>
          </div>

          <Checkbox
            disabled={(item.active===0 || item.disabled===true)?true:false}
            checked={checkItem(name, item.key)}
            onChange={(value) => onChange(name, { name: item.label, id: item.key })}
          />
        </StyledCard>
      )})}
      {
        noRowsLabel!=null && fieldValues?.length===0 &&
        <small>{noRowsLabel}</small>
      }
    </>
  );
}

export default MultiSelectBoxField;
