import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import BasicCell from "./BasicCell";

const StyledCellwithError = styled.div`
  width: 100%;
  height: 95%;
  border: 1px solid #ff0505;
  color: #ff0505;
  background: #fbdfdf;
  font-weight: 500;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 12px;

  svg {
    cursor: pointer;
    margin-left: 4px;
    border-radius: 1em;
  }
`;

function ErrorCell({ params, value, showError, onClick }) {
  return (
    <StyledCellwithError onClick={onClick}>
      <FontAwesomeIcon icon={faInfoCircle} size="1x" onClick={showError} className="mr-1" />
      <BasicCell params={params} value={value} />
    </StyledCellwithError>
  );
}

export default ErrorCell;
