import { Box, Container, Divider, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";

import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import { TrazabilidadBackground } from '../../../assets/vectors/TrazabilidadBackground';
import qrTheme from '../qrTheme'
import { Checkbox, Button } from '@mui/material';
import { Select, MenuItem, StylesProvider, createGenerateClassName } from '@material-ui/core';
import {
  AttributeMultipleCheckbox,
  AttributeImages,
  AttributeTokens,
  AttributeTable,
  AttributePlain,
  AttributeDate,
  AttributeLink,
  AttributeText,
  MapJourney,
  TimeLine,
  Footer,
} from '../components';
import { Spinner } from "reactstrap"
import { DEFAULT_LANGUAGES } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { IMAGES_PATH } from '../../../constants/paths';
import {
  AttributeMadurationChart,
} from './AttributeMadurationChart';
import { AttributeSelectImages } from './AttributeSelectImages';
import QrAccordion from './QrAccordion';
import LanguageSelect from '../../../Components/LanguageSelect';
import { getInitialLanguage } from '../../../Helpers/translation';

export const QrTemplate = ({
  attributes,
  onSubmit,
  errorMessage,
  editMode,
  setFieldsStates
}) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const getLanguagesFromStorage = () => {
    try {
      const storedLanguages = localStorage.getItem('languagesAvailables');
      return storedLanguages ? JSON.parse(storedLanguages) : DEFAULT_LANGUAGES;
    } catch (error) {
      console.error("Error al parsear JSON desde localStorage:", error);
      return DEFAULT_LANGUAGES;
    }
  };  
  const [selectedLang, setSelectedLang] = useState(getInitialLanguage());
  const [languages] = useState(getLanguagesFromStorage());
  const { handleSubmit, control } = useForm()
  const myForm = useRef(null);
  const generateClassName = createGenerateClassName({
    seed: 'qr-theme',
  });
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    const initialLang = getInitialLanguage();
    if (i18n.language !== initialLang) {
      i18n.changeLanguage(initialLang).then(() => {
        setSelectedLang(initialLang);
      });
    } else {
      setSelectedLang(initialLang);
    }
  }, []);
  

  const isMobile = width <= 768;

  const isValidData = (value) => {
    let outcome = false
    if (Array.isArray(value)) {
      outcome = (value.length > 0)
    } else {
      outcome = (value !== undefined && value !== null && value !== '')
    }
    return outcome
  }

  const doesTimeLineHaveAnyGoodValues = (values) => {
    for (let value of values) {
      if (value.lat !== null && value.lon !== null) {
        return true
      }
    }
    return false
  }

  const handleChangeLanguage = (event) => {
    const newLang = event.target.value;
    setSelectedLang(newLang);
    i18n.changeLanguage(newLang);
    localStorage.setItem('selectedLanguage', newLang);
  };

  const onChangeFields = (name, isEnable, value) => {
    setFieldsStates((prevFields) => {
      const existingFieldIndex = prevFields.findIndex(field => field.name === name);
      
      if (existingFieldIndex !== -1) {
        const updatedFields = [...prevFields];
        updatedFields[existingFieldIndex] = { name, isEnable, value };
        return updatedFields;
      } else {
        return [...prevFields, { name, isEnable, value }];
      }
    });
  }

  const renderField = (att, index) => {
    switch (att.type) {
      case 'text':
        return (
          <div>
            {att.data.value && (editMode || att.isEnabled) &&
              <AttributeText tt={t} data={att.data} />
            }
          </div>
        )
      case 'url':
        return (
          <div>
            {isValidData(att.data.value) && (editMode || att.isEnabled) &&
              <AttributeLink tt={t} data={att.data} />
            }
          </div>
        )
      case 'video':
        return (
          <div >
            {att.videoUrl && att.isEnabled && att.videoOrigin &&
              <iframe
                title={`iframe-${att.videoOrigin}`}
                src={
                  (att.videoOrigin === 'shortYT' || att.videoOrigin === 'videoYT') ? `https://www.youtube.com/embed/${att.videoUrl}`
                  : att.videoOrigin === 'googleDrive' && `https://drive.google.com/file/d/${att.videoUrl}/preview`
                }
                style={{
                  width: isMobile ? '350px' : '700px',
                  height: isMobile ? '150px' : '400px',
                  border: 'none' 
                }}
                allow="autoplay"
                allowFullScreen
              />
            }
          </div>
        )
      case 'plain':
        return (
          <div>
            {isValidData(att.data.value) && (editMode || att.isEnabled) &&
              <AttributePlain tt={t} data={att.data} />
            }
          </div>
        )
      case 'date':
        return (
          <div>
            {isValidData(att.data.value) && (editMode || att.isEnabled) &&
              <AttributeDate tt={t} data={att.data} />
            }
          </div>
        );
      case 'chart':
        const slaughterDate = attributes.find(
          (a) => a.name === 'slaughterDate'
        );
        const hasValidSlaughterDate =
          slaughterDate &&
          slaughterDate.data &&
          new Date(slaughterDate.data.value).toString() !== 'Invalid Date';

        return (
          <div>
            {isValidData(att.data.value) && (editMode || att.isEnabled) && (
              <>
                {hasValidSlaughterDate && (
                  <AttributeMadurationChart
                    value={att.data}
                    data={att}
                    tt={t}
                  />
                )}
              </>
            )}
          </div>
        );
      case 'images':
        const validImages = isValidData(att.data.value) &&
          (!Array.isArray(att.data.value) || att.data.value.filter(img => img !== null).length > 0);
        
        return (
          <div style={{ width: '100%' }}>
            {validImages && (editMode || att.isEnabled) && (
              <AttributeImages tt={t} data={att.data} />
            )}
          </div>
        )
      case 'selectimages':
        return (
          <div style={{ width: '100%' }}>
            {(isValidData(att.data.value)) && (editMode || att.isEnabled) &&
              <AttributeSelectImages tt={t} data={att.data} editMode={editMode} isEnable={att.isEnabled} enableValues={att.enableValues} onChangeFields={onChangeFields} />
            }
          </div>
        )
      case 'tokens':
        const tokenCount = Array.isArray(att.data.value) ? att.data.value.length : 1;
        const shouldUseAccordion = tokenCount > 44;

        return (
          <div style={{ width: '100%' }}>
            {shouldUseAccordion ? (
              <QrAccordion
                title={`${att.data.label} (${tokenCount})`}
                data={att.data}
                t={t}
              />
            ) : (
              <Box sx={{ width: '100%' }}>
                <AttributeTokens tt={t} data={att.data} />
              </Box>
            )}
          </div>
      );
      case 'multipleCheckbox':
        return (
          <div style={{ width: '100%' }}>
            {isValidData(att.data.value) && (editMode || att.isEnabled) &&
              <AttributeMultipleCheckbox tt={t} data={att.data} />
            }
          </div>
        )
      case 'table':
        return (
          <div style={{ width: '100%' }}>
            {isValidData(att.data.value.values) && (editMode || att.isEnabled) &&
              <AttributeTable tt={t} data={att.data} />
            }
          </div>
        )
      case 'productJourney':

        return (
          att.values !== undefined && doesTimeLineHaveAnyGoodValues(att.values) && (editMode || att.isEnabled) &&
          <div style={{ width: '100%' }}>
            <Typography color='text.secondary' variant='h3' className='pt-4'>
              {t('qrTemplate.Recorrido del producto')}
            </Typography>
            <Box
              sx={{
                width: '100%',
                height: '393.01px',
                borderRadius: '17px',
              }}
              className='mt-2 mb-1'
            >
              <MapJourney information={att.values.slice().reverse()} />
            </Box>
          </div>
        )
      case 'timeLine':
          return (
            att.values !== undefined && att.values.length > 0 && (att.isEnabled) &&
            <div style={{ width: '100%' }}>
              <Typography color='text.secondary' variant='h4' className='pt-4'>
                {t("qrTemplate.Recorrido")}
              </Typography>
              <TimeLine tt={t} information={att.values.slice().reverse()}/>
            </div>
          )
        
      case 'description':
        const language = selectedLang.split('_')
        return (
          att.values && (att.isEnabled) &&
          <div style={{ width: '100%' }}>
            <Typography color='text.grey' variant='h2' className='pt-3 pb-3'>
              {t('qrTemplate.Descripción')}
            </Typography>
            <Typography color='text.grey' variant='body1' className='pt-2'>
              {language.length > 1 ? att.values[language[1]] : att.values[selectedLang]}
            </Typography>
            <br />
          </div>
        )
      default:
        return (
          <>
          </>
        )
    }
  }

  const renderDescriptionEdit = (descripcion) => {
    return (
      <div style={editMode ? { border: '1px dashed #aaa', padding: '1rem', display: 'flex', flexDirection: 'row' } : {}} className='mt-3'>
        <div style={{ display: 'flex', paddingTop: '30px' }}>
          <Controller
            render={({ field: { name, value, onChange } }) => (
              <Checkbox
                name={name}
                checked={value}
                disabled={!descripcion.isUserConfigurable}
                onChange={onChange} />
            )}
            control={control}
            defaultValue={descripcion?.isEnabled}
            name={'description'}
          />
        </div>

        <div style={{ width: '100%' }}>
          <Typography color='text.grey' variant='h2' className='pt-3 pb-3'>
            {t('Descripción')}
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '50%', marginTop: '1rem', paddingRight: '1rem' }}>
              <Typography color='text.grey' variant='body2' className='mb-3'>
                Versión en Español
              </Typography>
              <Controller
                render={({ field: { name, value, onChange } }) => (
                  <TextField
                    sx={{ '& .MuiInputBase-root': { height: '250px' } }}
                    multiline
                    fullWidth
                    name={name}
                    value={value}
                    onChange={onChange}
                  />
                )}
                control={control}
                name={'descriptionEsp'}
                defaultValue={descripcion?.values.es}
              />
            </div>
            <div style={{ width: '50%', marginTop: '1rem', paddingRight: '1rem' }}>
              <Typography color='text.grey' variant='body2' className='mb-3'>
                Versión en Inglés
              </Typography>
              <Controller
                render={({ field: { name, value, onChange } }) => (
                  <TextField
                    sx={{ '& .MuiInputBase-root': { height: '250px' } }}
                    multiline
                    fullWidth
                    name={name}
                    value={value}
                    onChange={onChange}
                  />
                )}
                control={control}
                name={'descriptionEng'}
                defaultValue={descripcion.values.en}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <StyledEngineProvider injectFirst>
      {!attributes &&
        <div style={{ width: "100%", textAlign: "center", marginTop: "50px" }}>
          <img
            src={`${IMAGES_PATH}/origino_logo.png`}
            className='pt-4'
            style={{ width: '250px', height: 'auto' }}
            alt='logo origino'
          />
          <br />
          <br />

          {errorMessage ?
            <div class='text-center' height='200px'>
              <Container className='container-trazabilidad'>
                <Typography color='text.primary' style={{ marginBottom: '25px' }}>
                  {t('qrTemplate.Lo sentimos')} &#128542;
                </Typography>
                <Typography color='text.secondary' >
                  {errorMessage}
                </Typography>
              </Container>
              <Footer />
            </div >
            : <Spinner size="sm" type="grow" />
          }
        </div>
      }
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={qrTheme}>
          {!editMode && <TrazabilidadBackground />}

          {attributes &&
            <>
              <Container className='container-trazabilidad' component={'form'} noValidate
                ref={myForm} onSubmit={handleSubmit(onSubmit)}>
                {!editMode
                  ?
                  <div style={{ paddingTop: '0rem', paddingBottom: '2rem', display: 'flex', justifyContent: 'flex-end' }} align="right">
                    <LanguageSelect 
                      value={selectedLang}
                      onChange={handleChangeLanguage}
                      languages={languages}
                    />
                  </div>
                  :
                  <div style={{ paddingTop: '3rem', paddingBottom: '2rem', display: 'flex', justifyContent: 'flex-end' }} >
                    <Button variant='outlined' onClick={() => history.goBack()}>Cancelar</Button>
                    <Button variant='outlined' style={{ marginLeft: '15px' }} type='submit'>Guardar Template</Button>
                  </div>
                }
                <Typography color='text.secondary' variant='h2'>
                  {t('qrTemplate.Bienvenido!')} 👋
                </Typography>
                <Typography color='text.primary' variant='h1' className='pt-1'>
                  {t('qrTemplate.Conocé todo sobre este producto')}
                </Typography>

                {editMode && renderDescriptionEdit(attributes.find(att => att.name === 'description'))}

                <Typography color='text.grey' variant='h2' style={{ paddingTop: '3rem' }} >
                  {t('qrTemplate.Información de trazabilidad')}
                </Typography>

                {attributes.map((att, index) => (
                  !att.hide &&
                  <div 
                    style={
                      editMode && 
                      (Array.isArray(att.data.value) 
                        ? att.data.value.length > 0 
                        : att.data.value
                      ) 
                      ? { border: '1px dashed #aaa', padding: '2rem 1rem', display: 'flex', flexDirection: 'row', alignItems:'center' } 
                      : {}
                    } 
                    key={index}
                  >
                    {(
                      editMode &&
                      att.type !== "selectimages" 
                    ) ?
                      <div style={{ display: 'flex' }}>
                        <Controller
                          render={({ field: { name, value, onChange } }) => (
                            <Checkbox
                              name={name}
                              checked={value}
                              disabled={!att.isUserConfigurable}
                              onChange={onChange} 
                            />
                          )}
                          control={control}
                          name={att.name}
                          defaultValue={att.isEnabled}
                        />
                      </div>
                      :
                      (<>
                        {
                          ((att.type !== 'table' && att?.data?.value) || 
                          (att.type === 'table' && att?.data?.value.values.length > 0)) 
                            && <Divider/>
                        }
                      </>)
                    }
                    {renderField(att, index)}
                  </div>
                ))
                }

                {editMode &&
                  <div style={{ paddingTop: '1rem', display: 'flex', justifyContent: 'flex-end' }} >
                    <Button variant='outlined' onClick={() => history.goBack()}>Cancelar</Button>
                    <Button variant='outlined' style={{ marginLeft: '15px' }} type='submit'>Guardar Template</Button>
                  </div>
                }
              </Container>
              <Footer tt={t} />
            </>
          }
        </ThemeProvider>
      </StylesProvider>
    </StyledEngineProvider>
  );
};
