import React from "react";
import { Switch } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import { signInTheme } from "../pages/SignIn/signInTheme";

import PublicRoute from "../Components/Router/PublicRoute";
import GlobalContextProvider from "../Contexts/globalContext";

import { StepByStepRegistroUser } from "../pages/SignIn";
import Login from "../pages/Access/Login";

function Main() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={signInTheme}>
        <Switch>
          <GlobalContextProvider>
            {/* TOMI: no deberíamos usar un step by step para los dos primeros pasos del registro */}
            <PublicRoute path={"/public/signin/users/stepbystep"} component={StepByStepRegistroUser} exact></PublicRoute>
            <PublicRoute path={"/public/signin"} component={Login} exact></PublicRoute>
          </GlobalContextProvider>
        </Switch>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default Main;
