import { ApiFetch } from '../Utils/ApiFetch';
import { calculateLabelDates } from '../Utils/gridDefaultValues';
import { Genealogy } from '../Utils/genealogy';
import instance from '../Config/axios';
import { OBJECTS_URL } from '../constants/requestsUrl';

/**
 * abstraccion para persistir la info de los componente de tipo BasicfieldCollection
 */
export async function submitDetailInfo({
  errorCallback,
  externalCallback,
  fields,
  formProps,
  globalContext,
  successCallback,
  values,
}) {
  const genealogy = new Genealogy(values);
  const procesedDetails = genealogy.getDetailGenealogyArray();
  let procesedDetailsLabels = [];
  for (let row of procesedDetails) {
    let rowLabel = {};
    for (let field of fields) {
      if (field.type === 'date' || field.type === 'select') {
        rowLabel[field.name] = row[field.name];
      }
    }
    procesedDetailsLabels.push(rowLabel);
  }

  await submitInfo({
    apiEndpoint: 'objects/add/massive',
    errorCallback,
    externalCallback,
    fields,
    formProps,
    globalContext,
    labels: procesedDetailsLabels,
    successCallback,
    values: procesedDetails,
    type: 'massive',
  });
}

export async function submitDeleteInfo({
  errorCallback,
  externalCallback,
  fields,
  formProps,
  globalContext,
  successCallback,
  values,
}) {
  await submitInfo({
    apiEndpoint: 'objects/details',
    errorCallback,
    externalCallback,
    fields,
    formProps,
    globalContext,
    labels: {},
    successCallback,
    values: values,
    method: 'DELETE',
    type: 'massive',
  });
}

/**
 * abstraccion para mandar a persistir objects a la API
 */
export async function submitInfo({
  apiEndpoint,
  errorCallback,
  externalCallback,
  fields,
  formProps: {
    action,
    context,
    eventComment,
    eventDate,
    eventType,
    globalModel,
    objectType,
    parentId,
    pk,
    pkObjectType,
    pkReferer,
    temporalPkReferer,
    timeZone,
  },
  globalContext,
  labels,
  successCallback,
  values,
  method,
  type,
}) {
  let fieldsValuesTemp = null;
  let fieldsLabelsTemp = null;
  if (type && type === 'massive') {
    fieldsValuesTemp = values;
    fieldsLabelsTemp = labels;
  } else {
    const entries = Object.entries(values).map(([key, value]) => {
      const currentField = fields.find((field) => field.name === key);
      if (currentField && !currentField.hasOwnProperty('onlyEvent')) {
        return [key, value];
      }
      return undefined;
    });

    fieldsValuesTemp = Object.fromEntries(entries);

    const fieldsLabelsTemp = { ...labels };
    if ('_eventDate_' in values) {
      const { dateLabel, dateValue } = calculateLabelDates(
        values._eventDate_,
        timeZone
      );
      fieldsLabelsTemp._eventDate_ = dateLabel;
      fieldsValuesTemp._eventDate_ = dateValue;
    }
  }

  const currentAction = action === 'new' ? 'add' : 'update';

  const dataObj = {
    values: fieldsValuesTemp,
    labels: fieldsLabelsTemp,
    headerValues: {
      date: eventDate,
      comment: eventComment,
    },
    actionContext: {
      context: context,
      eventType: eventType ? eventType : -1,
    },
    objectType: objectType,
    pkObjectType: pkObjectType,
    pkReferer: pkReferer,
    holder: parentId,
    temporalDetailParentId: temporalPkReferer,
    globalModel: globalModel,
    action: currentAction,
    pk: pk,
  };

  if (externalCallback != null) {
    externalCallback(dataObj);
    return;
  }

  await ApiFetch(
    apiEndpoint,
    globalContext,
    true,
    async (res) => {
      try {
        if (res.status === 200) {
          const response = await res.json();
          successCallback(response);
        }
      } catch (error) {
        errorCallback(error);
      }
    },
    async (error) => {
      const err = await error.json();
      errorCallback(err);
    },
    {
      method: method || 'POST',
      body: JSON.stringify(dataObj),
    }
  );
}

export const getObject = async (objectType, objectId) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `${OBJECTS_URL.GET}/${objectType}/${objectId}`;

  return await instance.get(url, config);
}

export const getAllObjects = async (modelId, objectType, pkObjectType, parentId, temporalParentId, page, pageSize, sortField, sortType) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `${OBJECTS_URL.GET_ALL}/${modelId}/${objectType}/${pkObjectType}/${parentId}/${temporalParentId}/${page}/${pageSize}/${sortField}/${sortType}`;

  return await instance.post(url, { filters: [] }, config);
}

export const putObjectsMassive = async (dataObj) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };

  return await instance.post(OBJECTS_URL.ADD_MASSIVE, dataObj, config);
}
