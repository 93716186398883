import React from "react";
import { Grid, Stack, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faDownload } from "@fortawesome/pro-light-svg-icons";
import BoxesTable from "./components/BoxesTable";
import useGlobalContext from "../../Hooks/useGlobalContext";
import { useHistory } from "react-router-dom";
import { BOXES_EDIT } from "../../constants/paths";

function Boxes() {
  let history = useHistory();
  const { userData } = useGlobalContext();
  const boxContext = userData.userData.userData.box_context;

  const newAssetAction = () => {
    let args = {
      pk: null,
      mode: "new",
      objectTypeSubType: boxContext.objectTypeSubType,
      globalModel: boxContext.model,
      parentType: boxContext.parentType,
      parentTypeSubType: boxContext.parentTypeSubType,
      parentId: boxContext.parentId,
    };

    history.push({
      pathname: BOXES_EDIT,
      state: { refererContext: args },
    });
  };

  return (
    //Starts the route view with <Grid container> and add <Grid item 12> for rows.
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="flex-end" alignItems="stretch">
          <Button sx={{ ml: 2 }} variant="outlined" startIcon={<FontAwesomeIcon icon={faDownload} />}>
            DESCARGAR
          </Button>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            onClick={() => {
              newAssetAction();
            }}
            startIcon={<FontAwesomeIcon icon={faPlus} />}
          >
            CARGAR ACTIVOS
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <BoxesTable />
      </Grid>
    </Grid>
  );
}

export default Boxes;
