import React, { useState } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, InputAdornment } from '@mui/material';
import MultipleSelectChip from './MultipleSelectChip';


export default function RenderModelFieldFilter(props) {

  const [permissionsDisabled] = useState({});
  const [fieldsError] = useState({});
  const [formError] = useState(false);
  const {field,xSize,fieldsValues,tempFieldsValues,onChange} = props;

    const getValue = (fieldName,valType,operator) =>{
        let res=''

        let index = tempFieldsValues.findIndex((e)=>e.field===fieldName)

        if (index>-1){
            res = tempFieldsValues[index][valType]
            if (res===''){ return null}
            return res
        }
        if (operator==='inArray'){ return []}
        return res
    }

    return (
        <Grid item sx={{ pr: 2, mb: 2 }} xs={xSize}>

        {(field.type==='text' || field.type==='masked') &&
            <TextField
                label={field.label}
                sx={{ width: '100%', mr: 2 }}
                disabled={permissionsDisabled[field.name]}
                name={field.name}
                size="small"
                value={getValue(field.name,'value')}
                onChange={(val)=>(onChange(field.name,val.target.value,field.label,'='))}
                placeholder={'Ingrese '+field.label} />
        }
        {(field.type==='select') && field?.values!== undefined &&

            <FormControl fullWidth>
                <InputLabel size="small" id={'demo-controlled-open-select-label-'+field.name}>{field.label}</InputLabel>
                <Select disabled={permissionsDisabled[field.name]}
                    name={field.name}
                    label={field.name}
                    size="small"
                    labelId={'demo-controlled-open-select-label-'+field.name}
                    value={getValue(field.name,'value')}
                    id="select"
                    placeholder={'Seleccione '+(field.label)}
                    onChange={(val)=>(
                        onChange(field.name,val.target.value,field.label,'===')
                        )}
                >
                    <MenuItem value=""><em>Deseleccionar</em></MenuItem>
                    {field.values.map((item,index) =>(
                        (field.fkField && item.fk===fieldsValues[field.fkField]) &&
                        <MenuItem value={item.label} key={index}>{item.label}</MenuItem>
                    ))}
                    {field.values.map((item,index) =>(
                        (!field.fkField) &&
                        <MenuItem value={item.label} key={index}>{item.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        }

        {(field.type==='multiselect') &&
            <MultipleSelectChip
                size={"small"}
                field={field}
                onChange={(val)=>onChange(field.name,val,field.label,'inArray')}
                selectedValues={getValue(field.name,'values','inArray')}
            />
        }
        {(field.type==='betweenValues') &&
            <div style={{display:'flex', border:'1px solid #c4c4c4', padding:'7px', borderRadius:'4px' }}>
            <div style={{fontWeight: '400',fontSize: '0.75rem',marginLeft:'0.5rem',
                fontFamily: 'Ubuntu,Open Sans',
                backgroundColor:'white',
                paddingLeft: '0.3rem',
                paddingRight: '0.3rem',
                position: 'absolute',
                display: 'block',
                transformOrigin: 'top left',
                overflow: 'hidden',
                left: '0',
                top: '-0.4rem',}}>{field.label}</div>
            <TextField
                label={`min`}
                size='small'
                type='number'
                sx={{ width: '50%', mr: 2 }}
                disabled={permissionsDisabled[field.name]}
                name={field.name}
                value={getValue(field.name,'min','betweenValues')}
                onChange={(val)=>(onChange(field.name,val.target.value,field.label,'betweenValues','min'))}
                placeholder={'min'} />
            <div style={{ width: '7px'}} ></div>
            <TextField
                label={`max`}
                size='small'
                type='number'
                sx={{ width: '50%', mr: 2 }}
                disabled={permissionsDisabled[field.name]}
                name={field.name}
                value={getValue(field.name,'max','betweenValues')}
                onChange={(val)=>(onChange(field.name,val.target.value,field.label,'betweenValues','max'))}
                placeholder={'max'} />
            </div>
        }
        {(field.type==='betweenDates') &&
            <div style={{display:'flex', border:'1px solid #c4c4c4', padding:'7px', borderRadius:'4px',justifyItem:'center' }}>
            <div style={{fontWeight: '400',fontSize: '0.75rem',marginLeft:'0.5rem',
                fontFamily: 'Ubuntu,Open Sans',
                backgroundColor:'white',
                paddingLeft: '0.3rem',
                paddingRight: '0.3rem',
                position: 'absolute',
                display: 'block',
                transformOrigin: 'top left',
                overflow: 'hidden',
                left: '0',
                top: '-0.4rem',}}>{field.label}</div>
            <TextField
                label={`min`}
                size='small'
                type='date'
                sx={{ width: '50%', mr: 2 }}
                disabled={permissionsDisabled[field.name]}
                name={field.name}
                value={getValue(field.name,'min','betweenDates')}
                InputProps={{startAdornment: <InputAdornment position="start"></InputAdornment>}}
                onChange={(val)=>(onChange(field.name,val.target.value,field.label,'betweenDates','min'))}
                placeholder={'min'}/>
            <div style={{ width: '14px'}} ></div>
            <TextField
                label={`max`}
                size='small'
                type='date'
                sx={{ width: '50%', mr: 2 }}
                disabled={permissionsDisabled[field.name]}
                name={field.name}
                value={getValue(field.name,'max','betweenDates')}
                InputProps={{startAdornment: <InputAdornment position="start"></InputAdornment>}}
                onChange={(val)=>(onChange(field.name,val.target.value,field.label,'betweenDates','max'))}
                placeholder={'max'} />
            </div>
        }
        {(fieldsError[field.name] && formError) &&
            <label style={{color:'red'}}><br/>Completar</label>
        }

    </Grid>
    )

}