import React, { useEffect, useState } from "react";
import useGlobalContext from "../../Hooks/useGlobalContext";
import { Grid, Typography, Card, CardHeader, CardContent, Chip } from "@mui/material";
import { useTranslation } from 'react-i18next';
import {EVENT_TYPES} from '../../constants/index';

function EventsDelete(props) {
  const { t } = useTranslation();
  const { selectedAsset, getSessionDataByKey } = useGlobalContext();
  const [events, setEvents] = useState([]);

   useEffect(() => {
    if (selectedAsset.id !== -1) {
      let models = getSessionDataByKey('models');
      let model = selectedAsset.id;
      setEvents(models[model].events.filter(e => e.eventType === EVENT_TYPES.DAR_DE_BAJA));
    }
  }, [getSessionDataByKey, selectedAsset]);


  return (
    <Grid container spacing={2}>
      {events?.length
        ? events.map((data) => (
          data?.assetMode !== false &&
          <Grid item key={data.name} >
            <Card sx={{ width: 300, height: 150 }}>
              <CardHeader
                title={
                  <Chip
                    onClick={() => props.onSelect(data)}
                    sx={{ cursor: 'pointer' }}
                    color="primary"
                    label={data.name}
                  />
                }
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                {data.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))
        : <Grid item>
          {t("stepByStep.massive.step1.noEvent")}
        </Grid>
      }
    </Grid>
  );
}


export default EventsDelete;