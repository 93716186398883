import React, { useEffect, useState } from 'react'
import { QrTemplate } from '../components/QrTemplate'

import useGlobalContext from '../../../Hooks/useGlobalContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getQrIdentecoTrupoTemplate, updateQrIdentecoTrupoTemplate } from '../../../services/qr';
import { useTranslation } from 'react-i18next';

export const QrIdentecoTrupoEdit = () => {
  const [attributes, setAttributes] = useState()
  const { selectedAsset, selectedBox } = useGlobalContext();
  const [errorMessage, setErrorMessage] = useState(false)
  const [fieldsStates, setFieldsStates] = useState([]);
  const { t } = useTranslation();
  let history = useHistory();

  useEffect(() => {
    getQrIdentecoTrupoTemplate(selectedBox.id, selectedAsset.id)
      .then(res => setAttributes(res.data))
      .catch(err => {
        console.log('Error getQrIdentecoTrupoTemplate', err)
        setErrorMessage(true)
      })
  }, [selectedAsset, selectedBox]);

  const handleSubmit = (values) => {
    const { descriptionEng, descriptionEsp, ...valuesWithoutDescription } = values

    const newValues = Object.keys(valuesWithoutDescription).map((key) => ({ name: key, isEnabled: valuesWithoutDescription[key] }));
    newValues.find(val => val.name === 'description').values = { en: descriptionEng, es: descriptionEsp }

    const customValues = attributes.map(att => {
      const field = fieldsStates.find(fs => fs.name === att.name)
      return att.customValues && {name: att.name, isEnabled: field?.isEnable, values: field?.value ?? null}
    }).filter(a => a)

    const allValues = [...newValues, ...customValues]

    updateQrIdentecoTrupoTemplate(selectedBox.id, allValues)
      .then(res => {
        history.push({
          pathname: '/qrTemplates',
          state: {
            message: res.data,
            success: true
          }
        })
      })
      .catch(err => {
        history.push({
          pathname: '/qrTemplates',
          state: {
            message: t('template.qrIdentecoTrupo.errorUpdate'),
            success: false
          },
        })
      })
  }

  return (
    <QrTemplate
      onSubmit={handleSubmit}
      attributes={attributes}
      errorMessage={errorMessage ? t('template.error') : ''}
      editMode={true}
      setFieldsStates={setFieldsStates}
    />
  )
}
