import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import UploadContainerTransfer from '../../Components/FileUploader/BaseComponents/UploadContainerTransfer';
import ManualContainerTransfer from '../../Components/FileUploader/BaseComponents/ManualContainerTransfer';
import useGlobalContext from '../../Hooks/useGlobalContext';
import {
  TRANSFERS,
  TRANSFERS_MASSIVE_MANUAL
} from '../../constants/paths';
import ModalErrorInfo from '../../Components/Feature/ModalErrorInfo';
import { useTranslation } from 'react-i18next';

const BaseUploadContainerTransfers = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const location = useLocation();
  const [manualAdd, setManualAdd] = useState(false);
  const [showContainer, setShowContainer] = useState(false);
  const [massiveData, setMassiveData] = useState([]);
  const [filterAttributeModel, setFilterAttributeModel] = useState([])
  const { selectedBox, selectedAsset } = useGlobalContext();
  const [modalErrorInfo, setModalErrorInfo] = useState(false);

  const openModalInfo = () => { setModalErrorInfo(true) }

  const onModalErrorInfoClosed = () => {
    history.push({
      pathname: TRANSFERS,
    });
    setModalErrorInfo(false);
  }

  const setManual = () => {
    setManualAdd(
      history.location.pathname === TRANSFERS_MASSIVE_MANUAL
    );
  };

  useEffect(() => {
    setManual();
    if (location?.state?.rows?.length > 0) {
      setMassiveData(location.state.rows);
    };
    if (
      location?.state?.args?.fields?.length > 0 ||
      location?.state?.args?.isMassive
    ) {
      setFilterAttributeModel(location.state.args.fields || []);
    };
  }, [location.state]);

  useEffect(() => {
    setManual();
  }, [history.location.pathname]);

  useEffect(() => {
    if (selectedAsset.id !== -1 && selectedBox.id !== -1) {
      setShowContainer(true);
    }
  }, [selectedAsset, selectedBox]);

  const handleUploadOk = (res) => {
    // console.log('OK', res);
  };

  const handleUploadError = (error) => {
    openModalInfo();
  };

  return (
    <>
      <ModalErrorInfo
        open={modalErrorInfo}
        toggleModal={onModalErrorInfoClosed}
        modalText={t("component.comError")}
      />
      {showContainer && !manualAdd && (
        <UploadContainerTransfer
          pk={null}
          objectType="asset"
          action="update"
          entityMode="eventAsset"
          eventType={-1}
          globalModel={selectedAsset.id}
          parentId={selectedBox.id}
          temporalDetailParentId="-1"
          pkReferer={null}
          pkObjectType={selectedAsset.id}
          onUploadOk={handleUploadOk}
          onUploadError={handleUploadError}
        />
      )}
      {showContainer && manualAdd && (
        <ManualContainerTransfer
          pk={null}
          action={"update"}
          data={massiveData}
          filterAttributeModel={filterAttributeModel}
          objectType="asset"
          entityMode="asset"
          globalModel={selectedAsset.id}
          parentId={selectedBox.id}
          temporalDetailParentId="-1"
          pkReferer={null}
          pkObjectType={selectedAsset.id}
          onUploadOk={handleUploadOk}
          onUploadError={handleUploadError}
        />
      )}
    </>
  );
};

export default BaseUploadContainerTransfers;
