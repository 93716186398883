import instance from '../Config/axios'

export const getTypeReports = async (assetId) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `reportsPortfolio/${assetId}`;

  return instance.get(url, config);
};

export const getReportPortfolio = async (assetId, activeId) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `reportsPortfolio/report/${assetId}/${activeId}`;

  return instance.get(url, config);
};

export const calculateReportPortfolio = async (reportId, assetType, fieldsInputs) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `reportsPortfolio`;

  return instance.post(url, { reportId, assetType, fieldsInputs }, config,);
};