import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useGlobalContext from '../../../Hooks/useGlobalContext';

import { Typography, Select, MenuItem } from '@mui/material';

import {
  ASSETS,
  ASSETS_EDIT,
  ASSETS_NEW,
  BOXES_ALL,
  BOXES_NEW,
  BOXES_EDIT,
  LOTS,
  LOTS_EDIT,
  LOTS_NEW,
  ASSETS_FILE_LOAD,
  ASSETS_MANUAL_LOAD,
  EVENTS_ASSETS_FILTER,
  EVENTS_FILE_LOAD,
  EVENTS_ALL,
  ASSETS_MASSIVE_EDIT,
  COMPANY_ALL,
  COMPANY_NEW,
  COMPANY_EDIT,
  EVENTS_MASSIVE,
  EVENTS_MASSIVE_MANUAL,
  TRANSFERS_MASSIVE,
  TRANSFERS_MASSIVE_MANUAL,
  USER_EDIT,
  QR_TEMPLATES,
  QR_IDENTECO,
  QR_PRODUKTO_TRUPO,
  QR_IDENTECO_TRUPO,
  USER_DELEGATIONS,
  USER_DELEGATIONS_ALL,
  USER_DELEGATIONS_COMPANY_ALL,
  TRANSFERRED_ASSETS,
  ACTIVITIES,
  LOGICAL_DELETED_ASSETS,
  REPORTS,
  TRANSFERS,
  EVENTS,
  REQUIRED_FIELDS_ERROR,
  QRS_METRICS,
  QRS_LOGS
} from '../../../constants/paths';
import useThemeContext from '../../../Hooks/useThemeContext';
import LanguageSelect from '../../../Components/LanguageSelect';

function Header(props) {
  let { activeTheme } = useThemeContext();
  const globalContext = useGlobalContext();
  const location = useLocation();
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  let { headerTitle, headerSubtitle } = useGlobalContext();

  useEffect(() => {
    let titleTemp = '';
    let subTitleTemp = '';

    //PENSAR LOGICA DE RUTAS DINAMICAS
    const qrLogsRegex = /^\/qrLogs\/[^/]+\/[^/]+$/;

    if (qrLogsRegex.test(location.pathname)) {
      titleTemp = 'header.qrs_logs.title';
      subTitleTemp = 'header.qrs_logs.subtitle';
    } else {
      switch (location.pathname) {
        case ASSETS_FILE_LOAD:
          titleTemp = 'header.assets_file_load.title';
          subTitleTemp = 'header.assets_file_load.subtitle';
          break;

        case ASSETS:
          titleTemp = 'header.assets.title';
          subTitleTemp = 'header.assets.subtitle';
          break;

        case ASSETS_NEW:
          titleTemp = 'header.assets_new.title';
          subTitleTemp = 'header.assets_new.subtitle';
          break;

        case ASSETS_EDIT:
          titleTemp = 'header.assets_edit.title';
          subTitleTemp = 'header.assets_edit.subtitle';
          break;

        case ASSETS_MANUAL_LOAD:
          titleTemp = 'header.assets_manual_load.title';
          subTitleTemp = 'header.assets_manual_load.subtitle';
          break;

        case ASSETS_MASSIVE_EDIT:
          titleTemp = 'header.assets_massive_edit.title';
          subTitleTemp = 'header.assets_massive_edit.subtitle';
          break;

        case EVENTS:
          titleTemp = 'header.events.title';
          subTitleTemp = 'header.events.subtitle';
          break;

        case EVENTS_ALL:
          titleTemp = 'header.assets_all.title';
          subTitleTemp = 'header.assets_all.subtitle';
          break;

        case EVENTS_ASSETS_FILTER:
          titleTemp = 'header.assets_assets_filter.title';
          subTitleTemp = 'header.assets_assets_filter.subtitle';
          break;

        case EVENTS_FILE_LOAD:
          titleTemp = 'header.events_file_load.title';
          subTitleTemp = 'header.events_file_load.subtitle';
          break;

        case EVENTS_MASSIVE:
          titleTemp = 'header.events_massive.title';
          subTitleTemp = 'header.events_massive.subtitle';
          break;

        case EVENTS_MASSIVE_MANUAL:
          titleTemp = 'header.events_massive_manual.title';
          subTitleTemp = 'header.events_massive_manual.subtitle';
          break;

        case COMPANY_ALL:
          titleTemp = 'header.company_all.title';
          subTitleTemp = 'header.company_all.subtitle';
          break;

        case COMPANY_NEW:
          titleTemp = 'header.company_new.title';
          subTitleTemp = 'header.company_new.subtitle';
          break;

        case COMPANY_EDIT:
          titleTemp = 'header.company_edit.title';
          subTitleTemp = 'header.company_edit.subtitle';
          break;

        case BOXES_ALL:
          titleTemp = 'header.boxes_all.title';
          subTitleTemp = 'header.boxes_all.subtitle';
          break;

        case BOXES_NEW:
          titleTemp = 'header.boxes_new.title';
          subTitleTemp = 'header.boxes_new.subtitle';
          break;

        case BOXES_EDIT:
          titleTemp = 'header.boxes_edit.title';
          subTitleTemp = 'header.boxes_edit.subtitle';
          break;

        case TRANSFERS:
          titleTemp = 'header.transfers.title';
          subTitleTemp = 'header.transfers.subtitle';
          break;

        case TRANSFERS_MASSIVE:
          titleTemp = 'header.transfers_massive.title';
          subTitleTemp = 'header.transfers_massive.subtitle';
          break;

        case TRANSFERS_MASSIVE_MANUAL:
          titleTemp = 'header.transfers_massive_manual.title';
          subTitleTemp = 'header.transfers_massive_manual.subtitle';
          break;

        case TRANSFERRED_ASSETS:
          titleTemp = 'header.transferred_assets.title';
          subTitleTemp = 'header.transferred_assets.subtitle';
          break;

        case LOTS:
          titleTemp = 'header.lots.title';
          subTitleTemp = 'header.lots.subtitle';
          break;

        case LOTS_NEW:
          titleTemp = 'header.lots_new.title';
          subTitleTemp = 'header.lots_new.subtitle';
          break;

        case LOTS_EDIT:
          titleTemp = 'header.lots_edit.title';
          subTitleTemp = 'header.lots_edit.subtitle';
          break;

        case USER_EDIT:
          titleTemp = 'header.user_edit.title';
          subTitleTemp = 'header.user_edit.subtitle';
          break;

        case USER_DELEGATIONS:
          titleTemp = 'header.user_delegations.title';
          subTitleTemp = 'header.user_delegations.subtitle';
          break;

        case USER_DELEGATIONS_ALL:
          titleTemp = 'header.user_delegations_all.title';
          subTitleTemp = 'header.user_delegations_all.subtitle';
          break;

        case USER_DELEGATIONS_COMPANY_ALL:
          titleTemp = 'header.user_delegations_company_all.title';
          subTitleTemp = 'header.user_delegations_company_all.subtitle';
          break;

        case QR_TEMPLATES:
          titleTemp = 'header.qr_templates.title';
          subTitleTemp = 'header.qr_templates.subtitle';
          break;

        case QR_IDENTECO:
          titleTemp = 'header.qr_identeco.title';
          subTitleTemp = 'header.qr_identeco.subtitle';
          break;

        case QR_PRODUKTO_TRUPO:
          titleTemp = 'header.qr_produkto_trupo.title';
          subTitleTemp = 'header.qr_produkto_trupo.subtitle';
          break;

        case QR_IDENTECO_TRUPO:
          titleTemp = 'header.qr_identeco_trupo.title';
          subTitleTemp = 'header.qr_identeco_trupo.subtitle';
          break;
        
        case QRS_METRICS:
          titleTemp = 'header.qrs_metrics.title';
          subTitleTemp = 'header.qrs_metrics.subtitle';
          break;

        case QRS_LOGS:
          titleTemp = 'header.qrs_logs.title';
          subTitleTemp = 'header.qrs_logs.subtitle';
          break;

        case ACTIVITIES:
          titleTemp = 'header.activities.title';
          subTitleTemp = 'header.activities.subtitle';
          break;

        case LOGICAL_DELETED_ASSETS:
          titleTemp = 'header.logicalDeletedAssets.title';
          subTitleTemp = 'header.logicalDeletedAssets.subtitle';
          break;

        case REPORTS:
          titleTemp = 'header.reports.title';
          subTitleTemp = 'header.reports.subtitle';
          break;
        case REQUIRED_FIELDS_ERROR:
          titleTemp = 'header.requiredFields.title';
          subTitleTemp = 'header.requiredFields.subtitle';
          break;
        default:
          titleTemp = 'header.default.title';
          subTitleTemp = 'header.default.subtitle';
      }
    }
    setTitle(titleTemp);
    setSubTitle(subTitleTemp);
  }, [location.pathname]);

  useEffect(() => {
    if (headerTitle != null) setTitle(headerTitle);
    if (headerSubtitle != null) setSubTitle(headerSubtitle);
  }, [headerTitle, headerSubtitle]);

  return (
    <div style={{
      display: 'flex', justifyContent: 'space-around', width: '-webkit-fill-available',
      background: `${activeTheme.palette.primary.contrastText}`,
      margin: '0.8rem 0rem 0.8rem 0rem'
    }}>
      <div className="col-10">
        <Typography variant="h3" color={'#666666'}>
          {globalContext.t(title)}
        </Typography>
        <Typography variant="subtitle1" color={'grey'}>
          {subTitle ? globalContext.t(subTitle) : ''}
        </Typography>
      </div>

      <div className="col-2" align="right" style={{ marginTop: '15px' }}>
        <LanguageSelect />
      </div>
    </div>
  );
}

export default Header;
