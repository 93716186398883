import React from 'react';
import { IMaskMixin } from 'react-imask';
import TextField from '@mui/material/TextField';

const TextMaskedField = IMaskMixin(({ inputRef, ...props }) => (

  <TextField inputRef={inputRef} {...props} />
));

export default TextMaskedField;
