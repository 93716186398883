import React, { useEffect, useRef, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { uploadFile } from '../../services/fileUpload';
import { uploaderReducer, initialState } from './reducer';

import Dropzone from './BaseComponents/DropZone';
import UploaderButton from './BaseComponents/UploaderButton';

import {
  ProgressBar,
  DownloadButton,
  InputFilename,
} from './BaseComponents/SharedComponents';

const FileUploader = ({
  cancelText,
  clearText,
  defaultText,
  dragDrop,
  getUploadProgress,
  isPrivate,
  maxSize,
  name,
  onChange,
  restoredFile,
  template,
  uploadedFileText,
  validFormats,
}) => {
  const progressInfoRef = useRef(null);

  const [state, dispatch] = useReducer(uploaderReducer, initialState);

  const {
    progressInfo,
    errorMessage,
    source,
    uploadedFileInfo,
    selectedFileInfo,
    loading,
    error,
    success,
    restored,
    reset,
  } = state;

  useEffect(() => {
    (async () => {
      try {
        !restoredFile && dispatchOnInit();
        restoredFile && dispatchOnRestoreFile(restoredFile);
      } catch (error) {
        dispatchOnUploadError(error.message);
      }
    })();

    return () => {};
  }, [restoredFile]);

  getUploadProgress({
    name,
    status: { success, loading, restored },
    uploadedFileInfo,
  });

  useEffect(() => {
    if (success || reset) {
      onChange({ success, reset, uploadedFileInfo, name });
    }
  }, [success, reset]);

  const dispatchOnSelectFile = (file) => {
    dispatch({
      type: 'ON_SELECT_FILE',
      payload: {
        selectedFileInfo: file,
        progressInfo: [],
        error: false,
        errorMessage: undefined,
        reset: false,
      },
    });
  };

  const dispatchOnRestoreFile = (fileInfo) => {
    dispatch({
      type: 'ON_RESTORE_FILE',
      payload: {
        restoredFileInfo: fileInfo,
        restored: true,
        loading: false,
        success: false,
      },
    });
  };

  const dispatchOnUploadFile = (source, progress) => {
    dispatch({
      type: 'ON_UPLOAD_FILE',
      payload: {
        source,
        progressInfo: [progress],
        restored: false,
        loading: true,
      },
    });
  };

  const dispatchOnUploadSuccess = (fileInfo, progress) => {
    dispatch({
      type: 'ON_UPLOAD_SUCCESS',
      payload: {
        uploadedFileInfo: [fileInfo],
        progressInfo: [progress],
        restored: false,
        loading: false,
        success: true,
      },
    });
  };

  const dispatchOnUploadError = (errorMessage) => {
    dispatch({
      type: 'ON_UPLOAD_ERROR',
      payload: {
        error: true,
        errorMessage,
        loading: false,
        success: false,
        restored: false,
      },
    });
  };

  const dispatchOnReset = () => {
    dispatch({
      type: 'ON_RESET',
      payload: {
        reset: true,
      },
    });
  };

  const dispatchOnInit = () => {
    dispatch({
      type: 'ON_INIT',
      payload: {},
    });
  };

  const uploadFiles = async (file) => {
    const source = axios.CancelToken.source();

    progressInfoRef.current = [{ percentage: 0, fileName: file.name }];
    let progress = progressInfoRef.current[0];

    try {
      const uploadedFile = await uploadFile(
        isPrivate,
        file,
        (progressEvent) => {
          progress.percentage = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total
          );
          if (progress.percentage <= 80) {
            dispatchOnUploadFile(source, progress);
          } else {
            progress.percentage = 80;
            dispatchOnUploadFile(source, progress);
          }
        },

        source
      );

      progress.percentage = 100;
      dispatchOnUploadSuccess(uploadedFile.data, progress);
    } catch (error) {
      if (error.message !== 'Request canceled by user') {
        dispatchOnUploadError('Could not upload the file: ' + file.name);
      }
    }
  };

  const handleChange = (event, file) => {
    const _file = (event && event.target.files[0]) || file[0];

    if (_file.size > maxSize * 1024 * 1024) {
      dispatchOnUploadError(`File is more larger than ${maxSize} MB`);
    } else {
      dispatchOnSelectFile(_file);
      uploadFiles(_file);
    }
  };

  const handleReset = (inputRef) => {
    source && source.cancel('Request canceled by user');
    inputRef.current.value = '';
    dispatchOnReset();
  };

  const renderDropZone = () => (
    <Dropzone
      onError={dispatchOnUploadError}
      onChange={handleChange}
      validFormats={validFormats}
      maxSize={maxSize}
      error={error}
      errorMessage={errorMessage}
      loading={loading}
      restored={restored}
      restoredFile={restoredFile}
      success={success}
      selectedFileInfo={selectedFileInfo}
      uploader={renderUploaderButton()}
      defaultText={defaultText}
      uploadedFileText={uploadedFileText}
    />
  );

  const renderUploaderButton = () => (
    <UploaderButton
      restored={restored}
      loading={loading}
      success={success}
      error={error}
      handleReset={handleReset}
      handleChange={handleChange}
      validFormats={validFormats}
      cancelText={cancelText}
      clearText={clearText}
    />
  );

  const renderProgressBar = () => (
    <ProgressBar progressInfo={progressInfo} error={error} loading={loading} />
  );

  const renderInputFilename = () => (
    <InputFilename
      selectedFileInfo={selectedFileInfo}
      restoredFile={restoredFile}
      restored={restored}
      loading={loading}
      success={success}
      error={error}
      errorMessage={errorMessage}
    />
  );

  const renderDownloadButton = () => (
    <DownloadButton restoredFile={restoredFile} />
  );

  return (
    <>
      {template &&
        (dragDrop ? (
          <template
            dropZone={() => renderDropZone()}
            progressBar={loading && (() => renderProgressBar())}
            downloadButton={restored && (() => renderDownloadButton())}
          />
        ) : (
          <template
            inputFilename={() => renderInputFilename()}
            progressBar={loading && (() => renderProgressBar())}
            downloadButton={restored && (() => renderDownloadButton())}
            dropZone={dragDrop && (() => renderDropZone())}
          />
        ))}
      {!template &&
        (dragDrop ? (
          <div
            style={{
              cursor: 'pointer',
              maxWidth: '100%',
              margin: '4px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'baseline',
              flexWrap: 'wrap',
            }}>
            {
              <div style={{ marginBottom: '3px', minWidth: '100%' }}>
                {renderDropZone()}
                <div style={{ marginTop: '-5px', minWidth: '100%' }}>
                  {loading && renderProgressBar()}
                </div>
              </div>
            }
            <div
              style={{
                marginTop: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}>
              {restored && renderDownloadButton()}
            </div>
          </div>
        ) : (
          <div
            style={{
              maxWidth: '17rem',
              margin: '0px 0px 4px 0px',
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}>
            <div
              style={{
                marginBottom: '2px',
                marginRight: '4px',
                order: '-1',
                minWidth: '50%',
                marginTop: '1.5rem',
              }}>
              {loading && (
                <div style={{ marginTop: '2px' }}>{renderProgressBar()}</div>
              )}
            </div>
            {restored && renderDownloadButton()}
            {renderInputFilename()}
          </div>
        ))}
    </>
  );
};

FileUploader.propTypes = {
  isPrivate: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dragDrop: PropTypes.bool.isRequired,
  getUploadProgress: PropTypes.func.isRequired,
};

export default React.memo(FileUploader);
