import React from 'react';
import { Checkbox, ListItemText, MenuItem, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const RenderField = ({
  type,
  label,
  name,
  defaultValue,
  required,
  options,
  value,
  onChange,
  error,
  helperText,
  placeholder
}) => {
  switch (type) {
    case 'number':
      return <TextField
        required={required}
        type='number'
        value={value}
        onChange={onChange}
        error={error}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        label={label}
        placeholder={placeholder}
        name={name}
        defaultValue={defaultValue}
        helperText={helperText}
      />;
    case 'text':
      return <TextField
        required={required}
        value={value}
        onChange={onChange}
        error={error}
        label={label}
        name={name}
        defaultValue={defaultValue}
        helperText={helperText}
      />;
    case 'date':
      return <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Basic example"
          value={value}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    case 'select':
      return <TextField
        select
        required={required}
        value={value}
        onChange={onChange}
        error={error}
        label={label}
        defaultValue={defaultValue}
        helperText={helperText}
      >
        {options?.map((option) => <MenuItem value={option.key} key={option.key}>{option.label}</MenuItem>)}
      </TextField>
    case 'multiselect':
      return <TextField
        select
        required={required}
        value={value ? value : []}
        onChange={onChange}
        error={error}
        label={label}
        defaultValue={defaultValue}
        helperText={helperText}
        SelectProps={{
          multiple: true, renderValue: (selected) =>
            options.filter((option) => selected.includes(option.key))
              .map(item => item.label)
              .join(', ')
        }}
      >
        {options?.map((option) =>
          <MenuItem value={option.key} key={option.key}>
            <Checkbox checked={value?.indexOf(option.key) > -1} />
            <ListItemText primary={option.label} />
          </MenuItem>)}
      </TextField>
    default:
      return <TextField />;
  }
}

export default RenderField