import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Snackbar } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { ApiFetch } from '../../../Utils/ApiFetch';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import useThemeContext from '../../../Hooks/useThemeContext';

import { ASSETS } from '../../../constants/paths';

import BasicFormStepByStep from '../../../Components/Generic/basicFormStepByStep';

function StepByStepUserEdit() {
  const { t } = useTranslation();
  const history = useHistory()
  const { userData, refreshUserData, setMandatoryFieldsErrorStack, selectedBox } = useGlobalContext();
  const globalContext = useGlobalContext();
  const [fields, setFields] = useState([]);
  const [form, setForm] = useState(null);
  const [snackbarIsOpen, setSnackbarIsOpen] = useState(false);
  const [prevBind, setPrevBind] = useState({});
  const [activePrevBind] = useState(true);

  const { swithMenuMode, setActiveThemeFromAsset } = useThemeContext();

  useEffect(() => {
    const { country } = JSON.parse(localStorage.getItem('USER_DATA')).userData
    setModels(country)
  }, [globalContext]);

  const setModels = (pais) => {
    let models = globalContext.getSessionDataByKey('models');
    let modelKey = "DEFAULT"
    let modelLabels = "DEFAULT"
    let attributesModel = []

    if (pais) {
      modelKey = models["userModels"]["basic"]["userTypes"][`${pais}_EDIT`] ? `${pais}_EDIT` : "DEFAULT_EDIT"
      modelLabels = models["userModels"]["basic"]["fieldsLabels"][pais] ? pais : "DEFAULT"
    }
    const activeModel = models["userModels"]["basic"]["userTypes"][modelKey]
    let attrNames = []

    for(const step of activeModel.form.config){
      for(const section of step.sections){
        for(const field of section.fields){
          const attribute = models["userModels"]["basic"].attributes.filter((atrr) => atrr.name === field).map(attr => ({ ...attr }));
          if(attribute && !attrNames.includes(attribute[0].name)) {
            attributesModel.push(...attribute)
            attrNames.push(attribute[0].name)
          }
        }
      }
    }

    if (models["userModels"]["basic"]["fieldsLabels"][modelLabels]) {
      for (const objLabels of models["userModels"]["basic"]["fieldsLabels"][modelLabels]) {
        const matchingAttribute = attributesModel.find(atrr => atrr.name === objLabels.name);
        matchingAttribute && (matchingAttribute.label = objLabels.label);
      }
    }

    setFields(attributesModel);
    setForm(activeModel.form);
    return models;
  };

  const handleCloseSnackbar = () => {
    setSnackbarIsOpen(false)
  }

  const handleRedirect = () => {
    swithMenuMode('HOME');
    history.push(ASSETS);
    setActiveThemeFromAsset(globalContext.selectedAsset.id);
  }

  const handleUpdate = async (_ids) => {
    await refreshUserData();
    await ApiFetch(
      'fields/validate/' + selectedBox.id,
      globalContext,
      true,
      async (body) => {
        let resJson = await body.json();
        setMandatoryFieldsErrorStack(resJson.error_stack);
      },
    );
    setSnackbarIsOpen(true)
  };

  const onChangeField = (field, value, fullValues) => {
    if( field === "l1" && (value !== "" || value !== null)){
      setModels(fullValues.l1, fullValues)
    }
    setPrevBind(fullValues)
  }

  return (
    <>
      <Snackbar open={snackbarIsOpen} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}
          action={
            <Button color="inherit" onClick={handleRedirect}>
              Continuar
            </Button>}
        >
          Operacion Exitosa
        </Alert>
      </Snackbar >
      <BasicFormStepByStep
        formMode={'vertical'}
        saveFunc={() => { }}
        show={true}
        showStepTitle={false}
        showSectionTitle={false}
        sectionAccordionMode={false}
        fieldColSize={12}
        onCanceled={() => { }}
        onUpdated={handleUpdate}
        onChangeField={(field, value, fullValues) => {
          onChangeField(field, value, fullValues);
        }}
        activePrevBind={activePrevBind}
        prevBind={prevBind}
        withDefault={false}
        mode={'update'}
        pk={userData.userData.id}
        pkObjectType={1}
        pkReferer={null}
        globalModel={0}
        objectType={'users'}
        bindNullWithDefault={process.env.REACT_APP_SANDBOX_AUTOCOMPLETE === 'true' ? true : false}
        autocompleteSandboxMode={process.env.REACT_APP_SANDBOX_AUTOCOMPLETE === 'true' ? true : false}
        objectTypeBindForm={'users'}
        parentType={'root'}
        parentTypeSubType={1}
        parentId={0}
        count={0}
        fields={fields}
        form={form}
        submitButtonText={t("stepByStep.button.submit")}
        showCancelButton={false}
        cancelButtontext={t("stepByStep.button.cancel")}
        submitOnlyLastStep={true}
      />
    </>
  );
}

export default StepByStepUserEdit;
