import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import useThemeContext from '../../../Hooks/useThemeContext';
import { useTranslation } from 'react-i18next';
import {
  LOGOUT,
  COMPANY_ALL,
  USER_DELEGATIONS
} from '../../../constants/paths';
import { getMenuItems } from '../../../services/sideBar';
import * as Icons from '@mui/icons-material'

const optionsPreHome = [
  {
    name: 'menu.box',
    icon: 'DomainOutlined',
    path: COMPANY_ALL,
  },
  {
    name: 'menu.delegations',
    icon: 'TransferWithinAStationOutlined',
    path: USER_DELEGATIONS,
  }
];

function SidebarMenu() {
  const { t } = useTranslation();
  const history = useHistory();
  const { menuMode } = useThemeContext();
  const [menuActive, setMenuActive] = useState([]);
  const [optionsHome, setOptionsHome] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { selectedAsset: { id: model }, mandatoryFieldsErrorStack, selectedBox } = useGlobalContext();

  useEffect(() => {
    getMenuItems(model).then(res => setOptionsHome(res.data))
  }, [model])

  const allOptionsHome = useMemo(() => {
    const options = [...optionsHome];
    if (mandatoryFieldsErrorStack.length !== 0) {
      options.unshift({ icon: "ErrorOutlineOutlined", name: "menu.requiredFields", path: "/requiredFields" });
    }
    return options;
  }, [optionsHome, mandatoryFieldsErrorStack]);

  useEffect(() => {
    setMenuActive(menuMode === 'HOME' ? allOptionsHome : optionsPreHome);
  }, [menuMode, allOptionsHome]);

  useEffect(() => {
    const index = menuActive.findIndex(option => history.location.pathname.includes(option.path));
    setSelectedIndex(index !== -1 ? index : 0);
  }, [history.location.pathname, menuActive]);

  const redirect = (path) => {
    history.push(path);
  }

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <List component="nav" aria-label="main mailbox folders">
      {menuActive.map((item, index) => {
        if((!item.permissions || item.permissions.includes(Number(selectedBox.subtype))))
          return <Tooltip key={item.name} title={mandatoryFieldsErrorStack.length === 0 ? t(item.name) : t('component.tooltip.requiredFields')} placement='right'>
            <span>
              <ListItemButton
                key={item.name}
                disabled={mandatoryFieldsErrorStack.length > 0 && menuMode === 'HOME' && item.name !== "menu.requiredFields"}
                sx={item.name === "menu.requiredFields" && {
                  '&.Mui-selected': {
                    backgroundColor: '#F3E3E3',
                    color: 'darkred',
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor: '#E9D9D9',
                  },
                }}
                selected={selectedIndex === index}
                onClick={() => { handleListItemClick(index); redirect(item.path) }}>
                <ListItemIcon>
                  {React.createElement(Icons[item.icon])}
                </ListItemIcon>
                <ListItemText primary={t(item.name)}/>
              </ListItemButton>
            </span>
          </Tooltip>
        return null
      })}
      <ListItemButton
        key={'menu.logout'}
        onClick={() => redirect(LOGOUT)}>
        <ListItemIcon>
          {React.createElement(Icons['SettingsOutlined'])}
        </ListItemIcon>
        <ListItemText primary={t('menu.logout')} />
      </ListItemButton>
    </List>
  );
}

export default SidebarMenu;
