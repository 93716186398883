import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';

function BasicGridData(props) {
  const [isInit] = useState(true);
  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    let rows = [...props.rows];
    setRows(rows);
  }, [props.rows]);

  useEffect(() => {
    let ret = [];
    if (props.actions) {
      ret.push({ field: 'actions', headerName: '', width: 20, renderCell: props.actions });
    }
    ret.push({ field: 'id', headerName: 'Id', width: 100 });

    for (let field of props.fields) {
      let flagAdd = false;
      if (field.permissions.list[props.parentType].includes(parseInt(props.parentTypeSubType)) === true) {
        flagAdd = true;
      }
      if (field.onlyEvent) {
        flagAdd = false;
      }

      if (flagAdd) {
        ret.push({
          field: field.name,
          headerName: field.label,
          width: 150
        })
      }
    }
    setColumns(ret);
  }, [props.fields, props.parentTypeSubType]);

  return (
    <div style={{ height: "600px", width: '100%' }}>
      {(props.show && isInit) &&
        <DataGrid
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          checkboxSelection={props.multiSelectMode}
          onRowClick={(row) => { props.onSelected(row) }}
          onSelectionModelChange={(evt) => { if (props.onMultipleSelected) props.onMultipleSelected(evt) }}
          autoHeight
        />
      }
    </div>
  )
}

export default BasicGridData;
