import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiFetch } from '../../Utils/ApiFetch';
import useGlobalContext from '../../Hooks/useGlobalContext';

import {
  Alert,
  Grid,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import { USER_DELEGATIONS_ALL, USER_DELEGATIONS_COMPANY_ALL } from '../../constants/paths';

import UserDelegationsStepper from './UserDelegationsStepper';
import UserDelegationsCompanyStepper from './UserDelegationsCompanyStepper';
import DelegationAccordion from './Components/DelegationAccordion';
import ConfirmDialog from '../../Components/Feature/ConfirmDialog';

import { useTranslation } from 'react-i18next'
import { getDelegationsMade } from '../../services/users';
import ModalMessage from '../../Components/Feature/ModalMessage';


// MAIN COMPONENT

function UserDelegations() {
  const { t } = useTranslation()
  let history = useHistory();

  const globalContext = useGlobalContext();
  const { calculateActualQuota, selectedCompany } = globalContext;

  const [delegationsMade, setDelegationsMade] = useState([]);
  const [delegationsReceived, setDelegationsReceived] = useState([]);

  const [dialog, setDialog] = useState(false);
  const [dialogCompany, setDialogCompany] = useState(false);

  const [alertInfo, setAlertInfo] = useState({
    show: false,
    alertText: '',
  });

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dataDelegation, setDataDelegation] = useState({});

  const [openModal, setOpenModal] = useState(false);
  const [modalText, setModalText] = useState(''); 

  useEffect(() => {
    getAllDelegationsMade();
    getAllDelegationsReceived();
    loadQuotaModal();
  }, []);

  const getAllDelegationsMade = () => {
    getDelegationsMade()
      .then(res => setDelegationsMade(res.data))
      .catch(err => console.log('error getUserDelegations', err))
  };

  const getAllDelegationsReceived = () => {
    ApiFetch(
      'users/delegations/received/all',
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          let data = await res.json();
          setDelegationsReceived(data);
        }
      },
      (error) => {
        console.log('error /users/delegations/received/all', error);
      },
      {
        method: 'GET',
      }
    );
  };

  const openModalCompany = () => setDialogCompany(true)

  const openModalEstablishment = () => setDialog(true)

  const showModal = (message) => {
    setModalText(message);
    setOpenModal(true);
  };

  const onClickBoxDelegationsMade = (box) => {
    history.push({
      pathname: USER_DELEGATIONS_ALL,
      state: {
        boxDelegate: box
      }
    });
  };

  const onClickCompanyDelegationsMade = (company) => {
    history.push({
      pathname: USER_DELEGATIONS_COMPANY_ALL,
      state: {
        companyDelegate: company
      }
    });
  };

// DELEGATIONS RECEIVED

  const deleteBoxDelegation = (data) => {
    const payload = {
      user: data.id_user,
      rol: data.id_template,
      box: data.id_entity
    };

    ApiFetch(
      'users/delegations/deleteBoxAsign',
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          const newDelegations = delegationsReceived.filter(d => d.id_entity !== data.id_entity);
          setDelegationsReceived(newDelegations);
        }
        else{
          console.log("Delete error: ",res);
        }
      },
      (error) => {
        console.log('error users/delegations/deleteBoxAsign', error);
      },
      {
        method: 'POST',
        body: JSON.stringify(payload)
      }
    );
  };

  const deleteCompanyDelegation = (data) => {
    const payload = {
      user: data.id_user,
      rol: data.id_template,
      company: data.id_entity
    };

    ApiFetch(
      'users/delegations/deleteCompanyAsign',
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          const newDelegations = delegationsReceived.filter(d => d.id_entity !== data.id_entity);
          setDelegationsReceived(newDelegations);
        }
        else{
          console.log("Delete error: ",res);
        }
      },
      (error) => {
        console.log('error users/delegations/deleteCompanyAsign', error);
      },
      {
        method: 'POST',
        body: JSON.stringify(payload)
      }
    );
  };

  const handleOnConfirm = () => {
    if (dataDelegation?.type === "BOX" && dataDelegation?.id_entity > 0)
      deleteBoxDelegation(dataDelegation);

    if (dataDelegation?.type === "COMPANY" && dataDelegation?.id_entity > 0)
      deleteCompanyDelegation(dataDelegation);
  }

  const handleOpenConfirm = (data) => {
    setDataDelegation(data);
    setConfirmOpen(true);
  }


// LOAD QUOTA MODAL

  const loadQuotaModal = async () => {
    const { remainingQuota, companiesOwnerLength } = await calculateActualQuota({
      id: selectedCompany.id,
      type: 'companyDelegations'
    });
    if (remainingQuota <= 0 && companiesOwnerLength > 0) {
      setAlertInfo((current) => ({
        ...current,
        show: true,
        alertText: t('quotaExceeded', { type: t('companyDelegations') }),
      }));
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="mb-2">
          <Stack direction="row" justifyContent="end">
            <Button
              disabled={alertInfo.show}
              className="ml-2"
              variant="contained"
              onClick={openModalCompany}>
              {t("stepByStep.button.user.assignRoleCompany")}
            </Button>
            <Button
              className="ml-2"
              variant="contained"
              onClick={openModalEstablishment}>
              {t("stepByStep.button.user.assignRole")}
            </Button>
          </Stack>
        </Grid>


        <Grid item xs={12} className="mb-2">
          <h4>{t("stepByStep.msg.delegationsMade")}</h4>
        </Grid>

        <Grid item xs={12} className="mb-2">
          <DelegationAccordion
            ariaControls={"panel1b-content"}
            id={"panel1b-header"}
            title={'Empresas'}
            expanded={true}
            content={delegationsMade.filter(
              (delegation) => delegation.type === "COMPANY" && delegation.origin === 'OWNER')}
            onClick={onClickCompanyDelegationsMade}
            open={true}
          />
        </Grid>

        <Grid item xs={12} className="mb-2">
          <DelegationAccordion
            ariaControls={"panel1b-content"}
            id={"panel1b-header"}
            title={'Establecimientos'}
            expanded={true}
            content={delegationsMade.filter(
              (delegation) => delegation.type === "BOX" && delegation.origin === 'OWNER')}
            onClick={onClickBoxDelegationsMade}
            open={true}
            xs={12}
          />
        </Grid>

        {alertInfo.show && (
          <Grid item xs={12} className="mb-2">
            <span className="d-flex flex-row align-center">
              <Alert severity="error">
                <div dangerouslySetInnerHTML={{ __html: alertInfo.alertText }}></div>
              </Alert>
            </span>
          </Grid>
        )}

        <Grid item xs={12} className="mb-2">
          <h4>{t("stepByStep.msg.delegationsReceived")}</h4>
        </Grid>

        <Grid item xs={12} className="mb-2">
          <DelegationAccordion
            ariaControls={"panel1b-content"}
            id={"panel1b-header"}
            title={'Empresas'}
            expanded={true}
            content={delegationsReceived.filter(
              (delegation) => delegation.type === "COMPANY" && delegation.origin === 'COMPANY_DELEGATE')}
            onClick={handleOpenConfirm}
            open={true}
          />
        </Grid>

        <Grid item xs={12} className="mb-2">
          <DelegationAccordion
            ariaControls={"panel2b-content"}
            id={"panel2b-header"}
            title={'Establecimientos'}
            expanded={true}
            content={delegationsReceived.filter(
              (delegation) => delegation.type === "BOX" && delegation.origin === 'COMPANY_DELEGATE')}
            onClick={handleOpenConfirm}
            open={true}
          />
        </Grid>

      </Grid>

      <>
        <Dialog maxWidth="xl" open={dialog} onClose={() => { }}>
          <DialogTitle>{t("component.roles.title")}</DialogTitle>
          <DialogContent style={{ minHeight: "500px", minWidth: "800px", maxWidth: "800px" }}>
            <UserDelegationsStepper
              onCancel={() => {
                setDialog(false);
              }}
              handleAsign={() => {
                setDialog(false);
              }}
              showModal={showModal}
            />
          </DialogContent>
        </Dialog>

        <Dialog maxWidth="xl" open={dialogCompany} onClose={() => { setDialogCompany(false); }}>
          <DialogTitle>{t("component.roles.title")}</DialogTitle>
          <DialogContent style={{ minHeight: "500px", minWidth: "800px", maxWidth: "800px" }}>
            <UserDelegationsCompanyStepper
              onCancel={() => {
                setDialogCompany(false);
              }}
              handleAsign={() => {
                setDialogCompany(false);
              }}
              showModal={showModal}
            />
          </DialogContent>
        </Dialog>
      </>

      <>
        <ConfirmDialog
          title={t("stepByStep.msg.deleteDelegation")}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleOnConfirm}
          onClose={()=>{}}
        >
          {t("stepByStep.msg.deleteDelegationConfirmMsg")}
        </ConfirmDialog>
      </>

      <ModalMessage open={openModal} toggleModal={setOpenModal} modalText={modalText} />
    </>
  );
}

export default UserDelegations;
