import { Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export const TrazeTable = ({ data, tt }) => {

  const onDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.click();
  };


  const FileShow = ({v}) =>{

    const [image,setImage] = useState(null);
    useEffect(()=>{
      try{
        if (v!==null && v!=="") {
          let file = JSON.parse(v);
          if (file?.originalName) {
            let originalNameSplit = file.originalName.split(".");
            if (['JPG','PNG','JPEG','TIFF','BMP','PDF'].includes(originalNameSplit[originalNameSplit.length-1].toUpperCase()) || true ) { //TODO todos los archivos
              setImage(file.publicFileURI)
            }
          }
        }
      }catch(e){
        console.log('error in table',e)
      }
    },[])



       return(          
          <div style={{display:'flex',justifyContent:'center'}}>
            
            {image!=null &&
              <Button
                  style={{
                  background: '#3969E8',
                  boxShadow: '0px 2.34783px 4.1087px rgba(221, 221, 221, 0.5)',
                  borderRadius: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: '14px',
                  maxHeight: '25px',
                  textTransform: 'none',
                  }}
                  onClick={()=>{onDownload(image)}}
                >
                  {tt('Descargar')}
                </Button>
                }
        </div>
        
        )
  }



 return (
  <div >
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} size="medium">
        <TableHead style={{backgroundColor:'rgb(57, 105, 232)', color:'white'}}>
          <TableRow>
            <TableCell style={{color:'white'}}>
             <Typography color='white' variant='body2'>
               {tt(data.value.headers[0])}
              </Typography>
            </TableCell>
            {data.value.headers.slice(1).map( h => (
            <TableCell align="center" style={{color:'white'}} >
              <Typography color='white' variant='body2'>
                 {tt(h)}
                 </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.value.values.map((row) => (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row" >
                  <Typography color='text.grey' variant='body2'>
                      {tt(row[0])}
                  </Typography>
                </TableCell>

              {row.slice(1).map( (v,i) => {

                return(
                <TableCell component="th" scope="row" align="center">
                    { data.value.headersType[i+1]==='file' ?
                         <FileShow v={v}  />
                    :
                      <Typography color='text.grey' variant='body2'>
                          {v?tt(v):'--'}
                      </Typography>
                    }
                </TableCell>
                )
              }
            )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
 );
};
