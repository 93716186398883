import instance from '../Config/axios';

export const getActualQuotaForCompany = async ({ id, type }, token) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `/billing/quota?companyId=${id}&type=${type}`;

  return await instance.get(url, config);
};
