import React from 'react';
import styled from 'styled-components';
import BasicCell from './BasicCell';

const StyledFixedCell = styled.div`
  background: transparent;
  border: 1px solid #d1d1d1;
  color: #176510;
  cursor: pointer;
  font-weight: 200;
  height: 95%;
  justify-content: center;
  margin: auto;
  padding: 12px;
  text-align: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  svg {
    margin-left: 4px;
    border-radius: 1em;
  }
`;

function FixedCell({ params, value, onClick }) {
  return (
    <StyledFixedCell onClick={onClick}>
      <BasicCell params={params} value={value} />
    </StyledFixedCell>
  );
}

export default FixedCell;
