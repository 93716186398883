import React from 'react';
import styled from 'styled-components';
import BasicCell from './BasicCell';

const StyledCellwithError = styled.div`
  color: lightgray;
  cursor: not-allowed !important;
  font-weight: 200;
  height: 95%;
  justify-content: center;
  margin: auto;
  padding: 5px 0 5px 0;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  svg {
    cursor: pointer;
    margin-left: 4px;
    border-radius: 1em;
  }
`;

function DisabledCell({ params, value }) {
  return (
    <StyledCellwithError>
      <BasicCell params={params} value={value} />
    </StyledCellwithError>
  );
}

export default DisabledCell;
