const modelSelection = (model, selectedItemArray) => {
  let modelKey = null

  for (const key of Object.keys(model).sort().reverse()) {
    if(!modelKey){

      if(key === "*") {
        modelKey = key
        continue
      }

      const keyArray = key?.split("_")

      if(keyArray.length == 1 && selectedItemArray.length == 1){
        if(key == selectedItemArray[0] || key === "*") modelKey = key
        else continue
      }

      if(!keyArray.includes("*")){
        if(key == selectedItemArray.join("_")) modelKey = key
        else continue
      } else {
        if(selectedItemArray.length > 1){
          const clonedItemArray  = [...selectedItemArray]
          let potentialKey = `${clonedItemArray.join("_")}_*`
          while(potentialKey !== key && clonedItemArray.length > 0){
            clonedItemArray.pop()
            potentialKey = `${clonedItemArray.join("_")}_*`
          }
          if(potentialKey === key) modelKey = key
        }
      }
    }
  }

  return modelKey
}

export default modelSelection