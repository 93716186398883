import React, { useState, useEffect } from 'react';
import './styles.css';
import LargeGridData from '../../../Components/Generic/LargeGridData';
import useGlobalContext from '../../../Hooks/useGlobalContext';

export default function ActivosBasicTable(props) {
  const globalContext = useGlobalContext();
  const { selectedAsset, selectedBox } = useGlobalContext();
  const [parentType] = useState('box');
  const [rows] = useState([]);
  const [fields, setFields] = useState([]);
  const [isShow] = useState(true);

  const [exportConfig, setExportConfig] = useState({
    'mode': false,
    'caption': '',
    'exportMode': 'editAsset',
    'redirectPath': '',
    'redirectArgs': ''
  });

  useEffect(() => {
    if (selectedAsset.id !== -1) {
      getModels();
    }
  }, [selectedAsset.id]);

  useEffect(() => {
    setExportConfig(props.exportConfig);
  }, [props.exportConfig]);

  const getModels = () => {
    try {
      if (selectedAsset.id !== -1) {
        let models = globalContext.getSessionDataByKey('models');
        if (models !== null) {
          let model = selectedAsset.id;
          if (models[model]) {
            setFields(models[model].attributes);
            return models;
          }
        }
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  const handleChangeRowCount = (count) => {
    props.onRowCountChange(count);
  };

  const moreActionsButton = (row) => {

    let txPaused = false;
    if (row.row['paused'] !== null) {
      if (row.row['paused']) {
        txPaused = true;
      }
    }
    if (txPaused) {
      return "";
    } else {
      return "";
    }
  };

  return (
    <>
      {isShow &&
        <div>
          <LargeGridData
            event={props.event}
            multiSelectMode={true}
            onMultipleSelected={(evt) => { }}
            parentType={parentType}
            parentTypeSubType={selectedBox.subtype}
            actions={moreActionsButton}
            show={true}
            onSelected={() => { }}
            rows={rows}
            fields={fields}
            onChangeRowCount={handleChangeRowCount}
            exportFilterDataConfig={exportConfig}
            exportFilterAction={props.exportFilterAction}
            entity={'asset'}
            url={`objects/get/all/${selectedAsset.id}/asset/${selectedAsset.id}/${selectedBox.id}/${props.lotPk}/0`}
            preSelectedRows={props.preSelectedRows}
            sessionContext={props.sessionContext}
            onSelectedRowsChange={props.onSelectedRowsChange}
          />
        </div>
      }
    </>
  );
}
