import React, { useEffect } from 'react';
import { Grid, Stepper, Step, StepButton, Divider } from '@mui/material';
import { ApiFetch } from '../../../Utils/ApiFetch';
import { useHistory } from 'react-router-dom';

import LoadFileTransfer from '../LoadFileTransfer';
import PairColumns from '../PairColumns';
import GridMassiveEdit from '../GridMassiveEdit';
import MassiveSummary from '../MassiveSummary';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import { TRANSFERS } from '../../../constants/paths';
import ModalMessage from '../../../Components/Feature/ModalMessage';
import BoxTarget from '../BoxTarget'
import { useTranslation } from 'react-i18next';
import { calculateLabelDates } from '../../../Utils/gridDefaultValues';
import { TOAST_TYPE } from '../../../constants';

const UploadContainerTransfer = (props) => {
  const { t } = useTranslation();
  let history = useHistory();

  const steps = [
    t("stepByStep.massive.transferStep.loadFileEvent"),
    t("stepByStep.massive.transferStep.pairColumns"),
    t("stepByStep.massive.transferStep.gridMassiveEdit"),
    t("stepByStep.massive.transferStep.boxTarget"),
    t("stepByStep.massive.transferStep.massiveSummary")
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const [assets, setAssets] = React.useState([]);
  const [maxStepAvailable, setMaxStepAvailable] = React.useState(0);
  const [pairColumns, setPairColumns] = React.useState([]);
  const [waitModal, setWaitModal] = React.useState(false);
  const [infoStep, setInfoStep] = React.useState(['', '', '', '']);
  const [fileInfo, setFileInfo] = React.useState('');
  const [excelUploadId, setExcelUploadId] = React.useState(-1);
  const [dataList, setDataList] = React.useState({});
  const [amountErrorsInitial, setAmountErrorsInitial] = React.useState(null);
  const globalContext = useGlobalContext();
  const [boxTarget, setBoxTarget] = React.useState(0);

  const { userData } = globalContext;
  const timeZone = userData?.userData?.timezone;

  useEffect(() => {
    setActiveStep(0);
    updateDataState('', 0);
    // TODO: DELETE THIS
    /*setExcelUploadId(82);
    setActiveStep(2);*/

    /*   setFileInfo({
      fileId: 'cb48b076-dff1-4ed6-a827-a7ca3a049a99',
      originalName: 'errorColumnas.csv',
      storageName: 'aHcPuRaVheAQY_J6Kz9Q-',
      checksum: 'ce41bc66b76f33aea5ed86b13a8bb485',
      publicFileURI: 'http://localhost:2022/file/download/cb48b076-dff1-4ed6-a827-a7ca3a049a99',
      repositoryId: 1,
    });
    setActiveStep(1); */
  }, []);

  useEffect(() => {
    // console.log(activeStep, infoStep);
  }, [activeStep]);

  const handleStep = (step) => () => {
    /* if (activeStep > step && infoStep[step] !== '') {
      // bring the info from that state
      switch (step) {
        case 0:
          break;
        case 1:
          setFileInfo(infoStep[step]);
          break;
        case 2:
          setExcelUploadId(infoStep[step]);
          break;
        case 3:
          setDataList(infoStep[step]);
          break;
      }
    } */
    if (step < maxStepAvailable) setActiveStep(step);
  };

  const onBoxSelected = (boxTarget) => {
    let boxTargetObj = {};
    if (boxTarget !== '') {
      boxTargetObj = JSON.parse(boxTarget);
    }
    setBoxTarget(boxTargetObj);
    setActiveStep(4);
  }

  const updateDataState = (data, index, index2 = -1) => {
    let newArr = [...infoStep]; // copying the old datas array
    newArr[index] = data;
    if (index2 !== -1) newArr[index2] = data;
    setInfoStep(newArr);
  };

  const handleUploadFile = (file) => {
    setFileInfo(file);
    setActiveStep(1);
    updateDataState(file, 1);

    setMaxStepAvailable(1);
  };

  const handleAllPair = (id) => {
    resetMassiveEditState();

    setExcelUploadId(id);
    setActiveStep(2);
    updateDataState(id, 2);

    setMaxStepAvailable(2);
  };

  const handleSubmit = (list) => {
    setDataList(list);
    setActiveStep(3);
    updateDataState(list, 3);

    setMaxStepAvailable(3);
  };

  const handleAmountErrorsInitial = (val) => {
    if (amountErrorsInitial == null) {
      setAmountErrorsInitial(val);
    }
  };

  const handleDataChange = (dataList, dataPairColumns) => {
    // dataList is the setAssets in the MassiveEdit
    setAssets(dataList);
    setPairColumns(dataPairColumns);
  };

  const resetMassiveEditState = () => {
    setAssets([]);
    setPairColumns([]);
    setMaxStepAvailable(1);
  };

  const handleFinalSubmit = () => {

    let dataListTemp = [];

    if (props.action === 'update') {
      dataList.map((data) => {
        let dataTemp = { ...data };
        delete dataTemp.id;
        let dataTempNoValuesFilter = {};
        for (let key of Object.entries(dataTemp)) {
          if (dataTemp[key[0]] != null) {
            dataTempNoValuesFilter[key[0]] = dataTemp[key[0]];
          }
        }
        dataListTemp.push({ ...dataTempNoValuesFilter, pk: data._pk_ });
      });
    }

    if (props.action === 'add') {
      dataListTemp = [...dataList];
    }

    let dataListTempLabels = [];
    for (let row of dataListTemp) {
      if (row._eventDate_) {
        let labelsTemp = {};
        const { dateLabel, dateValue } = calculateLabelDates(
          row._eventDate_,
          timeZone
        );
        labelsTemp._eventDate_ = dateLabel;
        row._eventDate_ = dateValue;
        dataListTempLabels.push(labelsTemp);
      }
    }

    let dataObj = {
      values: dataListTemp,
      labels: dataListTempLabels,
      headerValues: {
        //date:eventDate,
        //comment:eventComment
      },
      action: props.action,
      pk: props.pk,
      objectType: props.objectType, //"asset",
      pkObjectType: props.pkObjectType, //assetContext.objectTypeSubType,
      pkReferer: props.pkReferer,
      holder: props.parentId, //assetContext.parentId,
      boxSource: props.parentId, //assetContext.parentId,
      boxTarget: boxTarget.id, //assetContext.parentId,
      temporalDetailParentId: props.temporalDetailParentId, // -1
      globalModel: props.globalModel, //assetContext.model,
      actionContext: { context: 'massive_file_form', eventType: 1000 },
    };

    setWaitModal(true);
    ApiFetch(
      'objects/add/assets/transfer/massive',
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          props.onUploadOk(res);
          history.push({
            pathname: TRANSFERS,
            state: {
              toast: {
                type: TOAST_TYPE.SUCCESS,
                message: t('breadcrumb.asset.msg.massive_transfer'),
              },
            },
          });
        } else {
          props.onUploadError(res);
          setWaitModal(false);
        }
      },
      (error) => {
        props.onUploadError(error);
      },
      {
        method: 'POST',
        body: JSON.stringify(dataObj),
      }
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="mt-0">
        <Grid item xs={12} className="mt-2">
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={index < maxStepAvailable}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Divider variant="middle" className="mt-4" style={{ marginBottom: '5px' }} />
      </Grid>
      {activeStep === 0 && <LoadFileTransfer onUploadFile={handleUploadFile} {...props}></LoadFileTransfer>}
      {activeStep === 1 && (
        <PairColumns
          globalModel={props.globalModel}
          fileInfo={fileInfo}
          entityMode={props.entityMode}
          eventType={props.eventType}
          onAllPair={handleAllPair}></PairColumns>
      )}
      {activeStep === 2 && (
        <GridMassiveEdit
          data={assets}
          mode={'Edit'}
          manualUpload={true}
          enableEventDate={true}
          entityMode={props.entityMode}
          eventType={props.eventType}
          pairColumns={pairColumns}
          globalModel={props.globalModel}
          pkObjectType={props.pkObjectType}
          excelUploadId={excelUploadId}
          onDataChange={handleDataChange}
          onSubmit={handleSubmit}
          onSetAmountErrorsInitial={handleAmountErrorsInitial}></GridMassiveEdit>
      )}
      {activeStep === 3 && (
        <>
          <BoxTarget
            assetsList={[...dataList.map(asset => asset._pk_)]}
            onBoxSelected={
              (boxTarget) => { onBoxSelected(boxTarget)
            }}
          />
        </>
      )}
      {activeStep === 4 && (
        <MassiveSummary
          showControls={true}
          dataList={dataList}
          globalModel={props.globalModel}
          onFinalSubmit={handleFinalSubmit}
          amountErrorsInitial={amountErrorsInitial}></MassiveSummary>
      )}

      <ModalMessage open={waitModal} toggleModal={() => { }} modalText={'Procesando...'} />
    </Grid>
  );
};

export default UploadContainerTransfer;
