import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import App from "./App";
import AccessPublic from "./AccessPublic";
import SignInPublic from "./SignInPublic";
import SignUpPublic from "./SignUpPublic";
import SignIn from "../pages/SignIn/SignIn";
import "./theme/global.scss";
import ThemeContextProvider from '../Contexts/themeContext';
import { LOGIN, ACCESS, PUBLIC_SIGNIN, PUBLIC_SIGNUP, REGISTER, SIGNIN, QR_IDENTECO_ID, QR_PRODUKTO_TRUPO_ID, QR_IDENTECO_TRUPO_ID } from '../constants/paths';
import DisconnectModal from "../Components/Modal/OfflineModal";
import { QrIdenteco, QrIdentecoTrupo, QrProduktoTrupo } from "../pages/Qr";

function RouterApp() {
  return (
    <ThemeContextProvider>
      <DisconnectModal />
      <Router>
        <Switch>
          <Route path="/" exact>
            <Redirect
              to={{
                pathname: LOGIN,
                state: {}
              }}
            />
          </Route>
          <Route path={LOGIN} component={AccessPublic} exact />
          <Route path={ACCESS} component={AccessPublic} />
          <Route path={PUBLIC_SIGNIN} component={SignInPublic} />
          <Route path={PUBLIC_SIGNUP} component={SignUpPublic} />
          <Route path={REGISTER} component={SignUpPublic} exact />
          <Route path={SIGNIN} component={SignIn} />
          <Route exact path={QR_IDENTECO_ID} component={QrIdenteco} />
          <Route exact path={QR_PRODUKTO_TRUPO_ID} component={QrProduktoTrupo} />
          <Route exact path={QR_IDENTECO_TRUPO_ID} component={QrIdentecoTrupo} />
          <Route>
            <App />
          </Route>
        </Switch>
      </Router>
    </ThemeContextProvider>

  );
}

export default RouterApp;
