import React, { useState, useEffect } from 'react';
import { Grid, Card, Chip, FormControl, Button } from '@mui/material';
import RenderModelFieldFilter from './RenderModelFieldFilter';
import useGlobalContext from "../../Hooks/useGlobalContext";
import { ApiFetch } from '../../Utils/ApiFetch';
import { useTranslation } from 'react-i18next'

export default function BasicFilter(props) {
  const { t } = useTranslation();
  const [tempFieldsValues, setTempFieldsValues] = useState([]);
  const { filterValues, setFilterValues, setFilterValuesLoaded } = props;
  const [expandedFilters, setExpandedFilters] = useState();
  const [newFilterToApply, setNewFilterToApply] = useState(false)
  const [localFields, setLocalFields] = useState([]);
  const { updateSessionData, getSessionDataByKey, selectedAsset, selectedBox } = useGlobalContext();
  const [boxLots, setBoxLots] = useState([]);
  const [viewExpanded, setViewExpanded] = useState(false);
  const globalContext = useGlobalContext()
  //props.columns tengo las columnas filtradas

  useEffect(() => {
    if (props.entity === 'asset') {
      ApiFetch(
        props.urlBoxesLots,
        globalContext,
        true,
        async (body) => {
          let boxLotsTemp = [
            { key: '__withouttag__', label: t('filter.tag.without_tag') },
          ];
          try {
            let { rows } = await body.json();
            for (let lot of rows) {
              boxLotsTemp.push({ key: lot.lot_name, label: lot.lot_name });
            }

            setBoxLots(boxLotsTemp);
          } catch (e) {
            console.log('error lots', e);
          }
        },
        (error) => {},
        {
          method: 'GET',
        }
      );
    }
  }, [selectedBox.id]);

  useEffect(() => {
    setLocalFields([...props.fields.map((f) => ({ ...f })), {
      name: "search",
      massiveImport: true,
      hideInList: false,
      onlyEvent: true,
      modelType: "metadata",
      tableFilter: {
          isEnabled: true,
          isExpanded: true
      },
      validations: {
          mandatory: {
              value: true,
              message: "Campo obligatorio"
          }
      },
      permissions: {
          list: {
              box: [
                  27,
                  28,
                  29
              ]
          },
          new: {
              box: [
                  27,
                  28,
                  29
              ]
          },
          edit: {
              box: [
                  27,
                  28,
                  29,
              ]
          }
      },
      label: "Búsqueda libre",
      type: "text",
      defaultValue: ""
  }]);
    setViewExpanded(false);
  }, [props.fields])

  useEffect(() => {
    if (filterValues.length > 0) {
      let newObject = {}
      newObject[`assetTableFilterStatus_${selectedAsset.id}_` + (props.sessionContext === undefined ? '' : props.sessionContext)] = { tempFieldsValues, filterValues, newFilterToApply }
      updateSessionData(newObject);
    }
  }, [tempFieldsValues, filterValues, newFilterToApply]);


  useEffect(() => {
    let filterStatusFromSession = getSessionDataByKey(`assetTableFilterStatus_${selectedAsset.id}_` + (props.sessionContext === undefined ? '' : props.sessionContext));
    if (filterStatusFromSession !== undefined) {
      if (filterStatusFromSession.tempFieldsValues) {
        setTempFieldsValues(filterStatusFromSession.filterValues);
        setFilterValues(filterStatusFromSession.filterValues);
        setNewFilterToApply(filterStatusFromSession.newFilterToApply);
        setFilterValuesLoaded(true)
      }
    }
    setFilterValuesLoaded(true)
  }, [selectedAsset.id]);

  const toggleChecked = () => setExpandedFilters(value => !value);

  const applyFilters = () => {
    let newFieldsValues = [...tempFieldsValues]
    setFilterValues(newFieldsValues);
    setNewFilterToApply(false);
  }

  const removeFieldByPosition = async (index) => {
    let values = [...filterValues];
    let valuesTemp = [...tempFieldsValues];

    const indexReset = valuesTemp.findIndex(v => { return v.field === values[index].field; });
    values.splice(index, 1)
    setFilterValues(values)
    if (index > -1) { valuesTemp.splice(indexReset, 1) }
    setTempFieldsValues(valuesTemp);

    let newObject = {};
    newObject[`assetTableFilterStatus_${selectedAsset.id}_` + (props.sessionContext === undefined ? '' : props.sessionContext)] = { valuesTemp, values }
    await updateSessionData(newObject);

  }

  const onChange = (field, val, name, type, limit) => {
    let valuesTemp = [...tempFieldsValues];

      switch (type) {
        case '=':
          let fieldAtts = localFields.filter((f) => f.name === field)
          if (fieldAtts.length === 1) {
            const index = valuesTemp.findIndex(v => { return v.field === field; });
            if (index > -1) { valuesTemp.splice(index, 1) }
          }
          if (val !== null && val !== '') {
            //estructura field
            if(field === 'search'){
              valuesTemp.push({ field: field, value: val, operator: '=', name: name, subFields: props.columns.filter(c => c.fieldType !== 'paused').map(c => {return {field: c.field.toLowerCase(), name: c.headerName}}) })
            } else {
              valuesTemp.push({ field: field, value: val, operator: '=', name: name })
            }
          }
          break;

        case '===':
            let fieldSelect = localFields.filter((f) => f.name === field)
            if (fieldSelect.length === 1) {
              const index = valuesTemp.findIndex(v => { return v.field === field; });
              if (index > -1) { valuesTemp.splice(index, 1) }
            }
            if (val !== null && val !== '') {
              valuesTemp.push({ field: field, value: val, operator: '===', name: name })
            }
            break;

        case 'inArray':
          const index = valuesTemp.findIndex(v => { return v.field === field; });
          if (index > -1) {
            valuesTemp.splice(index, 1)
          }
          if (val.length > 0) {
            valuesTemp.push({ ...{ field: field, values: val, operator: 'inArray', name: name } })
          }
          break;

        case 'betweenValues':
          if (val !== null && val !== '') {
            const index = valuesTemp.findIndex(v => { return v.field === field; });
            if (index > -1) {
              let valueField = valuesTemp[index]
              valuesTemp.splice(index, 1)
              valueField[limit] = val
              valuesTemp.push(valueField)
            } else {
              let valueField = { field: field, max: 0, min: 0, operator: 'betweenValues', name: name }
              valueField[limit] = val
              valuesTemp.push(valueField)
            }
          }
          break;

        case 'betweenDates':
          if (val !== null && val !== '') {
            const index = valuesTemp.findIndex(v => { return v.field === field; });
            if (index > -1) {
              let valueField = valuesTemp[index]
              valuesTemp.splice(index, 1)
              valueField[limit] = val
              valuesTemp.push(valueField)
            } else {
              let valueField = { field: field, max: 0, min: 0, operator: 'betweenDates', name: name }
              valueField[limit] = val
              valuesTemp.push(valueField)
            }
          }
          break;

        default:
          break;
      }

    setTempFieldsValues(valuesTemp)
    setNewFilterToApply(true)
  }


  return (
    <>
      <Grid component={Card} container sx={{ p: 2, mb: 4, mt: 2, alignItems: 'center', boxShadow: "1" }} >
        {(props.entity === 'asset') &&
          <Grid item component={FormControl} sx={{ pr: 2 }} xs={3} >
            <RenderModelFieldFilter
              field={
                {
                  name: "_lot_",
                  tableFilter: { isEnabled: true, isExpanded: false },
                  massiveImport: true,
                  modelType: "metadata",
                  validations: {
                    validValues: {
                      value: true,
                      message: t("filter.modelField.tag.message"),
                    },
                  },
                  permissions: {
                    list: { box: [5, 6, 7, 8, 9, 10, 11] },
                    new: { box: [5, 6, 7, 8, 9, 10, 11] },
                    edit: { box: [5, 6, 7, 8, 9, 10, 11] },
                  },
                  type: "multiselect",
                  label: t("filter.modelField.tag.label"),
                  dataSourceType: "values",
                  values: boxLots,
                }
              }
              xSize={12}
              filterValues={filterValues}
              tempFieldsValues={tempFieldsValues}
              onChange={onChange}
            />
          </Grid>
        }

        {localFields.map((fo, index) => {
          let f = { ...fo };
          if (f.tableFilter !== undefined) {
            if (f.tableFilter.isEnabled && f.tableFilter.isExpanded) {
              if (f.tableFilter.fieldType !== undefined) {
                f.type = f.tableFilter.fieldType
                if (f.tableFilter.dataSourceType !== undefined) {
                  f.dataSourceType = f.tableFilter.dataSourceType
                  if (f.tableFilter.values !== undefined) {
                    f.values = f.tableFilter.values
                  }
                }
              }

              return (
                <Grid item component={FormControl} sx={{ pr: 2 }} xs={3} key={index}>
                  <RenderModelFieldFilter
                    field={f}
                    xSize={12}
                    filterValues={filterValues}
                    tempFieldsValues={tempFieldsValues}
                    onChange={onChange}
                  />
                </Grid> 
              )
            }
          }
        })
        }

        {(viewExpanded) &&
          <Grid item component={FormControl} sx={{ pr: 2, justifyContent: 'left' }} xs={12} >
            <Button variant="text" onClick={toggleChecked}>
              {!expandedFilters ? t("filter.button.more") : t("filter.button.close")}
            </Button>
          </Grid>
        }
        {expandedFilters &&
          <><br />
            {localFields.map((f, index) => {
              if (f.tableFilter !== undefined) {
                if (f.tableFilter.isEnabled && !f.tableFilter.isExpanded) {
                  if (f.tableFilter.fieldType !== undefined) { f.type = f.tableFilter.fieldType }
                  return (
                    <Grid item component={FormControl} sx={{ pr: 2 }} xs={3} key={index}>
                      <RenderModelFieldFilter
                        field={f}
                        xSize={12}
                        filterValues={filterValues}
                        tempFieldsValues={tempFieldsValues}
                        onChange={onChange}
                      />
                    </Grid>
                  )
                }
              }
            })
            }
          </>
        }
        <Grid item component={FormControl} sx={{ pr: 2, justifyContent: 'flex-end' }} xs={12} >
          <Button variant="outlined" disabled={!newFilterToApply} onClick={() => { applyFilters() }}>
            {t("filter.button.apply")}
          </Button>
        </Grid>
        {filterValues.length > 0 &&
          <Grid item xs={12} sx={{ mt: 2 }} style={{ borderTop: '1px solid #ddd', padding: '1rem' }}>
            {filterValues.map((f, index) => {
              switch (f.operator) {
                case '=':
                  let operatorStr = f.operator === '=' ? t("filter.chip.like") : f.operator;
                  return (<Chip label={`${f.name} ${operatorStr} ${f.value}`} color="secondary" sx={{ mr: 1 }} style={{ margin: '2px' }} onDelete={() => { removeFieldByPosition(index) }} />)
                case '===':
                  let operatorStrSelect = f.operator === '===' ? t("filter.chip.strictlyLike") : f.operator;
                  return (<Chip label={`${f.name} ${operatorStrSelect} ${f.value}`} color="secondary" sx={{ mr: 1 }} style={{ margin: '2px' }} onDelete={() => { removeFieldByPosition(index) }} />)
                case 'inArray':
                  if (f.values.length > 0) {
                    let value = f.values[0]
                    for (let i = 1; i < f.values.length; i++) { value += ` ${t("filter.chip.or")} ${f.values[i]}` }
                    return (<Chip label={`${f.name} ${t("filter.chip.like")} ${value}`} color="secondary" sx={{ mr: 1 }} style={{ margin: '2px' }} onDelete={() => { removeFieldByPosition(index) }} />)
                  }
                  break;
                case 'betweenValues':
                  return (<Chip label={`${f.name}: ${f.min} ${t("filter.chip.to")} ${f.max}`} color="secondary" sx={{ mr: 1 }} style={{ margin: '2px' }} onDelete={() => { removeFieldByPosition(index) }} />)
                case 'betweenDates':
                  return (<Chip label={`${f.name}: ${f.min} ${t("filter.chip.to")} ${f.max}`} color="secondary" sx={{ mr: 1 }} style={{ margin: '2px' }} onDelete={() => { removeFieldByPosition(index) }} />)
                default:
                  break;
              }
            }
            )}
          </Grid>
        }
      </Grid>
    </>
  )
}
