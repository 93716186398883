import { get, post } from '../api';
import { GET_FILE_URL, POST_PUBLIC_FILE_URL } from '../Utils/url';

export const getFileUploadUrl = () => {
  return get(GET_FILE_URL, false);
};

export const savePublicFile = (data) => {
  return post(POST_PUBLIC_FILE_URL, data, false);
};
