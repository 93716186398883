import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';


import { greenTheme } from '../Layout/theme/greenTheme';
import { pinkTheme } from '../Layout/theme/pinkTheme';
import { blueTheme } from '../Layout/theme/blueTheme';



export const ThemeContext = createContext();

export default function ThemeContextProvider({ children }) {
  

  const [activeTheme, setActiveTheme] = useState(greenTheme);
  const [menuMode, setMenuMode] = useState('HOME');
  const [activeAsset, setActiveAsset] = useState(-1);

  const setActiveThemeFromAsset = (n) => { // tododromero
    setActiveAsset(n);
    if (menuMode==='HOME') {
      if (n===11) {
        setActiveTheme(pinkTheme)
      } else if (n===12) {
        setActiveTheme(greenTheme)
      }
      else {
        setActiveTheme(greenTheme)
      }
    }
  }

  useEffect(() => {
    if (localStorage.getItem('MENU_MODE')) {
      setMenuMode(localStorage.getItem('MENU_MODE'));
    }
  }, []);

  
  useEffect(() => {
    // change color background
    if (menuMode === 'PREHOME') {
      setActiveTheme(blueTheme);
    } else if (menuMode === 'HOME'){
      setActiveThemeFromAsset(activeAsset);
      //setActiveTheme(greenTheme);
    }
    localStorage.setItem('MENU_MODE', menuMode);
  }, [menuMode]);

  const swithMenuMode = (mode) => {
    setMenuMode(mode);
  }

  const value = {
    
    activeTheme, 
    setActiveTheme,
    menuMode, 
    swithMenuMode,
    setActiveThemeFromAsset,
    
  };

  

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
}

ThemeContextProvider.propTypes = {
  children: PropTypes.object,
};
