import React from 'react';
import styled from 'styled-components';
import { FormHelperText } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';

const StyledFormHelperText = styled(FormHelperText)`
  color: red !important;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

function ErrorField(props) {
  const { errors } = props;
  return (
    <>
      {errors.map((messages) => (
        <StyledFormHelperText>
          <StyledFontAwesomeIcon icon={faExclamationTriangle} />
          {messages}
        </StyledFormHelperText>
      ))}
    </>
  );
}

export default ErrorField;
