import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import UploadIcon from '../../../assets/icons/upload.svg';

const Dropzone = (props) => {
  const {
    onChange,
    onError,
    validFormats,
    maxSize,
    error,
    errorMessage,
    loading,
    success,
    restored,
    restoredFile,
    selectedFileInfo,
    uploader,
    defaultText,
    uploadedFileText,
  } = props;

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: maxSize * 1024 * 1024,
    accept: validFormats,
    disabled: restored,

    onDropAccepted: (acceptedFiles, event) => {
      onChange(null, acceptedFiles);
    },
    onDropRejected: (event) => {
      const errorCode = event[0].errors[0].code;

      switch (errorCode) {
        case 'file-too-large':
          onError(`File is larger than ${maxSize} MB`);
          break;
        case 'file-invalid-type':
          onError(`${event[0].errors[0].message}`);
          break;
        default:
          onError(`${errorCode}`);
      }
    },
  });

  const baseStyle = useMemo(
    () => ({
      /*
      padding: "20px",
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      borderRadius: "5px",
      backgroundColor: "#CCCDD7",*/
      padding: '20px',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      display: 'flex',
      color: '#3969E8',
      outline: 'none',
      transition: 'border .24s ease-in-out',
      maxWidth: '100%',
      // added
      border: '2px dashed #3969E8',
      borderRadius: '4px',
    }),
    []
  );

  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    [baseStyle]
  );

  const styleDrop = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '8px',
  };

  const styleFilenameText = {
    //width: '250px',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '12px',
  };

  const styleUploaderButton = {
    width: '100%',
    margin: 'auto',
    marginTop: '5px'
    //margin: '4px',
    //marginLeft: '10px',
  };

  const styleUploader = {
    margin: '4px',
  };

  return (
    <div {...getRootProps({ style })}>
      <div style={styleDrop}>
        <input {...getInputProps()} />
        <div style={{textAlign:'center'}}>
          {loading || success ? (
            <div style={styleFilenameText}>{selectedFileInfo?.name}</div>
          ) : restored ? (
            restoredFile?.originalName
          ) : error ? (
            errorMessage
          ) : (
            <div style={{ display: 'flex' }}>
              <img
                style={{ marginRight: '1rem' }}
                src={UploadIcon}
                alt="Upload Icon"
              />{' '}
              <div>{defaultText}</div>{' '}
            </div>
          )}
          {(success || loading || restored || error) && (
            <div style={styleUploaderButton}>{uploader}</div>
          )}
        </div>
        
      </div>
      <div style={styleUploader}>
        <p>{success && !loading ? uploadedFileText : ''}</p>
      </div>
    </div>
  );
};

export default React.memo(Dropzone);
