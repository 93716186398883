import instance from '../Config/axios'

export const createCompanyInvitation = async ({ email, company_id, permission_template_id }) => {
  const token = localStorage.getItem('USER_TOKEN');

  const config = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    }
  };

  const url = 'invitations/create/company';

  const data = {
    email,
    company_id,
    permission_template_id,
  };

  try {
    const response = await instance.post(url, data, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createBoxInvitation = async ({ email, box_id, permission_template_id }) => {
  const token = localStorage.getItem('USER_TOKEN');

  const config = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    }
  };

  const url = 'invitations/create/box';

  const data = {
    email,
    box_id,
    permission_template_id,
  };

  try {
    const response = await instance.post(url, data, config);
    return response;
  } catch (error) {
    console.error('Error en createBoxInvitation:', error);
    throw error;
  }
};

export const getInvitationByHash = async (hash) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const url = `invitations/${hash}`;

  try {
    const response = await instance.get(url, config);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      throw new Error('Error de red o conexión');
    }
  }
};
