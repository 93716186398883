import { Grid, InputLabel } from '@mui/material';
import Multiselect from 'multiselect-react-dropdown';
import React from 'react';

function MultiSelectField({
  name,
  label,
  disabled,
  options,
  value,
  onChange,
  size,
  fieldFk,
  valueFk,
}) {
  return (
    <>
      <Grid item sx={{ pr: 2, mb: 2 }}>
        <Grid item sx={{ pr: 2}}>
          <InputLabel id={`multiSelect-label-${name}`}>{label}</InputLabel>
        </Grid>
        <Grid item sx={{ pr: 2, mb: 2 }}>
          <Multiselect
            style={{
              inputField: {
                margin: '2px',
              },
            }}
            size={size}
            placeholder={'Seleccionar...'}
            disabled={disabled}
            name={name}
            label={`Seleccionar ${label}`}
            options={
              fieldFk
                ? [
                    ...new Map(
                      options
                        ?.filter((item) => {
                          if (valueFk) {
                            let arrayValueFk = valueFk?.split(',');
                            for (let valueFk of arrayValueFk) {
                              if (item.fk === valueFk) {
                                return true;
                              }
                            }
                          }
                          return false;
                        })
                        ?.map((item) => {
                          return { name: item.label, id: item.key };
                        })
                        .map((item) => [item['id'], item])
                    ).values(),
                  ]
                : options?.map((item) => {
                    return { name: item.label, id: item.key };
                  })
            } // Options to display in the dropdown
            // FIX: que me paso Ivan
            selectedValues={
              value === '' || value === undefined	
              ? 
              []	
              : 
              value.split(',').map((item) => {	
                  let i = options.find(x => x.key === item);	
                  return i?{ name: i.label, id: i.key }:undefined;	
                }).filter(element => element!==undefined)
            } // Preselected value to persist in dropdo
            onSelect={(val) => onChange(name, val)} // Function will trigger on select event
            onRemove={(val) => onChange(name, val)} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
          />
          
        </Grid>
      </Grid>
    </>
  );
}

export default MultiSelectField;
