import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectChip(props) {
  const { field, onChange, selectedValues } = props

  const handleChange = (event) => {
    const { target: { value }, } = event;
    onChange(typeof value === 'string' ? value.split(',') : value,
    )
  };

  return (
    <>
      <InputLabel size="small" id="demo-multiple-chip-label">{field.label}</InputLabel>
      <Select size="small"
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        sx={{ width: '100%', mr: 2 }}
        value={selectedValues}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip"
          label={field.label} />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip size="small" key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {(field.values.length === 0) &&
          <MenuItem disabled value=""><em>Sin Opciones</em></MenuItem>
        }
        {field.values.map((v) => (
          <MenuItem
            key={v.key}
            value={v.label}
          >
            {v.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}