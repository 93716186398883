import React, { useEffect, useState } from 'react'
import { QrTemplate } from '../components'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getQrIdenteco, postQrIdenteco } from '../../../services/qr';
import { useTranslation } from 'react-i18next';

export const QrIdenteco = () => {
  const { id } = useParams();
  const [attributes, setAttributes] = useState()
  const [errorMessage, setErrorMessage] = useState(false)
  const { t } = useTranslation();

  useEffect(() => {
    getQrIdenteco(id)
      .then(res => setAttributes(res.data))
      .catch(err => {
        console.log('Error getQrIdenteco', err)
        setErrorMessage(true)
      })
    postQrIdenteco(id)
  }, [id]);

  return (
    <QrTemplate
      attributes={attributes}
      editMode={false}
      errorMessage={errorMessage ? t('template.error') : ''}
    />
  )
}
