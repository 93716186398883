import { useState, useEffect } from 'react';
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const { t } = useTranslation();
  const [selectedLoadType, setSelectedLoadType] = useState('');
  const [allEvents, setEvents] = useState([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [selectedUser, setSelectedUser] = useState('');

	const events = {
		all:{name:t("activities.labels.optionEvents.all")},
		singleLoad:{name:t("activities.labels.optionEvents.singleLoad")},
		singleUpdate:{name:t("activities.labels.optionEvents.singleUpdate")},
		massiveUpdate:{name:t("activities.labels.optionEvents.massiveUpdate")},
		massiveLoad:{name:t("activities.labels.optionEvents.massiveLoad")},
	}

  useEffect(() => {
		if(props.events){
			setEvents([
				{ eventType: null, name: events.all.name },
				{ eventType: '-1', name: events.singleLoad.name },
				{ eventType: '-1', name: events.singleUpdate.name },
				{ eventType: '-1', name: events.massiveLoad.name },
				{ eventType: '-1', name: events.massiveUpdate.name },
				...props.events,
			]);
		}else{
			setEvents([
				{ eventType: null, name: events.all.name },
				{ eventType: '-1', name: events.singleLoad.name },
				{ eventType: '-1', name: events.singleUpdate.name },
				{ eventType: '-1', name: events.massiveLoad.name },
				{ eventType: '-1', name: events.massiveUpdate.name },
			]);
		}

  }, [props.events]);

	const filterType = (filtered) => {
		if(selectedLoadType){
			
			switch (selectedLoadType.name) {
				case events.all.name:
					filtered = props.activities
					break;
				case events.singleLoad.name:
					filtered = 
						filtered.filter(
							(a) =>
								(a.event_context.context == 'file_form' &&
									a.event_context.eventType == '-1' &&
									a.event_type == 'add') ||
								(a.event_context.context == 'manual_form' &&
									a.event_context.eventType == '-1' &&
									a.event_type == 'add')
						)
					break;
				case events.singleUpdate.name:
					filtered = 
						filtered.filter(
							(a) =>
								(a.event_context.context == 'file_form' &&
									a.event_context.eventType == '-1' &&
									a.event_type == 'update') ||
								(a.event_context.context == 'manual_form' &&
									a.event_context.eventType == '-1' &&
									a.event_type == 'update')
						)
					break;
				case events.massiveLoad.name:
					filtered = 
						filtered.filter(
							(a) =>
								(a.event_context.context == 'massive_file_form' &&
									a.event_context.eventType == '-1' &&
									a.event_type == 'add') ||
								(a.event_context.context == 'massive_manual_form' &&
									a.event_context.eventType == '-1' &&
									a.event_type == 'add')
						)
					break;
				case events.massiveUpdate.name:
					filtered = 
						filtered.filter(
							(a) =>
								(a.event_context.context == 'massive_file_form' &&
									a.event_context.eventType == '-1' &&
									a.event_type == 'update') ||
								(a.event_context.context == 'massive_manual_form' &&
									a.event_context.eventType == '-1' &&
									a.event_type == 'update')
						)
					break;
				default:
					filtered = 
						filtered.filter(
							(a) => a.event_context.eventType == selectedLoadType.eventType
						)
					break;
			}
		}

		return filtered

	}

  useEffect(() => {

		let filtered = [...props.activities]
		filtered = filterType(filtered)

		if(selectedUser){
			filtered = filtered.filter((a) =>
				a.username.toLowerCase().includes(selectedUser.toLowerCase())
			)
		}

		if (dateFrom) {
      filtered = filtered.filter((a) => new Date(a.timsta) >= new Date(dateFrom) )
    }

		if(dateTo){
      filtered = filtered.filter((a) => new Date(a.timsta) <= new Date(dateTo) )
		}

		props.setActivities(filtered);
  }, [dateFrom, dateTo, selectedLoadType, selectedUser, props.activities]);

  const HandleOnClickLoadEvent = (option) => {
		setSelectedLoadType(option);
  };

  const HandleOnChangeUser = (username) => {
		setSelectedUser(username)
  };

  const HandleOnChangeDates = (value, type) => {
    if (type === 'from' && value) {
      setDateFrom(value);
    } else if (type === 'to' && value) {
      setDateTo(value);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{ pr: 2, mb: 2 }}
        justifyContent="flex-end">
        <Grid item xs={3}>
          <FormControl fullWidth sx={{ color: '#666666', mb: 1 }}>
            <InputLabel size="small" id='tipo-evento'>{t("activities.labels.inputLabels.eventType")}</InputLabel>
            <Select
              disabled={false}
              size="small"
							label={t("activities.labels.inputLabels.eventType")}
							labelId='tipo-evento'
              value={selectedLoadType.name}
              onChange={(e) => HandleOnClickLoadEvent(e.target.value)}>
              {allEvents.map((itemItem) => (
                <MenuItem value={itemItem} key={itemItem.name}>
                  {itemItem.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth sx={{ color: '#666666', mb: 1 }}>
						<InputLabel size="small" id='nombre-usuario'></InputLabel>
            <TextField
              size="small"
							label={t("activities.labels.inputLabels.userName")}
							labelId='nombre-usuario'
              type="text"
							value={selectedUser}
              onChange={(e) => HandleOnChangeUser(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth sx={{ color: '#666666', mb: 1 }}>
						<InputLabel size="small" id='fecha-desde'></InputLabel>
            <TextField
              size="small"
							InputLabelProps={{ shrink: true }}
							label={t("activities.labels.inputLabels.dateFrom")}
							labelId='fecha-desde'
              type="date"
              onChange={(e) => HandleOnChangeDates(e.target.value, 'from')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth sx={{ color: '#666666', mb: 1 }}>
						<InputLabel size="small" id='fecha-hasta'></InputLabel>
            <TextField
              size="small"
							InputLabelProps={{ shrink: true }}
							label={t("activities.labels.inputLabels.dateTo")}
							labelId='fecha-hasta'
              type="date"
              onChange={(e) => HandleOnChangeDates(e.target.value, 'to')}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};
