import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

function CheckBoxField(props) {
  const { label, disabled, name, checked, onChange } = props;
  return (
    <FormControlLabel
      sx={{mr: -0.5}}
      control={
        <Checkbox
          label={label}
          disabled={disabled}
          name={name}
          checked={checked}
          onChange={(val) => onChange(name, val.target.checked)}
          placeholder={`Ingrese ${label}`}
        />
      }
    />
  );
}

export default CheckBoxField;
