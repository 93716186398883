import { Typography, Checkbox } from '@mui/material';

export const AttributeMultipleCheckbox = ({ data, tt }) => {
  return (
    <>
      <Typography color='text.secondary' variant='h3'>
        {tt(`qrTemplate.${data.label}`)}
      </Typography>
      <div style={{ display: 'flex', flexFlow: 'wrap', width: '100%' }} className='row-content'>
        {data.value.map((v) => (
          <div style={{ fontSize: '0.8rem', marginRight: '0.5rem', marginBottom: '0.5rem', padding: '0.3rem', borderRadius: '0.5rem' }} >
            <Checkbox disabled checked={v.value} /> {v.label}
          </div>
        ))}
      </div>
    </>
  );
};
