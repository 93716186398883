import React, { useState, useEffect } from 'react';
import useGlobalContext from '../../Hooks/useGlobalContext';
import 'react-toastify/dist/ReactToastify.css';
import ArchivoNotificaciones from '../../Components/Activity/ArchivoNotificaciones'
import { ApiFetch } from '../../Utils/ApiFetch';

function Activities(props) {
  const { selectedBox } = useGlobalContext();
  const globalContext = useGlobalContext();
  const [activities,setActivities] = useState([])

  useEffect(()=>{
    bindActivities(selectedBox.id);
  },[selectedBox]);

  const bindActivities = async(boxId) => {
    ApiFetch(
      'boxes/activities/'+boxId,
      globalContext,
      true,
      async (result) => {
        if (result.status === 200) {
          let resJsonTemp = await result.json();
          setActivities(resJsonTemp)
        }
      },
      (error) => {
        
        console.log('error boxes/activities', error);
      },
      {
        method: 'GET',
      }
    );
  }

  
  return (
    //Starts the route view with <Grid container> and add <Grid item 12> for rows.
    <ArchivoNotificaciones activities={activities}/>  
  );
}

export default Activities;
