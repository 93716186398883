import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import BaseBasicForm from "../../../Layout/containers/BaseBasicForm";
import BasicFormStepByStep from "../../../Components/Generic/basicFormStepByStep";
import useGlobalContext from "../../../Hooks/useGlobalContext";
import leftIcon from '../../../Images/stepbystep/lefticons/bienvenido.png'
import { useTranslation } from 'react-i18next';

function StepByStepRegistroUser() {
  const { t } = useTranslation();
  let history = useHistory();
  const globalContext = useGlobalContext();
  const [fields, setFields] = useState([]);
  const [form, setForm] = useState(null);

  const setModels = () => {
    let models = globalContext.getSessionDataByKey("models");
    setFields(models["userModels"]["basic"].attributes);
    setForm(models["userModels"]["basic"].form);
    return models;
  };

  useEffect(() => {
    setModels();
  }, []);

  const redirectStepByStepCompany = (ids) => {
    let args = {
      ids: ids,
    };

    history.push({
      pathname: "/signin/users/activate",
      state: args,
    });
  };

  return (
    <BaseBasicForm
      text={t("stepByStep.titleLeft.accountRegister")}
      leftIcon={leftIcon} showOriginoImage="true">
      <BasicFormStepByStep
        formMode={"vertical"}
        saveFunc={() => { }}
        show={true}
        showStepTitle={false}
        showSectionTitle={false}
        sectionAccordionMode={false}
        fieldColSize={12}
        onCanceled={() => { }}
        onUpdated={redirectStepByStepCompany}
        withDefault={false}
        mode={"new"}
        pk={null}
        pkObjectType={1}
        pkReferer={null}
        globalModel={0}
        objectType={"users"}
        objectTypeBindForm={"users"}
        parentType={"root"}
        parentTypeSubType={1}
        parentId={0}
        count={0}
        fields={fields}
        form={form}
      />
    </BaseBasicForm>
  );
}

export default StepByStepRegistroUser;
