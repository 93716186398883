import React, { useState } from 'react'
import BaseBasicForm from '../../Layout/containers/BaseBasicForm'
import leftIcon from '../../Images/stepbystep/lefticons/bienvenido.png'
import { useTranslation } from 'react-i18next';
import { Alert, Box, CircularProgress, Snackbar, TextField, Typography } from '@mui/material';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ApiFetch } from '../../Utils/ApiFetch';
import { GlobalContext } from '../../Contexts/globalContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { LOGIN } from "../../constants/paths";
import { LoadingButton } from '@mui/lab';
import LanguageSelect from '../../Components/LanguageSelect';

const ChangePasswordScreen = () => {
  const { t } = useTranslation();
  const params = useParams()
  let history = useHistory();

  const [email, setEmail] = useState('')
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    if (password1 === password2) {
      setIsLoading(true)
      ApiFetch(
        'users/validateMailResetPassword',
        GlobalContext,
        false,
        (res) => {
          setSnackbarOpen(!!res.ok)
          setIsLoading(false)
        },
        (err) => {
          if (err.status === 404) {
            setPasswordError(t("passwordReset.errorMsg.notFound"))
          } else {
            setPasswordError(t("passwordReset.errorMsg.serverError"))
          }
          setIsLoading(false)
        }
        , {
          method: 'POST',
          body: JSON.stringify({
            "email": email,
            "hashEmail": params.hash,
            "newPassword": password1
          })
        })
    } else {
      setPasswordError(t("signUp.errorMsg.differentPasswords"))
    }
  }

  return (
    <BaseBasicForm text={t("passwordReset.title")} leftIcon={leftIcon} showOriginoImage="true">
      <Box position={'absolute'} top={20} right={40} sx={{
        right: { lg: 65, xl: 70 }, 
        display: 'flex', 
        justifyContent: 'flex-end', 
        width: '100%',
      }}>
        <LanguageSelect />
      </Box>
      <Typography variant="subtitle1" component="h4" color="text.secondary" className="mb-4">
        {t("passwordReset.subtitleChangePassword")}
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        autoComplete='off'
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
        <TextField
          id="email"
          label={t("signUp.email")}
          type='email'
          required
          value={email}
          onChange={(val) => { setEmail(val.target.value) }}
          fullWidth
          sx={{ mb: 3 }}
        />
        <TextField
          id="password1"
          label={t("signUp.password1")}
          type='password'
          required
          value={password1}
          onChange={(val) => { setPassword1(val.target.value) }}
          fullWidth
          sx={{ mb: 3 }}
        />
        <TextField
          id="password2"
          label={t("signUp.password2")}
          type="password"
          required
          value={password2}
          onChange={(val) => { setPassword2(val.target.value) }}
          fullWidth
          sx={{ mb: 3 }}
        />
        <LoadingButton
          variant='contained'
          loading={isLoading}
          type='submit'
          loadingIndicator={<CircularProgress color="primary" size={16} />}>
          {t("passwordReset.continue")}
        </LoadingButton>
      </Box>
      <br />
      {passwordError &&
        <Typography color={'red'}>
          {passwordError}
        </Typography>
      }
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => history.push(LOGIN)}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {t("passwordReset.success")}
        </Alert>
      </Snackbar>
    </BaseBasicForm>
  )
}

export default ChangePasswordScreen