import React, { useEffect } from "react";
import { IconButton, Popover, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from 'react-i18next';
import useGlobalContext from '../../../Hooks/useGlobalContext'

function PopoverOptions(props) {

  const { t } = useTranslation()
  const [dropdown, setDropdown] = React.useState(false);
  const dropdownOpen = Boolean(dropdown);
  const [disabledEdit, setDisabledEdit] = React.useState(true);
  const dropdownId = dropdownOpen ? "new-file-popover" : undefined;
  const globalContext = useGlobalContext();
  const { userData } = globalContext;
  const closeDropdown = () => {
    setDropdown(null);
  };
  const showDropdown = (event) => {
    setDropdown(event.currentTarget);
  };

  useEffect(() => {
    if (userData?.userData?.permissions?.companies[props.data.id]?.includes('COMPANY_EDIT')) {
      setDisabledEdit(false);
    } else {
      setDisabledEdit(true);
    }
  }, []);

  return (
    <>
      <IconButton aria-label="settings" onClick={(event) => { showDropdown(event) }}>
        <MoreVertIcon />
      </IconButton>

      <Popover
        id={dropdownId}
        open={dropdown}
        anchorEl={dropdown}
        onClose={closeDropdown}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {/*<MenuItem divider onClick={() => onClickAddSubBox(data.id,data.subtype)}>
            Crear Sub.Establecimiento
        </MenuItem>*/}
        <MenuItem divider disabled={disabledEdit} onClick={() => { props.onClickEditCompany(props.data.id, props.data.subtype) }}>
          {t("component.popoverOption.edit")}
        </MenuItem>
        <MenuItem disabled divider>{t("component.popoverOption.delete")}</MenuItem>
      </Popover>
    </>
  )
}

export default PopoverOptions;
