import useGlobalContext from '../../Hooks/useGlobalContext';
import { LOGIN } from '../../constants/paths';
import { useHistory } from 'react-router-dom';
import { useEffect } from "react";

function Logout() {
  let history = useHistory();
  const { logout } = useGlobalContext();

  useEffect(() => {
    logout();
    history.push(LOGIN);
  }, [history, logout]);

  return null;
}

export default Logout;