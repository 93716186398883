import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography, Card, Avatar } from "@mui/material";
import { QR_IDENTECO,QR_IDENTECO_TRUPO } from "../../constants/paths";
import { useTranslation } from 'react-i18next';
import useGlobalContext  from "../../Hooks/useGlobalContext";
import { Alert } from "@mui/material";

function QrTemplatesList() {
  const { t } = useTranslation();
  let history = useHistory();
  const { mandatoryFieldsErrorStack } = useGlobalContext();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className="d-flex" style={{ display: 'flex' }}>
        <Card className="pt-2 pl-2 pb-2" sx={{ width: 1 }}>
          <div className="d-flex flex-row justify-between pr-2">
            <div className="d-flex flex-row align-start">
              <Avatar aria-label="recipe" className="mr-2 mt-1">
                Id
              </Avatar>
              <span>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="primary"
                  className="mb-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push({ pathname: QR_IDENTECO });
                  }}
                >
                  {t("template.qrIdenteco.title")}
                </Typography>
                <Typography variant="subtitle2" component="p" className="mt-0 mb-2">
                  {t("template.qrIdenteco.subtitle")}
                </Typography>
              </span>
            </div>
            <div>
            </div>
          </div>
        </Card>
      </Grid>

      <Grid item xs={12} className="d-flex" style={{ display: 'flex' }}>
        <Card className="pt-2 pl-2 pb-2" sx={{ width: 1 }}>
          <div className="d-flex flex-row justify-between pr-2">
            <div className="d-flex flex-row align-start">
              <Avatar aria-label="recipe" className="mr-2 mt-1">
                IdTr
              </Avatar>
              <span>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="primary"
                  className="mb-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push({ pathname: QR_IDENTECO_TRUPO });
                  }}
                >
                  {t("template.qrIdentecoTrupo.title")}
                </Typography>

                <Typography variant="subtitle2" component="p" className="mt-0 mb-2">
                  {t("template.qrIdentecoTrupo.subtitle")}
                </Typography>
              </span>
            </div>
            <div>
            </div>
          </div>
        </Card>
      </Grid>
      {/* } */}
      {/* Plantilla para Qr Produkto Trupo. Por ahora se hace desde el evento de faena. En futuro, ver             */}
      {/* <Grid item xs={12} key={1} className="d-flex" style={{ display: 'flex' }}>
          <Card className="pt-2 pl-2 pb-2" sx={{ width: 1 }}>
            <div className="d-flex flex-row justify-between pr-2">
              <div className="d-flex flex-row align-start">
                <Avatar aria-label="recipe" className="mr-2 mt-1">
                  PT
                </Avatar>
                <span>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    color="primary"
                    className="mb-2"
                    style={{cursor:"pointer"}}
                    onClick={() => {

                        history.push({
                            pathname: QR_PRODUKTO_TRUPO_SELECT,
                            state: { refererContext: null }, //TODO args
                          });

                    }}
                    >
                    {t("template.qrTrupo.title")}
                  </Typography>

                  <Typography variant="subtitle2" component="p" className="mt-0 mb-2">
                    {t("template.qrTrupo.subtitle")}
                  </Typography>
                </span>
              </div>
              <div>
              </div>
            </div>
          </Card>
        </Grid> */}
    </Grid>
  );
}

export default QrTemplatesList;
