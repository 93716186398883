import React, { useEffect, useState } from "react";
import useGlobalContext from "../../Hooks/useGlobalContext";
import { Grid, Typography, Card, CardHeader, CardContent, Chip } from "@mui/material";
import { useTranslation } from 'react-i18next'

function EventsAll(props) {
  const { t } = useTranslation();
  const { selectedAsset, selectedBox, relationUserEntity, getSessionDataByKey } = useGlobalContext();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (selectedAsset.id !== -1) {
      let models = getSessionDataByKey('models');
      let model = selectedAsset.id;
      const events = [...models[model].events.map((e) =>
        !e.isTransfer &&
        e.permissions.box.some(perm => perm == selectedBox.subtype) &&
        (e.permissionsRelation === undefined ||
        e.permissionsRelation?.some(perm => perm.box === selectedBox.subtype && perm.relation === relationUserEntity))
        ? e : null
      )]

      setEvents(events)
    }
  }, [getSessionDataByKey, selectedAsset, selectedBox, relationUserEntity]);

  return (
    <Grid container spacing={2}>
      {events?.length
        ? events.map((data, index) => (
          (data !== null && data?.assetMode !== false) &&
          <Grid item key={data.name} >
            <Card sx={{ width: 300, height: 150 }}>
              <CardHeader
                title={
                  <Chip
                    onClick={() => props.onSelect(data)}
                    sx={{ cursor: 'pointer' }}
                    color="primary"
                    label={t("stepByStep.massive.step1.typeEvent." + selectedAsset.id + "." + index + ".name")}
                  />
                }
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {t("stepByStep.massive.step1.typeEvent." + selectedAsset.id + "." + index + ".description")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))
        : <Grid item>
          {t("stepByStep.massive.step1.noEvent")}
        </Grid>
      }
    </Grid>
  );
}

export default EventsAll;
