import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  Menu,
  Divider,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinusCircle,
  faEllipsisV,
  faQrcode
} from '@fortawesome/pro-light-svg-icons';
import './styles.css';

import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

import { Redirect, useHistory, useLocation } from 'react-router-dom';
import LargeGridData from '../../../Components/Generic/LargeGridData';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import { ApiFetch } from '../../../Utils/ApiFetch';
import Modal from '@material-ui/core/Modal';
import { ASSETS_EDIT, ASSETS_MASSIVE_EDIT, ASSETS, IMAGES_PATH } from '../../../constants/paths';
import { config } from '../../../Config/index';

export default function DataTable(props) {
  const { t } = useTranslation()
  let history = useHistory();
  const location = useLocation();
  const globalContext = useGlobalContext();
  const { userData, selectedAsset, selectedBox } = useGlobalContext();
  const [parentType] = useState('box');
  const [showNewAssetModal] = useState(false);
  const [menuActionSelectedRow, setMenuActionSelectedRow] = useState(null);
  const [menuQRSelectedRow, setMenuQRSelectedRow] = useState(null);

  const [rows] = useState([]);
  const [fields, setFields] = useState([]);
  const [events, setEvents] = useState([]);
  const [isShow] = useState(true);
  const [isRedirectToAssets] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElQR, setAnchorElQR] = React.useState(null);
  const open = Boolean(anchorEl);
  const openQR = Boolean(anchorElQR);

  const [exportConfig, setExportConfig] = useState({
    'mode': false,
    'caption': '',
    'exportMode': 'editAsset',
    'redirectPath': '',
    'redirectArgs': ''
  });

  useEffect(() => {
    //  getRows();
  }, [events, fields, selectedBox]);

  useEffect(() => {

    setExportConfig({
      'mode': false,
      'exportMode': 'editAsset',
      'caption': '',
      'redirectPath': '',
      'redirectArgs': ''
    })

    if (location.state?.mode === 'filterTransfer') {
      setExportConfig({
        'mode': true,
        'exportMode': location.state.mode,
        'caption': 'Transferir',
        'redirectPath': ASSETS_MASSIVE_EDIT,
        'redirectArgs': location.state.event
      })
    }

    if (location.state?.mode === 'filterEvent') {
      if (location?.state?.event?.fields?.length > 0) {
        setExportConfig({
          'mode': true,
          'exportMode': location.state.mode,
          'caption': 'Continuar',
          'redirectPath': ASSETS_MASSIVE_EDIT,
          'redirectArgs': location.state.event
        })
      } else
        if (location?.state?.event?.isMassive) {
          setExportConfig({
            'mode': true,
            'exportMode': location.state.mode,
            'caption': 'Continuar',
            'redirectPath': ASSETS_MASSIVE_EDIT,
            'redirectArgs': location.state.event
          })
        }
    }

  }, [location?.pathname]);

  useEffect(() => {
    if (selectedAsset.id !== -1) {
      getModels();
    }
    //  getRows();
  }, [selectedAsset.id]);

  const rowMenuActionshandleClick = (event, row) => {
    setMenuActionSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };
  const rowMenuActionshandleClose = () => {
    setAnchorEl(null);
  };

  const rowMenuQRhandleClick = (event, row) => {
    setMenuQRSelectedRow(row);
    setAnchorElQR(event.currentTarget);
  };
  const rowMenuQRhandleClose = () => {
    setAnchorElQR(null);
  };

  const moreActionsButton = (row) => {

    let txPaused = false;
    if (row.row['paused'] != null) {
      if (row.row['paused']) {
        txPaused = true;
      }
    }
    if (txPaused || !(userData?.userData?.permissions?.boxes[selectedBox.id + "-" + selectedAsset.id]?.includes("ASSET_EDIT") === true)) {
      //return <Button style={{padding:"0px", margin:"0px"}} size="small" color="secondary" variant="outlined">PAUSED</Button>
      return "";
    }

    return (
      <>
        <span
          id="basic-button"
          aria-controls="basic-menu"
          style={{ minWidth: "20px", textAlign: "center", cursor: "pointer" }}
          aria-label="More"
          onClick={(event) => rowMenuActionshandleClick(event, row)}>
          <FontAwesomeIcon style={{ fontSize: '25px' }} icon={faEllipsisV} />
        </span>
      </>
    );
  };

  const qrActionsButton = (row) => {
    return (
      <span
        style={{
          cursor: "pointer",
        }}
        onClick={(event) => rowMenuQRhandleClick(event, row)}
      >
        <FontAwesomeIcon icon={faQrcode} style={{ fontSize: '20px' }} />
      </span>
    )
  }

  const getModels = () => {
    try {
      if (selectedAsset.id !== -1) {
        let models = globalContext.getSessionDataByKey('models');
        if (models != null) {
          let model = selectedAsset.id;
          if (models[model]) {
            setEvents(models[model].events);
            setFields(models[model].attributes);
            return models;
          }
        }
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  const updateMenuActionSelectedRow = () => {
    let args = {
      pk: menuActionSelectedRow.id,
      mode: 'update',
      objectTypeSubType: selectedAsset.id,
      globalModel: selectedAsset.id,
      parentType: parentType,
      parentTypeSubType: selectedBox.subtype,
      parentId: selectedBox.id,
    };

    history.push({
      pathname: ASSETS_EDIT,
      state: { refererContext: args },
    });
  };

  const updateMenuQRSelectedRow = () => {
    window.open(menuQRSelectedRow?.row?.qr_link !== null ? menuQRSelectedRow?.row?.qr_link : process.env.REACT_APP_URL + "qrIdenteco/" + menuQRSelectedRow.id, '_blank')?.focus();
  };
  const updateMenuQRCopyRow = () => {
    navigator.clipboard.writeText(menuQRSelectedRow?.row?.qr_link !== null ? menuQRSelectedRow?.row?.qr_link : process.env.REACT_APP_URL + "qrIdenteco/" + menuQRSelectedRow.id);
  }

  const handleChangeRowCount = (count) => {
    props.onRowCountChange(count);
  };

  const exportAction = (arg) => {
    if (arg?.exportFileDataConfig?.exportMode === 'filterEvent') {
      history.push({
        pathname: arg?.exportFileDataConfig?.redirectPath,
        state: {
          rows: arg.rows,
          args: arg?.exportFileDataConfig?.redirectArgs
        },
      });
    }

    if (arg?.exportFileDataConfig?.exportMode === 'filterTransfer') {
      let dataObj = {
        boxSource: selectedBox.id,
        boxTarget: arg.exportFileDataConfig.redirectArgs.boxTarget,
        rows: arg.rows,
      }
      ApiFetch(
        'objects/add/assets/transfer/massive',
        globalContext,
        true,
        async (res) => {
          if (res.status === 200) {
            history.push({
              pathname: ASSETS,
              state: {},
            });
            window.location.reload();

          }
        },
        (error) => {
          console.log('error', error);
        },
        {
          method: 'POST',
          body: JSON.stringify(dataObj),
        }
      );

    }
  }

  const downloadQR = () => {
    let canvas = document.getElementById('generated_qr_png');
    let pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = menuQRSelectedRow?.id + `.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const downloadQRSVG = () => {
    const svg = document.getElementById('generated_qr_svg_xl');

    const serializer = new XMLSerializer();
    let source = serializer.serializeToString(svg);
    if (!source.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
      source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
    }
    if (!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
      source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
    }
    source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

    const svgUrl = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);

    const downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = menuQRSelectedRow?.id + `.svg`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };



  return (
    <>
      {isShow &&
        <div>
          {isRedirectToAssets &&
            <Redirect to={ASSETS} />
          }
          <LargeGridData
            multiSelectMode={false}
            onMultipleSelected={(evt) => { }}
            parentType={parentType}
            parentTypeSubType={selectedBox.subtype}
            //actions={moreActionsButton}
            qrMenu={qrActionsButton}
            show={true}
            onSelected={() => { }}
            rows={rows}
            fields={fields}
            onChangeRowCount={handleChangeRowCount}
            sessionContext={"assets"}
            exportFilterDataConfig={exportConfig}
            exportFilterAction={exportAction}
            entity={'asset'}
            url={`objects/get/all/${selectedAsset.id}/asset/${selectedAsset.id}/${selectedBox.id}/0/0`}
            isLogicalDeletedPage={true}
          />

          <Menu
            sx={{
              boxShadow: 1
            }}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={rowMenuActionshandleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            <MenuItem
              onClick={() => {
                updateMenuActionSelectedRow();
                rowMenuActionshandleClose();
              }}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faMinusCircle} />
              </ListItemIcon>
              <ListItemText>{t("component.popoverOption.edit")}</ListItemText>
            </MenuItem>
            <Divider sx={{ mt: '0!important', mb: '0!important' }} />
            {events.map(
              (event) =>
                event.permissions.box.includes(parseInt(selectedBox.subtype)) === true && false && (
                  <div>
                    <MenuItem
                      onClick={() => {
                        rowMenuActionshandleClose();
                      }}>
                      <ListItemIcon>
                        <FontAwesomeIcon icon={faMinusCircle} />
                      </ListItemIcon>
                      <ListItemText>
                        {t("component.popoverOption.event")}<b>{event.name}</b>
                      </ListItemText>
                    </MenuItem>
                    <Divider sx={{ mt: '0!important', mb: '0!important' }} />
                  </div>
                )
            )}
          </Menu>

          <Menu
            sx={{
              boxShadow: 1
            }}
            id="basic-menu"
            anchorEl={anchorElQR}
            open={openQR}
            onClose={rowMenuQRhandleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            <MenuItem
              onClick={() => {
                updateMenuQRSelectedRow();
                rowMenuQRhandleClose();
              }}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faMinusCircle} />
              </ListItemIcon>
              <ListItemText>Abrir</ListItemText>
            </MenuItem>

            <Divider sx={{ mt: '0!important', mb: '0!important' }} />

            <MenuItem
              onClick={() => {
                updateMenuQRCopyRow();
                rowMenuQRhandleClose();
              }}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faMinusCircle} />
              </ListItemIcon>
              <ListItemText>Copiar</ListItemText>
            </MenuItem>

            <Divider sx={{ mt: '0!important', mb: '0!important' }} />

            <MenuItem
              sx={{
                display: "flex",
                justifyContent: "center",
                ':hover': {
                  bgcolor: 'white',
                  color: 'white',
                },
              }}
            >
              <QRCode
                size={1000}
                style={{ maxWidth: "120px", maxHeight: "120px" }}
                value={menuQRSelectedRow?.row?.qr_link !== null ? menuQRSelectedRow?.row?.qr_link : process.env.REACT_APP_URL + "qrIdenteco/" + menuQRSelectedRow.id}
                renderAs="canvas"
                bgColor="#ffffffff"
                includeMargin id={"generated_qr_png"}
                imageSettings={{ src: `${IMAGES_PATH}/origino_qr.png`, excavate: true, width: "225", height: "225" }}
                level={'H'}
              />
            </MenuItem>

            <MenuItem
              onClick={() => {
                downloadQR();
                rowMenuQRhandleClose();
              }}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faMinusCircle} />
              </ListItemIcon>
              <ListItemText>
                Descargar QR PNG (standard)
              </ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                downloadQRSVG();
                rowMenuQRhandleClose();
              }}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faMinusCircle} />
              </ListItemIcon>
              <ListItemText>
                <div style={{ display: "none" }}>
                  <QRCode
                    size={1000}
                    value={menuQRSelectedRow?.row?.qr_link !== null ? menuQRSelectedRow?.row?.qr_link : process.env.REACT_APP_URL + "qrIdenteco/" + menuQRSelectedRow.id}
                    renderAs='svg'
                    bgColor="#ffffffff"
                    includeMargin id={"generated_qr_svg_xl"}
                    imageSettings={{
                      src: `${config.reactAppUrl}images/origino_qr.png`,
                      excavate: true,
                      width: "225",
                      height: "225"
                    }}
                    level={'H'}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <span>Descargar QR SVG (avanzado)</span>
                </div>
              </ListItemText>
            </MenuItem>

          </Menu>

          <Modal
            open={showNewAssetModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
          </Modal>


        </div>
      }
    </>
  );
}
