import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FileUploader from '../../FileUploader/FileUploader';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-light-svg-icons';

const VALID_FORMATS = [
  '.jpg',
  '.jpeg',
  'JPG',
  '.png',
  '.gif',
  '.csv',
  '.pdf',
  '.docx',
  '.xlsx',
  '.doc',
  '.xls',
  '.ppt',
  '.pptx',
  '.txt',
];


function FileField({ label, name, onChange, value, validFormats, field }) {
  const repository = field?.downloadMockup && require(`../../../Repository/${field.downloadMockup}.xlsx`)
  const { t } = useTranslation();

  const [textProp1, setTextProp1] = useState('');
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const getUploadProgress = (progressInfo) => {
    setUploadInProgress(progressInfo?.status.loading);
    if (uploadInProgress) {
      setTextProp1('');
    }
  };

  const handleOnChange = async (statusInfo) => {
    if (statusInfo.reset) {
      setTextProp1('');
    }

    if (statusInfo.success || statusInfo.reset) {
      let currentVal = JSON.stringify(statusInfo?.uploadedFileInfo[0]) ?? '';
      if (currentVal !== value) {
        // fix
        if (statusInfo.reset) {
          currentVal = '';
        }
        onChange({ name: statusInfo.name, statusInfo: currentVal });
      }
    }
  };

  const handleRestoredFile = () => {
    if (value && value !== 'Cargar Archivo') return JSON.parse(value);
    return null;
  };

  return (
    <FormGroup>
      <p style={{ fontSize: '0.75rem', marginBottom: '0px', marginLeft: '5px' }}>
        {label}
      </p>
      <FileUploader
        cancelText={t('component.uploader.cancel')}
        clearText={t('component.uploader.clear')}
        defaultText={t('component.uploader.search')}
        dragDrop={true}
        getUploadProgress={getUploadProgress}
        isPrivate={false}
        maxSize={10}
        name={name}
        onChange={handleOnChange}
        restoredFile={handleRestoredFile()}
        uploadedFileText={textProp1}
        url={process.env.REACT_APP_UPLOADER_HOST + 'file/upload'}
        validFormats={validFormats ?? VALID_FORMATS}
      />
      {field?.downloadMockup &&
        <div style={{display: 'flex', flexDirection: "row", justifyContent: 'flex-end', alignItems:'center'}}>
          <Typography variant="body2" component="h3" color="text.primary" style={{ fontSize: '0.75rem'}}>
            {t('stepByStep.massive.step1.downloadFile')}
          </Typography>
          <Button
            variant='outlined'
            sx={{height: 25, width: 120, fontSize: "0.75rem", marginLeft:1}}
            href={repository.default}
            startIcon={ <FontAwesomeIcon icon={faDownload} style={{fontSize: "1.25em"}} />}>
            {t('stepByStep.massive.button.download')}
          </Button>
        </div>
      }
    </FormGroup>
  );
}

export default FileField;
