import React, { useState, useEffect } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { Cell, Legend, Pie, PieChart } from 'recharts';
import { StyleBoxLegend } from './CardDetailedInfo.styles';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import { useTranslation } from 'react-i18next';

export const CardDetailedInfo = (props) => {

  const globalContext = useGlobalContext();
  const [dataRace, setDataRace] = useState([]);
  const { selectedAsset } = useGlobalContext(); //
  const { t } = useTranslation();

  const COLORS = ['#76CC81', '#FFF76A', '#FFA589', '#DAFFD7'];
  // Activar valores dentro de la dona

  const setModels = () => {

    if (selectedAsset.id !== -1 && props.dataList.length > 0) {

      let models = globalContext.getSessionDataByKey('models');
      let model = selectedAsset.id;
      let chartsData = [];
      for (let attr of models[model].attributes) {
        if (attr.summaryRules) {
          if (attr.summaryRules.includes('PORC')) {
            let keys = {};
            for (let row of props.dataList) {
              if (row[attr.name]) {
                keys[row[attr.name].toString().toUpperCase()] = 0;
              }
            }
            for (let row of props.dataList) {
              if (row[attr.name]) {
                keys[row[attr.name].toString().toUpperCase()]++;
              }
            }
            let races = [];
            for (let entry of Object.entries(keys)) {
              if (entry[0] === 'null' || entry[0] === 'undefined') {
                entry[0] = 'No Informa';
              }
              if (entry[0] === 'FALSE' || entry[0] === 'TRUE') {
                entry[0] = entry[0] === 'FALSE' ? t('stepByStep.lot.cardDetailedInfo.yes') : t('stepByStep.lot.cardDetailedInfo.no')
              }
              let race = { 'race': entry[0], 'amount': entry[1] }
              if (entry[0] !== '' && entry[0] !== 'null') {
                races.push(race);
              }
            }

            chartsData.push({ data: races, name: attr.label });
          }
        }
      }
      setDataRace(chartsData);

    }
  }

  useEffect(() => {
    setModels();
  }, [props.dataList, selectedAsset]);


  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <Grid className="d-flex" style={{overflowX:'auto'}}>
        {payload.map((entry, index) => (
          <Grid key={index} container>
            <Grid item xs={12} className="d-flex ml-2">
              <Grid item className="align-self-center">
                <StyleBoxLegend color={COLORS[index % COLORS.length]} />
              </Grid>
              <Grid item className="ml-1">
                <Typography variant="buttom" className="font-weight-medium" noWrap>
                  {' '}
                  {entry.payload.amount}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography className="ml-2" variant="caption" noWrap>
                {entry.payload.race}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };


  return (
    <Grid container>
      {dataRace.map((entry) => (
        entry.data.length > 0 &&
        <Card className="ml-1" style={{ marginBottom: "10px"}}>
          <Grid item xs={12} className="d-flex flex-column pt-2 pl-2 pr-2">
            <Grid item className="align-self-start">
              <Typography noWrap>{entry.name}</Typography>
            </Grid>
            <Grid item>
              <PieChart width={300} height={250}>
                <Pie
                  data={entry.data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  dataKey="amount">
                  {entry.data.map((en, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Legend content={renderLegend} align="center" />
              </PieChart>
            </Grid>
          </Grid>
        </Card>
      ))}
    </Grid>
  );
};