class AssetGenealogy {
  genealogy_relationship = '';
  genealogy_idcaravana = '';
  genealogy_hba = '';
  genealogy_rp = '';
  genealogy_cc = '';
  genealogy_name = '';
  genealogy_cor = '';
  genealogy_cor_file = '';
  genealogy_adn_file = '';
  genealogy_dep_file = '';

  constructor({
    HBA,
    RP,
    Criador,
    Nombre,
    NombrePadre,
    HBAPadre,
    NombreMadre,
    HBAMadre,
    NombreAbueloPaterno,
    HBAAbueloPaterno,
    NombreAbuelaPaterna,
    HBAAbuelaPaterna,
    NombreAbueloMaterno,
    HBAAbueloMaterno,
    NombreAbuelaMaterna,
    HBAAbuelaMaterna,
    type,
  }) {
    if (type === 'currentAsset') {
      this.genealogy_hba = HBA.toString();
      this.genealogy_rp = RP;
      this.genealogy_relationship = 'Datos Propios';
      this.genealogy_cc = Criador;
      this.genealogy_name = Nombre;
    } else {
      switch (type) {
        case 'Padre':
          this.genealogy_hba = HBAPadre;
          this.genealogy_name = NombrePadre;
          this.genealogy_relationship = 'Padre';
          break;
        case 'Madre':
          this.genealogy_hba = HBAMadre;
          this.genealogy_name = NombreMadre;
          this.genealogy_relationship = 'Madre';
          break;
        case 'AbueloPaterno':
          this.genealogy_hba = HBAAbueloPaterno;
          this.genealogy_name = NombreAbueloPaterno;
          this.genealogy_relationship = 'Abuelo Paterno';
          break;
        case 'AbuelaPaterna':
          this.genealogy_hba = HBAAbuelaPaterna;
          this.genealogy_name = NombreAbuelaPaterna;
          this.genealogy_relationship = 'Abuela Paterna';
          break;
        case 'AbueloMaterno':
          this.genealogy_hba = HBAAbueloMaterno;
          this.genealogy_name = NombreAbueloMaterno;
          this.genealogy_relationship = 'Abuelo Materno';
          break;
        case 'AbuelaMaterna':
          this.genealogy_hba = HBAAbuelaMaterna;
          this.genealogy_name = NombreAbuelaMaterna;
          this.genealogy_relationship = 'Abuela Materna';
          break;
        default:
          break;
      }
    }
  }

  getJsonObject() {
    return {
      genealogy_relationship: this.genealogy_relationship,
      genealogy_idcaravana: this.genealogy_idcaravana,
      genealogy_hba: this.genealogy_hba,
      genealogy_rp: this.genealogy_rp,
      genealogy_cc: this.genealogy_cc,
      genealogy_name: this.genealogy_name,
      genealogy_cor: this.genealogy_cor,
      genealogy_cor_file: this.genealogy_cor_file,
      genealogy_adn_file: this.genealogy_adn_file,
      genealogy_dep_file: this.genealogy_dep_file,
    };
  }
}

export class Genealogy {
  currentValue;
  finalArray = [];

  constructor(currentValue) {
    this.currentValue = currentValue;
  }

  getAssetEntries(currentValue, endStringArray, matching) {
    const joinedStrings = endStringArray.join('|');
    const regex = new RegExp(`(${joinedStrings})$`);
    return Object.entries(currentValue)
      .map(([key, value]) => (regex.test(key) === matching ? [key, value] : []))
      .filter(([key]) => key !== undefined);
  }

  setFinalArray(currentObj, type) {
    if (currentObj.length > 0) {
      const current = new AssetGenealogy({
        ...Object.fromEntries(currentObj),
        type,
      }).getJsonObject();
      this.finalArray.push(current);
    }
  }

  getDetailGenealogyArray() {
    const currentAsset = this.getAssetEntries(
      this.currentValue,
      [
        'Madre',
        'Padre',
        'AbueloPaterno',
        'AbuelaPaterna',
        'AbueloMaterno',
        'AbuelaMaterna',
      ],
      false
    );
    const father = this.getAssetEntries(this.currentValue, ['Padre'], true);
    const mother = this.getAssetEntries(this.currentValue, ['Madre'], true);
    const abueloPaterno = this.getAssetEntries(
      this.currentValue,
      ['AbueloPaterno'],
      true
    );
    const abuelaPaterna = this.getAssetEntries(
      this.currentValue,
      ['AbuelaPaterna'],
      true
    );
    const abueloMaterno = this.getAssetEntries(
      this.currentValue,
      ['AbueloMaterno'],
      true
    );
    const abuelaMaterna = this.getAssetEntries(
      this.currentValue,
      ['AbuelaMaterna'],
      true
    );

    this.setFinalArray(currentAsset, 'currentAsset');
    this.setFinalArray(father, 'Padre');
    this.setFinalArray(mother, 'Madre');
    this.setFinalArray(abueloPaterno, 'AbueloPaterno');
    this.setFinalArray(abuelaPaterna, 'AbuelaPaterna');
    this.setFinalArray(abueloMaterno, 'AbueloMaterno');
    this.setFinalArray(abuelaMaterna, 'AbuelaMaterna');

    return this.finalArray;
  }
}
