import { useEffect, useState } from 'react';
import { CkeckStepIcon, InfoStepIcon, RejectedtepIcon, stringAvatar, StyledBadge, StyledBoldTypography, StyledConnector, StyleStepContent, TrasferStepIcon, } from './ArchivoNotificaciones.styles';
import { Avatar, Box, Typography, StepLabel, Step, Stepper, } from '@mui/material';
import NotificationActivo from './NotificationActivo';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { Paper } from '@mui/material';
import { Divider } from '@mui/material';
import ActivitiesFilter from '../Generic/ActivitiesFilter';
import { dateSanitizer } from '../../Helpers/dateFormater';
import { useTranslation, Trans } from 'react-i18next';

function ArchivoNotificaciones(props) {
  const globalContext = useGlobalContext();
  const { t } = useTranslation();
  const { selectedBox } = globalContext;
  const [initialActivities, setInitialActivities] = useState(props.activities)  
  const [activities, setActivities] = useState(props.activities)
  const [events, setEvents] = useState([])

  useEffect( ()=>{
    setActivities(props.activities)
    setInitialActivities(props.activities)
    setEvents( globalContext.sessionData.models[globalContext.selectedAsset.id]?.events )
  },[props,globalContext] )

  //For notification Icon
  const stepIcon = (type) => {

    switch (type) {
      case 'transfer':
        return TrasferStepIcon;
      case 'info':
        return InfoStepIcon;
      case 'check':
        return CkeckStepIcon;
      case 'add':
        return CkeckStepIcon;
      case 'update':
        return CkeckStepIcon;
      case 'rejected':
        return RejectedtepIcon;
      case 'newExcel':
        return CkeckStepIcon;
      default:
        break;
    }
  };

  const eventLabelPrinter = (notificacion) => {

    if(notificacion.event_context.eventType == '-1' && notificacion.event_context.context === 'manual_form'){
      return t("activities.labels.loadManual")
    }else if(notificacion.event_context.eventType == '-1' && notificacion.event_context.context === 'massive_manual_form'){
      return t("activities.labels.loadMassive")
    }else if(notificacion.event_context.eventType == '-1' && notificacion.event_context.context === 'file_form'){
      return t("activities.labels.loadByFile")
    }else if(notificacion.event_context.eventType == '-1' && notificacion.event_context.context === 'massive_file_form'){
      return t("activities.labels.loadByFileMassive")
    }else{
      return globalContext.sessionData.models[notificacion.id_asset_type]?.events.find( a => a.eventType == notificacion.event_context.eventType)?.name
    }

  }

  //For notification label
  const label = (notificacion) => {
    
    if (notificacion.type==='add') {
      return (
          <Typography className='flex-row d-flex pt-1'>
            {notificacion.nro > 1 ?
              <Trans 
                i18nKey="activities.labels.addManualPlural"
                values={{event:eventLabelPrinter(notificacion), nro:notificacion.nro, boxname:notificacion.boxname }}
                components={{ bold: <b className='mx-1'/>, font: <font color='primary'/>  }}
              />
              :
              <Trans 
                i18nKey="activities.labels.addManualSingular"
                values={{event:eventLabelPrinter(notificacion), nro:notificacion.nro, boxname:notificacion.boxname }}
                components={{ bold: <b className='mx-1' />, font: <font color='primary'/> }}
              />
            }

          </Typography>
      );
  };

  if (notificacion.type==='update') {
    return (
          <Typography className='flex-row d-flex pt-1'>
            {notificacion.nro > 1 ?
              <Trans 
                i18nKey="activities.labels.updateManualPlural"
                values={{event:eventLabelPrinter(notificacion), nro:notificacion.nro, boxname:notificacion.boxname }}
                components={{ bold: <b className='mx-1'/>, font: <font color='primary'/>  }}
              />
              :
              <Trans 
                i18nKey="activities.labels.updateManualSingular"
                values={{event:eventLabelPrinter(notificacion), nro:notificacion.nro, boxname:notificacion.boxname }}
                components={{ bold: <b className='mx-1' />, font: <font color='primary'/> }}
              />
            }
          </Typography>
      );
  };

  if (notificacion.type==='transfer') {
    return notificacion.event_holder === selectedBox.id ? (
      <Typography className='flex-row d-flex pt-1'>
        {notificacion.nro > 1 ?
          <Trans 
            i18nKey="activities.labels.transferedHolderPlural"
            values={{event:eventLabelPrinter(notificacion), nro:notificacion.nro, boxname:notificacion.boxname, boxnameTo:notificacion.boxtarget }}
            components={{ bold: <b className='mx-1'/>, font: <font color='primary'/>  }}
          />
          :
          <Trans 
            i18nKey="activities.labels.transferedHolderSingular"
            values={{event:eventLabelPrinter(notificacion), nro:notificacion.nro, boxname:notificacion.boxname, boxnameTo:notificacion.boxtarget }}
            components={{ bold: <b className='mx-1' />, font: <font color='primary'/> }}
          />
        }
      </Typography>
    ) : (
      <Typography className='flex-row d-flex pt-1'>
        {notificacion.nro > 1 ?
          <Trans
            i18nKey="activities.labels.transferedPlural"
            values={{event:eventLabelPrinter(notificacion), nro:notificacion.nro, boxname:notificacion.boxname }}
            components={{ bold: <b className='mx-1'/>, font: <font color='primary'/>  }}
          />
          :
          <Trans 
            i18nKey="activities.labels.transferedSingular"
            values={{event:eventLabelPrinter(notificacion), nro:notificacion.nro, boxname:notificacion.boxname }}
            components={{ bold: <b className='mx-1' />, font: <font color='primary'/> }}
          />
        }
      </Typography>
    )
  };
}

 return (
  <Box>

    <ActivitiesFilter 
      activities={initialActivities}
      setActivities={setActivities}
      events={events}
    />

    <Divider className='m-2'/>

    <Paper elevation='3' className='p-2'>

   {(activities?.length===0 || activities==null) &&
      <><b>{t("activities.labels.notListed")}</b></>
    }
   <Stepper
    nonLinear
    orientation='vertical'
    connector={<StyledConnector />}
    activeStep={1}
   >

    <StyleStepContent />

    {activities.map((notificacion) => (
     <Step>
      <StepLabel
       StepIconComponent={stepIcon(notificacion.type)}
       optional={
        <Box className='pl-1'>
         {true && (
          <Typography
           className='d-flex flex-row mt-1'
           variant='body1'
           component='p'
           color='text.primary'
          >
           {notificacion.username &&
           <>
           <Avatar
            {...stringAvatar(notificacion.username)}
            className='mr-1'
            color='green'
           />
           {' '}
           {notificacion.username} &middot;
           </>
            }
          </Typography>
         )}
         <Typography variant='body1' component='p' color='text.primary'>
          { dateSanitizer(notificacion.timsta)}
         </Typography>
        </Box>
       }
      >
       <Box className='d-flex pt-3 pl-1'>
        {label(notificacion)}
        {notificacion.new === true ? <StyledBadge badgeContent={1} /> : null}
       </Box>
      </StepLabel>
      <StyleStepContent>
       {notificacion.type === 'newTransfer' ? (
        <NotificationActivo
         activo={notificacion.transferencia.activo}
         box={notificacion.transferencia.box}
         partido={notificacion.transferencia.partido}
         email={notificacion.transferencia.email}
         telefono={notificacion.transferencia.telefono}
        />
       ) : null}
      </StyleStepContent>
     </Step>
    ))}
   </Stepper>
   </Paper>

  </Box>
 );
}

export default ArchivoNotificaciones;
