import * as locale from 'date-fns/locale';
import 'moment/min/moment-with-locales';

const LOCALE_MAP = {
  af_ZA: locale.af,
  ar_AE: locale.ar,
  ar_BH: locale.ar,
  ar_DZ: locale.arDZ,
  ar_EG: locale.arEG,
  ar_IQ: locale.ar,
  ar_JO: locale.ar,
  ar_KW: locale.ar,
  ar_LB: locale.ar,
  ar_LY: locale.ar,
  ar_MA: locale.arMA,
  ar_OM: locale.ar,
  ar_QA: locale.ar,
  ar_SA: locale.ar,
  ar_SD: locale.ar,
  ar_SY: locale.ar,
  ar_TN: locale.ar,
  ar_YE: locale.ar,
  ar: locale.ar,
  az_Cyrl_AZ: locale.az,
  be_BY: locale.be,
  bg_BG: locale.bg,
  bn_BD: locale.bn,
  bs_BA: locale.bs,
  ca: locale.ca,
  cgg_UG: locale.ug,
  cs_CZ: locale.cs,
  da_DK: locale.da,
  de_AT: locale.deAT,
  de_DE: locale.de,
  de_LI: locale.de,
  el_CY: locale.el,
  el_GR: locale.el,
  en_AS: locale.enUS,
  en_AU: locale.enAU,
  en_BW: locale.enUS,
  en_BZ: locale.enUS,
  en_CA: locale.enCA,
  en_GB: locale.enGB,
  en_GU: locale.enUS,
  en_HK: locale.enUS,
  en_IE: locale.enIE,
  en_JM: locale.enUS,
  en_MH: locale.enUS,
  en_MP: locale.enUS,
  en_MT: locale.enUS,
  en_MU: locale.enUS,
  en_NA: locale.enUS,
  en_NZ: locale.enNZ,
  en_PH: locale.enUS,
  en_PK: locale.enUS,
  en_SG: locale.enUS,
  en_TT: locale.enUS,
  en_UM: locale.enUS,
  en_US: locale.enUS,
  en_VI: locale.enUS,
  en_ZW: locale.enNZ,
  en: locale.enUS,
  es_AR: locale.es,
  es_BO: locale.es,
  es_CL: locale.es,
  es_CO: locale.es,
  es_CR: locale.es,
  es_DO: locale.es,
  es_EC: locale.es,
  es_ES: locale.es,
  es_GT: locale.es,
  es_HN: locale.es,
  es_MX: locale.es,
  es_NI: locale.es,
  es_PA: locale.es,
  es_PE: locale.es,
  es_PR: locale.es,
  es_PY: locale.es,
  es_SV: locale.es,
  es_UY: locale.es,
  es_VE: locale.es,
  es: locale.es,
  et_EE: locale.et,
  fa_IR: locale.faIR,
  fi_FI: locale.fi,
  fr_BF: locale.fr,
  fr_BI: locale.fr,
  fr_BJ: locale.fr,
  fr_BL: locale.fr,
  fr_CD: locale.fr,
  fr_CF: locale.fr,
  fr_CG: locale.fr,
  fr_CH: locale.frCH,
  fr_CI: locale.fr,
  fr_CM: locale.fr,
  fr_DJ: locale.fr,
  fr_FR: locale.fr,
  fr_GA: locale.fr,
  fr_GN: locale.fr,
  fr_GP: locale.fr,
  fr_GQ: locale.fr,
  fr_KM: locale.fr,
  fr_LU: locale.fr,
  fr_MC: locale.fr,
  fr_MF: locale.fr,
  fr_MG: locale.fr,
  fr_ML: locale.fr,
  fr_MQ: locale.fr,
  fr_NE: locale.fr,
  fr_RE: locale.fr,
  fr_RW: locale.fr,
  fr_SN: locale.fr,
  fr_TD: locale.fr,
  fr_TG: locale.fr,
  fr: locale.fr,
  he_IL: locale.he,
  hi_IN: locale.hi,
  hr_HR: locale.hr,
  hu_HU: locale.hu,
  hy_AM: locale.hy,
  id_ID: locale.id,
  is_IS: locale.is,
  it_IT: locale.it,
  it: locale.it,
  ja_JP: locale.ja,
  ka_GE: locale.ka,
  kk_Cyrl_KZ: locale.kk,
  km_KH: locale.km,
  ko_KR: locale.ko,
  ko: locale.ko,
  lt_LT: locale.lt,
  lv_LV: locale.lt,
  mk_MK: locale.mk,
  mn: locale.mn,
  ms_BN: locale.ms,
  ms_MY: locale.ms,
  nb_NO: locale.nb,
  nl_BE: locale.nlBE,
  nl_NL: locale.nl,
  nl: locale.nl,
  pl_PL: locale.pl,
  pt_BR: locale.ptBR,
  pt_GW: locale.pt,
  pt_MZ: locale.pt,
  pt_PT: locale.pt,
  pt: locale.pt,
  ro_MD: locale.ro,
  ro_RO: locale.ro,
  ru_RU: locale.ru,
  sk_SK: locale.sk,
  sl_SI: locale.sl,
  sq_AL: locale.sq,
  sr_Cyrl_ME: locale.sr,
  sr_Cyrl_RS: locale.sr,
  sv_SE: locale.sv,
  sv: locale.sv,
  th_TH: locale.th,
  tr_TR: locale.tr,
  uk_UA: locale.uk,
  uz_Cyrl_UZ: locale.uzCyrl,
  vi_VN: locale.vi,
  zh_CN: locale.zhCN,
  zh_Hant_TW: locale.zhTW,
};

export const getLocale = (currenLocale) => {
  return LOCALE_MAP[currenLocale] || locale.enUS;
};

export const getLocaleMoment = (currenLocale) => {
  return currenLocale;
};
