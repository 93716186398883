import React from "react";
import { Switch } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import { signInTheme } from "../pages/SignIn/signInTheme";
import PublicRoute from "../Components/Router/PublicRoute";
import GlobalContextProvider from "../Contexts/globalContext";

import { LOGIN, LOGOUT, LOGIN_SANDBOX, USER_CHANGE_PASSWORD, USER_CHANGE_PASSWORD_HASH } from "../constants/paths";
import { Login, LoginSandbox, Logout, SendMailChangePassword, ChangePasswordScreen } from '../pages/Access'

function Main() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={signInTheme}>
        <Switch>
          <GlobalContextProvider>
            <PublicRoute path={LOGOUT} component={Logout} exact></PublicRoute>
            <PublicRoute path={LOGIN} component={Login} exact></PublicRoute>
            <PublicRoute path={LOGIN_SANDBOX} component={LoginSandbox} exact></PublicRoute>
            <PublicRoute path={USER_CHANGE_PASSWORD_HASH} component={ChangePasswordScreen} exact />
            <PublicRoute path={USER_CHANGE_PASSWORD} component={SendMailChangePassword} exact />
          </GlobalContextProvider>
        </Switch>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default Main;
