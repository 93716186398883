import { Box, IconButton, Modal } from '@mui/material';
import React from 'react';

const style = {
 position: 'absolute',
 top: '50%',
 left: '50%',
 transform: 'translate(-50%, -50%)',
 bgcolor: 'background.paper',
 border: '2px solid #000',
 boxShadow: 24,
 p: 4,
};

export const Video = ({data}) => {

 const [open, setOpen] = React.useState(false);
 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 
 return (
  <Box
   sx={{
    width: '100%',
    height: '393.01px',
    borderRadius: '17px',
    background: '#5E5858',
    backgroundImage: data.videoImage,
    backgroundSize: 'cover',
    backgroundBlendMode: 'multiply',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
   }}
   className='mt-2'
  >
   <IconButton
    aria-label='delete'
    style={{
     width: '61.11px',
     height: '46.73px',
    }}
    onClick={handleOpen}
   >
    <svg
     width='28'
     height='32'
     viewBox='0 0 28 32'
     fill='none'
     xmlns='http://www.w3.org/2000/svg'
    >
     <path
      d='M25.8201 13.6133C26.7576 14.1602 27.2263 14.9609 27.2263 16.0156C27.2263 17.0703 26.7576 17.8711 25.8201 18.418L5.19509 30.6055C4.29665 31.1523 3.35915 31.1523 2.38259 30.6055C1.44509 30.0977 0.976339 29.2969 0.976339 28.2031V3.82812C0.976339 2.65625 1.46462 1.83594 2.44118 1.36719C3.45681 0.859375 4.37478 0.878906 5.19509 1.42578L25.8201 13.6133ZM3.78884 27.6172C3.78884 27.7344 3.84743 27.8125 3.96462 27.8516C4.08181 27.9297 4.199 27.9492 4.31618 27.9102L23.8865 16.3086C24.0037 16.2305 24.0623 16.1328 24.0623 16.0156C24.0623 15.8984 24.0037 15.8008 23.8865 15.7227L4.31618 4.12109C4.23806 4.08203 4.15993 4.08203 4.08181 4.12109C4.00368 4.12109 3.92556 4.16016 3.84743 4.23828C3.80837 4.27734 3.78884 4.33594 3.78884 4.41406V27.6172Z'
      fill='white'
     />
    </svg>
   </IconButton>

   <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby='modal-modal-title'
    aria-describedby='modal-modal-description'
   >
    <Box sx={style}>
     <iframe
      width='560'
      height='315'
      src= {data.videoUrl}
      title='YouTube video player'
      frameborder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowfullscreen
     ></iframe>
    </Box>
   </Modal>
  </Box>
 );
};
