import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';

function Loading({ height, size = 50 }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: height ? height : '100vh',
      }}>
      <CircularProgress size={size} color="primary" />
    </Box>
  );
}

Loading.prototypes = {
  height: PropTypes.number
};

export default Loading;
