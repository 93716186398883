import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Button, Stepper, Step, StepLabel, Box, Grid, Card, CardActionArea } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import BaseBasicForm from "../../../Layout/containers/BaseBasicForm";
import leftIcon from '../../../Images/stepbystep/lefticons/nueva-cuenta.png';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import { HOME } from "../../../constants/paths";
import { ApiFetch } from "../../../Utils/ApiFetch";

function ActivateUser(props) {

  const globalContext = useGlobalContext();

  const steps = [globalContext.t('signUp.activated.step.company'), globalContext.t('signUp.activated.step.facility')];

  const [id, setId] = useState(0);
  const [skipStep, setSkipStep] = useState(false);
  let history = useHistory();

  useEffect(() => {
    const ids = (props.location && props.location.state && props.location.state.ids) || {};
    if (ids.length > 0) {
      setId(ids[0]);
    }
  }, []);

  const handleOnClickEmpresa = () => {
    let args = {
      userId: id,
    };

    history.push({
      pathname: "/signin/company/stepbystep",
      state: args,
    });
  };

  const handleOnClickDashboard = () => {
    ApiFetch(
      'users/markAsDelegated',
      globalContext,
      true,
      async (res) => {
        if(res.status === 200){
          setSkipStep(true);
        }
        else {
          console.log("Delete error: ",res);
        }
      },
      (error) => {
        console.log('error users/markAsDelegated', error);
      },
      {
        method: 'POST'
      }
    );
  };

  useEffect(() => {
    if (skipStep){
      globalContext.refreshUserData()
        .then(()=> history.push({ pathname: HOME }))
        .catch((e)=> console.log(e));
    }
  }, [skipStep]);


  return (
    <BaseBasicForm text={globalContext.t("stepByStep.user.titleLeft.complete")} leftIcon={leftIcon} {...props} showOriginoImage="true">
      <FontAwesomeIcon icon={faCheckCircle} size="3x" color="#3969E8" />
      <Typography variant="h3" component="h4" color="text.primary" align="center" className="mt-2 mb-4">
        {globalContext.t("signUp.activated.title")}
      </Typography>
      <Typography variant="subtitle1" component="h4" color="text.secondary" align="center" className="mb-4">
        {globalContext.t("signUp.activated.subtitle.0")}
        <br />
        {globalContext.t("signUp.activated.subtitle.1")}
      </Typography>
      <Stepper alternativeLabel className="w-100 mb-4">
        {steps.map((label) => (
          <Step key={label} active>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>


      <Grid container spacing={2} className="pt-3">
        <Grid item xs={6}  textAlign="center">
          <Button
            className="bg-primary rounded rounded-4 p-3 mb-1 w-100"
            onClick={handleOnClickEmpresa}
          >
            <Typography variant="h3" sx={{fontSize: "1.1rem"}} component="p" color="white">
                {globalContext.t("signUp.activated.button.createCompany")}
            </Typography>
          </Button>

          <Typography variant="caption" component="p" color="text.secondary">
            {globalContext.t("signUp.activated.buttonSubtitle.createCompany")}
          </Typography>
        </Grid>

        <Grid item xs={6} textAlign="center">
          <Button
            className="border border-primary rounded rounded-4 p-3 mb-1 w-100"
            onClick={handleOnClickDashboard}
          >
            <Typography variant="h3" sx={{fontSize: "1.1rem"}} component="p" color="#3969e8">
              {globalContext.t("signUp.activated.button.createLater")}
            </Typography>
          </Button>

          <Typography variant="caption" component="p" color="text.secondary">
            {globalContext.t("signUp.activated.buttonSubtitle.createLater")}
          </Typography>
        </Grid>

      </Grid>
    </BaseBasicForm>
  );
}

export default ActivateUser;
