import React, { useEffect, useState } from 'react';
import BasicCell from './BasicCell';
import DisabledCell from './DisabledCell';
import ErrorCell from './ErrorCell';
import FixedCell from './FixedCell';
import PopoverErrorMesssage from './PopoverErrorMessage';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DialogPop from './DialogPop';

const TableCell = ({
  columnJSON,
  dataType,
  errors,
  iconDialog,
  isEditable,
  isFixed,
  onChangeValue,
  params,
  showReplaceDialog,
  setDataLastReplacedValue,
  globalModel,
  temporalPk,
  mode,
}) => {
  const [errorCell, setErrorCell] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [valueLocation, setValueLocation] = useState('');
  const errorOpen = Boolean(errorCell);

  const { values, name } = columnJSON;
  const { value, formattedValue } = params;

  const showError = (event) => {
    event.stopPropagation();
    setErrorCell(event.currentTarget);
  };
  const closeError = () => {
    setErrorCell(null);
  };

  const errorId = errorOpen ? 'error-popover' : undefined;

  const handleChangeValue = (fieldValue, id, fieldName) => {
    setDataLastReplacedValue({
      params: {
        id,
        field: fieldName,
        value: fieldValue,
      },
    });
    onChangeValue(fieldValue, id, fieldName, params.row.id);
    showReplaceDialog({
      id,
      field: fieldName,
      value: fieldValue,
    });
  };

  const handleChangeState = ({ state, saveData, fieldValue }) => {
    setOpen(state);
    if (saveData) handleChangeValue(fieldValue, params.id, params.field);
  };

  const handleOnClick = () => setOpen(!open);

  useEffect(() => {
    if (columnJSON.type === 'location') {
      try {
        const locationParse = JSON.parse(value)?.geometry?.coordinates;
        if (locationParse) {
          setValueLocation(
            `Lat: ${locationParse[1]}, Lng: ${locationParse[0]}`
          );
        } else {
          setValueLocation('Sin ubicación');
        }
      } catch {
        setValueLocation('Seleccione ubicación');
      }
    }
  }, [value]);

  return (
    <>
      {iconDialog && (
        <>
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            icon={faEdit}
            size="1x"
            onClick={handleOnClick}
            className="mr-1"
          />
          <DialogPop
            dataType={dataType}
            name={name}
            value={params.value}
            valuesSelect={values}
            open={open}
            onChange={handleChangeState}
            titleField={params?.colDef?.headerName ?? ''}
            params={params}
            attributeLocal={columnJSON}
            globalModel={globalModel}
            temporalPk={temporalPk}
            mode={mode}
          />
        </>
      )}
      {!isEditable ? (
        <DisabledCell params={params} value={formattedValue || value} />
      ) : errors.length > 0 ? (
        <ErrorCell
          params={params}
          showError={showError}
          value={
            columnJSON.type === 'location'
              ? errors.find(
                  (a) =>
                    a.column === params.field &&
                    params.api.getRowIndex(params.id) === a.row
                ).errors[0] ?? value
              : formattedValue || value
          }
          onClick={
            iconDialog
              ? () => {
                  handleOnClick();
                }
              : null
          }
        />
      ) : isFixed(params) || true ? (
        columnJSON.type === 'detail' ? (
          <FixedCell
            params={params}
            value={
              !(formattedValue || value) ? ' ' : `${columnJSON.label} completo`
            }
          />
        ) : columnJSON.type === 'location' ? (
          <FixedCell
            params={params}
            value={valueLocation}
            onClick={handleOnClick}
          />
        ) : (
          <FixedCell
            params={params}
            value={formattedValue || value}
            onClick={
              iconDialog
                ? () => {
                    handleOnClick();
                  }
                : null
            }
          />
        )
      ) : (
        <BasicCell params={params} value={formattedValue || value} />
      )}
      <PopoverErrorMesssage
        errorId={errorId}
        errorOpen={errorOpen}
        errorCell={errorCell}
        closeError={closeError}
        error={errors.map((item) => item.errors).flat()}
      />
    </>
  );
};

export default TableCell;

export const TableCellMem = React.memo(TableCell);
