import instance from '../Config/axios';
import { BOXES_URL } from '../constants/requestsUrl';

export const getTransfersByBox = async (boxId) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `${BOXES_URL.GET}/${boxId}/transfers`;

  return await instance.get(url, config);
};

export const getLotsByBox = async (boxId) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `${BOXES_URL.GET}/${boxId}/lots`;

  return await instance.get(url, config);
};

export const getBoxActivityEvent = async (boxId) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `${BOXES_URL.GET_ACTIVITIES}/${boxId}`;

  return await instance.get(url, config);
};

export const getAssetsByBox = async (boxId, assetTypeId, transferred) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = transferred ?
  `${BOXES_URL.GET}/${boxId}/${assetTypeId}/transferred` 
  : `${BOXES_URL.GET}/${boxId}/${assetTypeId}` 

  return await instance.get(url, config);
};