import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiFetch } from '../../Utils/ApiFetch';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { useHistory } from 'react-router-dom';
import { ASSETS } from '../../constants/paths';
import { TabStatistics } from './Components/TabStatistics';
import { LineChartClicks } from './Components/LineChartClicks';
import { PieChartTop5 } from './Components/PieChartTop5';

function QRsLogs () {
    const history = useHistory();
    const { typeQr, id } = useParams(); 
    const { userData } = useGlobalContext();

    const globalContext = useGlobalContext();

    const [permissionView, setPermissionView] = useState(null)
    const [qrLogsData, setLogsData] = useState([])
    const [selectedTab, setSelectedTab] = useState(1);
    const [qrLogsDates, setLogsDates] = useState([])
    const [hitsStats, setHitsStats] = useState({ last24Hours: 0, last7Days: 0, last30Days: 0, allTime: 0 });
    const [qrLogsTop5Country, setLogsTop5Country] = useState([])
    const [qrLogsTop5Devices, setLogsTop5Devices] = useState([])

    useEffect(() => {
        getPermission()
    },[])

    useEffect(() => {
        if(permissionView === false) {
            history.push({
                pathname: ASSETS,
            });
        }
    },[permissionView, history])

    const getPermission = async () => {
        await ApiFetch(
            `qrLogs/permission/${typeQr}/${id}`,
            globalContext,
            true,
            async (body) => {
                let resJson = await body.json();
                if(resJson){
                    const resPermission = resJson[0]
                    if(userData?.userData?.permissions?.boxes[resPermission.boxholder + "-" + resPermission.type]) {
                        setPermissionView(true)
                        await ApiFetch(
                            `qrLogs/logs/${typeQr}/${id}`,
                            globalContext,
                            true,
                            async (body) => {
                                let resJson = await body.json();
                                resJson && setLogsData(resJson)
                            },
                            (error) => {
                              console.log('error getQRsMetrics', error);
                              setPermissionView(false)
                            }
                        );
                    } else {
                        setPermissionView(false)
                    }
                } else {
                    setPermissionView(false)
                }
            },
            (error) => {
              console.log('error getQRsMetrics', error);
              setPermissionView(false)
            }
        );
    }

    return (
        <>
            {permissionView &&
            <>
                <TabStatistics 
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                />
                {selectedTab === 1 &&
                    <LineChartClicks 
                        qrLogsData={qrLogsData} 
                        hitsStats={hitsStats} 
                        setHitsStats={setHitsStats} 
                        qrLogsDates={qrLogsDates} 
                        setLogsDates={setLogsDates}
                    />
                }
                {selectedTab === 2 &&
                   <PieChartTop5 
                        type={'countries'} 
                        qrLogsData={qrLogsData}
                        qrLogsTop5={qrLogsTop5Country} 
                        setLogsTop5={setLogsTop5Country}
                    />
                }
                {selectedTab === 3 &&
                    <PieChartTop5 
                        type={'devices'} 
                        qrLogsData={qrLogsData}
                        qrLogsTop5={qrLogsTop5Devices} 
                        setLogsTop5={setLogsTop5Devices}
                    />
                }
            </>
            }
        </>
    )
}

export default QRsLogs;
