import React from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { IMAGES_PATH } from '../../constants/paths';

const NotSelected = () => {
  const { t } = useTranslation();
  return (
    <Grid sx={{ textAlign: 'center' }}>
      <img
        src={`${IMAGES_PATH}/add_activos.png`}
        alt={t('breadcrumb.asset.helpi.alt_img')}
        className="pl-3 pr-3 mb-4 mt-4"
      />
      <Typography variant="h4" component="p" color="text.primary" className="mb-2">
        {t('breadcrumb.asset.helpi.title')}
      </Typography>
      <Typography variant="body2" component="p" color="text.primary">
        {t('breadcrumb.asset.helpi.subtitle')}
      </Typography>
    </Grid>
  )
}

export default NotSelected