import { Typography } from '@mui/material';

export const AttributeText = ({ data, tt }) => {
  const att = (value) => {
    try {
      let sValue = value.split(",");
      if (sValue.length === 1) {
        let sValueTemp = value.split("(");
        if (sValueTemp.length > 1) {
          value = tt(sValueTemp[0].trim()) + " (" + sValueTemp[1];
        } else {
          value = tt(value.trim());
        }
        return value;
      } else {
        let sValueRet = [];
        for (let valueTemp of sValue) {
          let sValueTemp = valueTemp.split("(");
          if (sValueTemp.length > 1) {
            valueTemp = tt(sValueTemp[0].trim()) + " (" + sValueTemp[1];
          } else {
            valueTemp = tt(valueTemp.trim());
          }
          sValueRet.push(valueTemp);
        }
        return sValueRet.join(", ");
      }
    } catch (e) {
      return tt(value);
    }
  }
  return (
    <>
      <Typography color='text.secondary' variant='h3'>
        {tt(`qrTemplate.${data.label}`)}
      </Typography>
      <Typography color='text.grey' variant='subtitle1'>
        {
          att(data.value)
        }
      </Typography>
    </>
  );
};
