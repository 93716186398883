import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/pro-light-svg-icons';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';

const fileTypes = ['JPG', 'PNG', 'GIF'];
function DropZone({ children }) {
  const handleChange = (file) => {
    // console.log(file);
  };
  return (
    <FileUploader
      handleChange={handleChange}
      name="file"
      types={fileTypes}
      children={children}
    />
  );
}

const StyledDropZone = styled.div`
  border: 2px dashed #ffa589;
  border-radius: 4px;
  u {
    cursor: pointer;
  }
  svg path {
    fill: #ffa589;
  }
`;
function DragAndDropInputFile() {
  const { t } = useTranslation();
  return (
    <DropZone>
      <StyledDropZone className="d-flex flex-row justify-between align-center pt-2 pb-2 pl-3 pr-3">
        <FontAwesomeIcon size="lg" icon={faClone} className="mr-2" />
        <Typography variant="caption" component="p" color="text.primary">
          {t('component.fileDragAndDrop.0')}
          <u>{t('component.fileDragAndDrop.1')}</u>
        </Typography>
      </StyledDropZone>
    </DropZone>
  );
}

export default DragAndDropInputFile;
