import React, { useEffect } from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { useTranslation } from 'react-i18next';

export const LineChartClicks = (props) => {
    const {qrLogsData, hitsStats, setHitsStats, qrLogsDates, setLogsDates} = props
    const { t } = useTranslation();

    useEffect(() => {
        if(!(qrLogsDates.length > 0)) {
            const dates = qrLogsData.reduce((acc, log) => {
                const date = log.created_at.split('T')[0];
                const existing = acc.find(item => item.name === date); 
                if (existing) {
                    existing.clicks++; 
                } else {
                    acc.push({ name: date, clicks: 1 });
                }
                return acc;
            }, []).reverse();
            setLogsDates(dates)
        }

        if(!hitsStats.last24Hours && !hitsStats.last7Days && !hitsStats.last30Days && !hitsStats.allTime) {
            const qrLogsParsed = [...qrLogsData].map(log => ({
                ...log,
                created_at: new Date(log.created_at)
            }));
            
            const now = new Date();
            
            let last24Hours = 0;
            let last7Days = 0;
            let last30Days = 0;
            let allTime = qrLogsParsed.length; 
    
            qrLogsParsed.forEach(log => {
                const hoursDifference = Math.abs((now - log.created_at) / 3600000)
                if (hoursDifference <= 24) {
                    last24Hours++;
                }
                if (hoursDifference <= 24 * 7) {
                    last7Days++;
                }
                if (hoursDifference <= 24 * 30) {
                    last30Days++;
                }
            });
            setHitsStats({last24Hours, last7Days, last30Days, allTime})
        }
    },[hitsStats, qrLogsData, qrLogsDates, setHitsStats, setLogsDates])

    return (
        <>
            <Typography width={'100%'} textAlign={'center'} color={'#666666'}>{t('qrMetrics.LineChart.Last30Days')}</Typography>
            <ResponsiveContainer width="100%" height={200}>
                <LineChart
                    data={qrLogsDates?.slice(-30)}
                    margin={{
                        top: 10,
                        right: 50,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="name" 
                        tick={{ fontSize: 14 }} 
                        interval={Math.floor(qrLogsDates.slice(-30).length / 5)}
                    />
                    <YAxis 
                        tick={{ fontSize: 14 }}  
                    />
                    <Tooltip/>
                    <Line
                        type="basis" 
                        dataKey="clicks" 
                        stroke="#76CC81" 
                        strokeWidth={2}
                        dot={false} 
                    />
                </LineChart>
            </ResponsiveContainer>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginY: 2 }}>
                <Box sx={{ flexGrow: 1, backgroundColor: '#76CC81', paddingX: 2, borderRadius: '10px 0 0 10px', border: 1, borderColor: '#76CC81' }}>
                    <Typography>{t('qrMetrics.LineChart.Last24Hours')}</Typography>
                </Box>
                <Box sx={{ paddingX: 2, border: 1, minWidth: 80, textAlign: 'center' }}>
                    <Typography>{hitsStats.last24Hours} {t('qrMetrics.LineChart.Hits')}</Typography>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ flexGrow: 1, backgroundColor: '#76CC81', paddingX: 2, borderRadius: '10px 0 0 10px', border: 1, borderColor: '#76CC81' }}>
                    <Typography>{t('qrMetrics.LineChart.Last7Days')}</Typography>
                </Box>
                <Box sx={{ paddingX: 2, border: 1, minWidth: 80, textAlign: 'center' }}>
                    <Typography>{hitsStats.last7Days} {t('qrMetrics.LineChart.Hits')}</Typography>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ flexGrow: 1, backgroundColor: '#76CC81', paddingX: 2, borderRadius: '10px 0 0 10px', border: 1, borderColor: '#76CC81' }}>
                    <Typography>{t('qrMetrics.LineChart.Last30Days')}</Typography>
                </Box>
                <Box sx={{ paddingX: 2, border: 1, minWidth: 80, textAlign: 'center' }}>
                    <Typography>{hitsStats.last30Days} {t('qrMetrics.LineChart.Hits')}</Typography>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ flexGrow: 1, backgroundColor: '#76CC81', paddingX: 2, borderRadius: '10px 0 0 10px', border: 1, borderColor: '#76CC81' }}>
                    <Typography>{t('qrMetrics.LineChart.AllTimes')}</Typography>
                </Box>
                <Box sx={{ paddingX: 2, border: 1, minWidth: 80, textAlign: 'center' }}>
                    <Typography>{hitsStats.allTime} {t('qrMetrics.LineChart.Hits')}</Typography>
                </Box>
            </Box>
        </>
    )
}
