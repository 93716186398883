import React from 'react';
import styled from 'styled-components';
import { Button, Popover, Card, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next'

const StyledCard = styled(Card)`
  max-width: 10rem;
  min-width: 160px;
`;
function PopoverErrorMesssage({ errorId, errorOpen, errorCell, closeError, error }) {
  const { t } = useTranslation();
  return (
    <Popover
      id={errorId}
      open={errorOpen}
      anchorEl={errorCell}
      onClose={closeError}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}>
      <StyledCard style={{ minWidth: '300px' }} className="p-2 d-flex flex-column">
        <FontAwesomeIcon icon={faInfoCircle} className="mb-1" color="#61B15A" />
        <Typography variant="body2" component="p" className="mb-1">
          <ul>
            {error.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
        </Typography>
        <Button variant="text" className="align-self-end" onClick={closeError}>
          {t("component.understood")}
        </Button>
      </StyledCard>
    </Popover>
  );
}

export default PopoverErrorMesssage;
