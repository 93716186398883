import { Typography } from '@mui/material';
import { PhotoCarrousel } from './PhotoCarrousel';

export const AttributeImages = ({ data, tt }) => {
  return (
    <>
      <Typography color='text.secondary' variant='h3' mb={1}>
        {tt(`qrTemplate.${data.label}`)}
      </Typography>
      <PhotoCarrousel photos={data.value} />
    </>
  );
};
