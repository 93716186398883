import React, { useState, useEffect } from 'react';
import { Grid, Stack, Button } from '@mui/material';
import { useLocation, useHistory } from "react-router-dom";
import BasicFormStepByStep from '../../Components/Generic/basicFormStepByStep';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { LOTS } from "../../constants/paths";
import { useTranslation } from 'react-i18next';

// ref dg: se inicializa formulario de alta / edición, y recibe desde la pagina anterior parametros (location)
function CargaLotes() {
  // CV
  const { t } = useTranslation()
  let history = useHistory();
  const location = useLocation();
  const globalContext = useGlobalContext();
  const { selectedAsset } = useGlobalContext(); //
  const [fields, setFields] = useState([]);
  const [form, setForm] = useState(null);

  const setModels = () => {
    if (selectedAsset.id !== -1) {
      let models = globalContext.getSessionDataByKey("models");
      setFields(models["lotModels"]["basic"][selectedAsset.id].attributes);
      setForm(models["lotModels"]["basic"][selectedAsset.id].form);
      return models;
    } // dromerodemo
  }

  useEffect(() => {
    //setModels();
  }, []);

  useEffect(() => {
    setModels();
  }, [selectedAsset]);


  const redirectToAssetList = () => {
    history.push({
      pathname: LOTS
    });
  }

  return (
    //Starts the route view with <Grid container> and add <Grid item 12> for rows.
    <>
      <Grid container spacing={2}>

        <Grid item xs={12} sx={{ mb: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {(process.env.REACT_APP_HIDE_NOT_IMPLEMENTED === 'true' ? false : true) &&
              <Button sx={{ ml: 2 }} variant="outlined">DAR DE BAJA</Button>
            }
          </Stack>
        </Grid>

        {fields.length > 0 &&
          <Grid item xs={12} sx={{ mt: 3 }}>
            <BasicFormStepByStep
              formMode={'vertical'}
              saveFunc={() => { }}
              show={true}
              onCanceled={() => { redirectToAssetList() }}
              onUpdated={() => { redirectToAssetList() }}
              withDefault={false}
              mode={location.state.refererContext.mode}
              pk={location.state.refererContext.pk}
              //pkObjectType={location.state.refererContext.objectTypeSubType}
              pkObjectType={selectedAsset.id}
              pkReferer={null}
              //globalModel={location.state.refererContext.globalModel}
              globalModel={selectedAsset.id}
              objectType={'lot'}
              objectTypeBindForm={'lot'}
              parentType={location.state.refererContext.parentType}
              parentTypeSubType={location.state.refererContext.parentTypeSubType}
              parentId={location.state.refererContext.parentId}
              count={0}
              fields={fields}
              form={form}
              showCancelButton={true}
              cancelButtontext={t("stepByStep.button.cancel")}
              submitOnlyLastStep={true}
            />
          </Grid>
        }
      </Grid>
    </>
  );
}

export default CargaLotes;