import React, { } from "react";
import { Typography } from "@mui/material";
import originoLogoFull from "../../Images/origino_logo_full.png";
import { StyledContainer, StyledLeftSide, StyledRightSide } from "../../pages/SignIn/SignIn.styles";

const BaseBasicForm = (props) => {
  return (
    <StyledContainer container spacing={0}>
      <StyledLeftSide
        style={{
          backgroundImage: `url(${originoLogoFull})`,
          backgroundPosition: 'top',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '100vw',
          height: '100vh',
        }}
        item xs={6}
        className="d-flex flex-column align-center justify-center"
      >
        <Typography variant="h4"
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', justifyContent: 'space-evenly' }}
          component="h2"
          color="text.blue">
          {(props.leftIcon) &&
            <>
              <img
                alt="leftIcon"
                className="mb-2"
                style={{
                  width: "70px"
                }} src={props.leftIcon}
              />
            </>
          }
          {props.text}
        </Typography>

        {props.showOriginoImage && false && <img src={originoLogoFull} alt="Origino" />}

      </StyledLeftSide>
      <StyledRightSide item xs={6} className="d-flex flex-column align-center justify-center">
        {props.children}
      </StyledRightSide>
    </StyledContainer>
  );
};

export default BaseBasicForm;
