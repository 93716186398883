import instance from '../Config/axios'

export const signOut = async () => {
  try {
    const token = localStorage.getItem('USER_TOKEN')
    const config = {
      headers: {
        Authorization: token,
      },
    };
    const res = await instance.post(`/users/signOut`, {}, config);
    return res
  } catch (error) {
    console.log('error signOut', error)
  }
};

export const signUp = async (args) => {
  try {
    const token = localStorage.getItem('USER_TOKEN');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: token }),
      },
    };

    const res = await instance.post(`/users/signUp`, args, config);
    return res;
  } catch (error) {
    console.log('Error en signUp', error);
    throw error;
  }
};
