import React from 'react';
import { Select, MenuItem } from '@mui/material';
import useGlobalContext from '../../Hooks/useGlobalContext';

const LanguageSelect = ({
  value,
  onChange,
  languages,
  sx = {},
  ...props
}) => {
  const globalContext = useGlobalContext();

  const selectValue = value || globalContext.selectedLang;
  const handleSelectChange = onChange || globalContext.handleChangeLanguage;
  const languageOptions = languages || globalContext.languages;

  return (
    <Select
      name="language"
      value={selectValue}
      onChange={handleSelectChange}
      sx={{ height: '2rem', ...sx }}
      {...props}
    >
      {Object.keys(languageOptions).map((lng) => (
        <MenuItem value={lng} key={lng} disabled={selectValue === lng}>
          {languageOptions[lng].nativeName}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguageSelect;
