import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import UploadContainer from '../../Components/FileUploader/BaseComponents/UploadContainer';
import ManualContainer from '../../Components/FileUploader/BaseComponents/ManualContainer';
import useGlobalContext from '../../Hooks/useGlobalContext';
import {
  ASSETS,
  ASSETS_MANUAL_LOAD,
  ASSETS_MASSIVE_EDIT,
} from '../../constants/paths';
import ModalErrorInfo from '../../Components/Feature/ModalErrorInfo';
import { useTranslation } from 'react-i18next';

const BaseUploadContainerAssets = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const location = useLocation();
  const [manualAdd, setManualAdd] = useState(false);
  const [action, setAction] = useState('add');
  const [showContainer, setShowContainer] = useState(false);
  const [massiveData, setMassiveData] = useState([]);
  const [filterAttributeModel, setFilterAttributeModel] = useState([])
  const [modalErrorInfo, setModalErrorInfo] = useState(false);
  const openModalInfo = () => {
    setModalErrorInfo(true);
  }
  const onModalErrorInfoClosed = () => {
    let redirectPath = "";
    if (props.redirectOnErrorPath) {
      redirectPath = props.redirectOnErrorPath
    } else {
      redirectPath = ASSETS
    }
    history.push({
      pathname: redirectPath,
      state: {},
    });
    setModalErrorInfo(false);
  }

  const setManual = () => {
    setManualAdd(
      history.location.pathname === ASSETS_MANUAL_LOAD ||
      history.location.pathname === ASSETS_MASSIVE_EDIT
    );
  };

  useEffect(() => {
    setManual();
    if (location?.state?.rows?.length > 0 && (location?.state?.args?.fields?.length > 0 || location?.state?.args?.isMassive)) {
      setMassiveData(location.state.rows);
      setFilterAttributeModel(location.state.args.fields)
    }
  }, []);

  useEffect(() => {
    if (massiveData.length > 0) {
      setAction("update");
    } else {
      setAction("add");
    }
  }, [massiveData])

  useEffect(() => {
    setManual();
  }, [history.location.pathname]);

  const { selectedBox, selectedAsset } = useGlobalContext();

  useEffect(() => {
    if (selectedAsset.id !== -1 && selectedBox.id !== -1) {
      setShowContainer(true);
    }
  }, [selectedAsset, selectedBox]);

  const handleUploadOk = (res) => {
    // console.log('OK', res);
  };

  const handleUploadError = (error) => {
    openModalInfo();
  };

  return (
    <>
      <ModalErrorInfo
        open={modalErrorInfo}
        toggleModal={
          () => {
            onModalErrorInfoClosed();
          }
        }
        modalText={t("component.comError")}
      />
      {showContainer && (
        <div>
          {!manualAdd && (
            <>
              <UploadContainer
                pk={null}
                objectType="asset"
                action="add"
                entityMode="asset"
                globalModel={selectedAsset.id}
                parentId={selectedBox.id}
                temporalDetailParentId="-1"
                pkReferer={null}
                pkObjectType={selectedAsset.id}
                onUploadOk={handleUploadOk}
                onUploadError={handleUploadError}
              />
            </>
          )}
          {manualAdd && (
            <>
              <ManualContainer
                pk={null}
                action={action}
                data={massiveData}
                filterAttributeModel={filterAttributeModel}
                objectType="asset"
                entityMode="asset"
                globalModel={selectedAsset.id}
                parentId={selectedBox.id}
                temporalDetailParentId="-1"
                pkReferer={null}
                pkObjectType={selectedAsset.id}
                onUploadOk={handleUploadOk}
                onUploadError={handleUploadError}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default BaseUploadContainerAssets;
