export default function Location({ color }) {
 return (
  <svg
   fill='none'
   height={45}
   viewBox='0 0 45 45'
   width={45}
   xmlns='http://www.w3.org/2000/svg'
  >
   <path
    fill={color ? color : '#3969E8'}
    d='M22.4998 3.74902C15.2435 3.74902 9.37476 9.61777 9.37476 16.874C9.37476 26.7178 22.4998 41.249 22.4998 41.249C22.4998 41.249 35.6248 26.7178 35.6248 16.874C35.6248 9.61777 29.756 3.74902 22.4998 3.74902ZM22.4998 21.5615C19.9123 21.5615 17.8123 19.4615 17.8123 16.874C17.8123 14.2865 19.9123 12.1865 22.4998 12.1865C25.0873 12.1865 27.1873 14.2865 27.1873 16.874C27.1873 19.4615 25.0873 21.5615 22.4998 21.5615Z'
   />
  </svg>
 );
}
