import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@material-ui/core';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Stack,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import ColorPicker from 'material-ui-color-picker';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MassiveSummary from '../../Components/FileUploader/MassiveSummary';
import useGlobalContext from '../../Hooks/useGlobalContext';
import ActivosBasicTable from '../../Layout/components/Activos/ActivosBasicTable';
import { ApiFetch, SyncApiFetch } from '../../Utils/ApiFetch';
import BasicfieldCollection from './basicFieldCollection';
import CheckBoxField from './Fields/CheckBoxField';
import DateField from './Fields/DateField';
import ErrorField from './Fields/ErrorField';
import FileField from './Fields/FileField';
import LocationField from './Fields/LocationField';
import MaskedField from './Fields/MaskedField';
import GroupedMultiSelectBoxField from './Fields/GroupedMultiSelectBoxField';
import MultiSelectBoxField from './Fields/MultiSelectBoxField';
import MultiSelectField from './Fields/MultiSelectField';
import NumberMaskedField from './Fields/NumberMaskedField';
import PolygonField from './Fields/PolygonField';
import SelectField from './Fields/SelectField';
import SelectImageField from './Fields/SelectImageField';
import { useTranslation } from 'react-i18next';
import BoxTarget from '../FileUploader/BoxTarget';
import { calculateLabelDates } from '../../Utils/gridDefaultValues';
import ModalErrorInfo from '../../Components/Feature/ModalErrorInfo';
import { ASSETS } from '../../constants/paths';
import { getLocaleMoment } from '../../Utils/locale';
import isValid from 'date-fns/isValid';
import { fieldsValidations } from '../../services/fieldsValidations';
import { MuiTelInput } from 'mui-tel-input';
import ModalErrorResponse from '../Feature/ModalErrorResponse';
import AutocompleteField from './Fields/Autocomplete';
import addMassiveItemsCondition from '../../Helpers/addMassiveItemsCondition';

const MAX_STRING_LENGTH = 256;

function BasicFormStepByStep(props) {
  const { t } = useTranslation();
  const globalContext = useGlobalContext();
  let history = useHistory();
  const { userData, selectedBox, selectedCompany } = globalContext;
  const locale = getLocaleMoment(userData?.userData?.locale);
  const timeZone = userData?.userData?.timezone;
  const [randomKeyContext, setRandomKeyContext] = useState(0);
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [completed] = useState({});
  const [readonly, setReadonly] = useState(true);
  const [permissionsDisabled, setPermissionsDisabled] = useState({});
  const [permissionsListDisabled, setPermissionsListDisabled] = useState({});
  const [fieldsError, setFieldsError] = useState({});
  const [formError, setFormError] = useState(false);
  const [eventDate] = useState('');
  const [eventComment] = useState('');
  const [temporalPkReferer, setTemporalPkReferer] = useState(-1);
  const [fieldsValues, setFieldsValues] = useState({});
  const [fieldsLabels, setFieldsLabels] = useState({});
  const [fieldsToCompOnUpdate, setFieldsToCompOnUpdate] = useState({});
  const [showStepTitle, setShowStepTitle] = useState(true);
  const [showSectionTitle, setShowSectionTitle] = useState(true);
  const [fieldColSize, setFieldColSize] = useState(12);
  const [sectionAccordionMode, setSectionAccordionMode] = useState(true);
  const [submitButtonText, setSubmitButtonText] = useState(
    t('stepByStep.button.submit')
  );
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [listErrors, setListErrors] = useState([]);
  const [cancelButtontext, setCancelButtontext] = useState(
    t('stepByStep.button.cancel')
  );
  const [deleteButtonText, setDeleteButtonText] = useState(
    t('stepByStep.button.delete')
  );
  const [showForm, setShowForm] = useState(true);
  const [showSubmit, setShowSubmit] = useState(true);
  const [initSteps, setInitSteps] = useState(true);
  const [modalErrorInfo, setModalErrorInfo] = useState(false);
  const [skipFirstSelectImage, setSkipFirstSelectImage] = useState(false)
  const modelLabels = JSON.parse(localStorage.getItem('SESSION_DATA'))?.models[props.pkObjectType]?.alterLabel?.filter(label =>
    label.event === props.eventType)[0]?.labels

  const openModalInfo = () => {
    setModalErrorInfo(true);
  };

  const onModalErrorInfoClosed = () => {
    let redirectPath = '';
    if (props.redirectOnErrorPath) {
      redirectPath = props.redirectOnErrorPath;
    } else {
      redirectPath = ASSETS;
    }
    history.push({
      pathname: redirectPath,
      state: {},
    });
    setModalErrorInfo(false);
  };

  const getDefaultValueSufix = async (field) => {
    let sufix = '';
    try {
      if (
        field.defaultValueSufix?.dataSourceMode === 'autonum_table' &&
        field.defaultValueSufix?.dataSourceTable
      ) {
        let res = await SyncApiFetch(
          'models/autonumsufix/get/' + field.defaultValueSufix?.dataSourceTable,
          globalContext,
          true
        );
        sufix = '_' + res.sufix;
      }
      return sufix;
    } catch (e) {
      return sufix;
    }
  };

  useEffect(() => {
    let ret = {};
    if (props.fields.length > 0) {
      let fieldsNames = {};
      for (let field of props.fields) {
        if (field.type === 'date') fieldsNames[field.name] = null;
        else fieldsNames[field.name] = '';
      }
      ret = fieldsNames;
    } else {
      ret = {};
    }
    setFieldsValues(ret);

    if (props.objectType === 'users' && props.pk != null) {
      setActiveStep(1);
    }
    const callSources = async () => {
      for (let field of props.fields) {
        if (field.dataSourceType === 'api') {
          if (field.dataSourceApiEndpoint != null) {
            try {
              let res = await SyncApiFetch(
                'custom/form/sources/' +
                  field.dataSourceApiEndpoint +
                  '/' +
                  props.pk,
                globalContext,
                true
              );
              field.values = res;
            } catch (e) {
              console.log('error callSources', e);
            }
          }
        }
      }
    };
    callSources();
  }, [props.fields]);

  useEffect(() => {
    setShowForm(true);
    setRandomKeyContext(new Date().valueOf());

    if (props.showStepTitle !== undefined) {
      setShowStepTitle(props.showStepTitle);
    }
    if (props.showSectionTitle !== undefined) {
      setShowSectionTitle(props.showSectionTitle);
    }
    if (props.fieldColSize !== undefined) {
      setFieldColSize(props.fieldColSize);
    }
    if (props.sectionAccordionMode !== undefined) {
      setSectionAccordionMode(props.sectionAccordionMode);
    }
    if (props.submitButtonText !== undefined) {
      setSubmitButtonText(props.submitButtonText);
    }
    if (props.showCancelButton !== undefined) {
      setShowCancelButton(
        props.showCancelButton || props.showCancelButton === 'true'
      );
    }

    if (props.cancelButtontext !== undefined) {
      setCancelButtontext(props.cancelButtontext);
    }
    localStorage.setItem('cacheUnique', JSON.stringify({}));
  }, []);

  useEffect(() => {
    if (props.showCancelButton !== undefined) {
      setShowCancelButton(
        props.showCancelButton || props.showCancelButton === 'true'
      );
    }
  }, [props.showCancelButton]);

  useEffect(() => {
    setReadonly(props.initReadonly);
  }, [props.initReadonly]);

  useEffect(() => {
    setInitSteps(false);
    let stepsTemp = [];
    if (props.form != null) {
      for (let step of props.form.config) {
        stepsTemp.push(step.title);
      }
    }
    setSteps(stepsTemp);
    setTimeout(() => {
      setInitSteps(true);
    }, 100);
  }, [props.form]);

  useEffect(() => {
    if (props.saveCount > 0) {
      submit();
    }
  }, [props.saveCount]);

  useEffect(() => {
    if (props.fields) {
      if (props.fields.length === 0) {
        return;
      }
    }
    let valuesTemp = {};
    let permissionsDisabledTemp = {};
    let permissionsListDisabledTemp = {};
    for (let i = 0; i < props.fields.length; i++) {
      if (props.fields[i].type === 'checkbox') {
        valuesTemp[props.fields[i].name] = false;
      } else {
        valuesTemp[props.fields[i].name] = '';
      }

      let flagTierDisabled = false;
      if (props.fields[i]?.tiers?.edit?.tiers) {
        let flagExistTier = false;
        for (let tier of props.fields[i].tiers.edit.tiers) {
          if (userData?.userData?.companiesTiers && userData.userData.companiesTiers[selectedCompany?.id]) {
            for (let tierCompany of userData.userData.companiesTiers[
              selectedCompany?.id
            ]) {
              if (tier === tierCompany) {
                flagExistTier = true;
              }
            }
          }
        }
        if (flagExistTier === false) {
          flagTierDisabled = true;
        }
      }
      if (
        props.fields[i].permissions.edit[props.parentType].includes(
          parseInt(props.parentTypeSubType)
        ) === false ||
        flagTierDisabled
      ) {
        permissionsDisabledTemp[props.fields[i].name] = true;
      } else {
        permissionsDisabledTemp[props.fields[i].name] = false;
      }
      if (
        props.fields[i].permissions.list[props.parentType].includes(
          parseInt(props.parentTypeSubType)
        ) === true
      ) {
        permissionsListDisabledTemp[props.fields[i].name] = false;
      } else {
        permissionsListDisabledTemp[props.fields[i].name] = true;
      }
      if (
        props.fields[i].permissions.new[props.parentType].includes(
          parseInt(props.parentTypeSubType)
        ) === true
      ) {
        permissionsDisabledTemp[props.fields[i].name] = false;
      } else {
        permissionsDisabledTemp[props.fields[i].name] = true;
      }
      if(props.fields[i].disabled === true) {
        permissionsDisabledTemp[props.fields[i].name] = true;
      }
    }

    setPermissionsDisabled(permissionsDisabledTemp);
    setPermissionsListDisabled(permissionsListDisabledTemp);

    if (props.withDefault) {
      bindFormDefault();
    }
    if (props.bindValues != null) {
      valuesTemp = props.bindValues;
    }
    const checkDefault = async () => {
      if (props.bindWithDefault) {
        for (let i = 0; i < props.fields.length; i++) {
          valuesTemp[props.fields[i].name] = props.fields[i].defaultValue;
          if (props.fields[i].defaultValueCustomFunc) {
            valuesTemp[props.fields[i].name] = await delayCustomBind(
              props.fields[i]
            );
          }
          if (
            props.fields[i].defaultValueSufix?.dataSourceMode ===
              'autonum_table' &&
            props.fields[i].defaultValueSufix?.dataSourceTable
          ) {
            let sufix = await getDefaultValueSufix(props.fields[i]);
            valuesTemp[props.fields[i].name] =
              props.fields[i].defaultValue + sufix;
          }
        }
      }

      if (props.bindNullWithDefault) {
        for (let i = 0; i < props.fields.length; i++) {
          valuesTemp[props.fields[i].name] = props.fields[i].defaultValue;
          if (props.fields[i].defaultValueCustomFunc) {
            valuesTemp[props.fields[i].name] = await delayCustomBind(
              props.fields[i]
            );
          }
          if (
            props.fields[i].defaultValueSufix?.dataSourceMode ===
              'autonum_table' &&
            props.fields[i].defaultValueSufix?.dataSourceTable
          ) {
            let sufix = await getDefaultValueSufix(props.fields[i]);
            valuesTemp[props.fields[i].name] =
              props.fields[i].defaultValue + sufix;
          }
        }
      }
    };
    checkDefault();

    setFieldsValues(valuesTemp);

    if (props.mode === 'update' || props.mode === 'delete') {
      setShowForm(false);
      bindForm(valuesTemp, props.pk, [], props.prevBind);
    }
  }, [props.fields, props.bindValues]);

  useEffect(() => {
    setTemporalPkReferer(new Date().valueOf() * -1);
  }, [props.show]);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const delayCustomBind = async (field) => {
    try {
      const response = await SyncApiFetch(
        'custom/form/sources/' + field.defaultValueCustomFunc + '/' + props.pk,
        globalContext,
        true
      );
      return response.value;
    } catch (e) {
      return '';
    }
  };

  const continued = () => {
    setActiveStep(activeStep + 1);
  };

  const back = () => {
    setActiveStep(activeStep - 1);
  };

  const form = useRef(null);

  const bindForm = (valuesArg, pk, filterAttributes = [], prevBind = null) => {
    if(props.activePrevBind && prevBind !== null && Object.keys(prevBind).length !== 0) {

      Object.entries(prevBind).forEach(([clave, valor]) => {
        valuesArg[clave] = valor
      });

      setFieldsValues({ ...valuesArg });
      setShowForm(true);
    } else {
      ApiFetch(
        'objects/get/' + props.objectTypeBindForm + '/' + pk,
        globalContext,
        true,
        async (body) => {
          let resJson = await body.json();
          let values = valuesArg;

          let valuesTemp = Object.entries(resJson.values);
          if (filterAttributes?.length > 0) {
            valuesTemp = valuesTemp.filter(([name]) =>
              filterAttributes.some((item) => item === name)
            );
          }

          let labelsTemp = {};
          for (let labelTemp of Object.entries(resJson.labels)) {
            if (labelTemp[1] != null) {
              labelsTemp[labelTemp[0]] = labelTemp[1];
            }
          }

          setFieldsLabels(labelsTemp);
          for (let value of valuesTemp) {
            if (value[1] === 'false') {
              value[1] = false;
            }
            if (value[1] === 'true') {
              value[1] = true;
            }
            if(value[0] === "_boxtarget_"){
              value[1] = null
            }
            if (
              (value[1] === 'null' || value[1] === '' || value[1] == null) &&
              props.bindNullWithDefault
            ) {}
            else {
              values[value[0]] = value[1];
            }
          }

          props.setFirstBindEdit && props.setFirstBindEdit({ ...values })
          setFieldsToCompOnUpdate({...values})
          setFieldsValues({ ...values });
          setShowForm(true);
        },
        (error) => {
          openModalInfo();
        }
      );
    }
  };

  const bindFormDefault = () => {
    ApiFetch(
      `objects/get/${props.objectTypeBindForm}/${props.pkDefault}`,
      globalContext,
      true,
      async (body) => {
        let resJson = await body.json();
        setFieldsValues(resJson.values);
      },
      (error) => {
        openModalInfo();
      }
    );
  };

  const recursiveSearchFieldToReset = (field) => {
    let valuesRet = [];
    for (let i = 0; i < props.fields.length; i++) {
      if (props.fields[i].fkField === field) {
        valuesRet.push(props.fields[i].name);

        let ret = recursiveSearchFieldToReset(props.fields[i].name);

        if (ret.length > 0) {
          for (let fieldTemp of ret) {
            valuesRet.push(fieldTemp);
          }
        }
      }
    }
    return valuesRet;
  };

  const onChange = (field, val) => {
    let valuesTemp = { ...fieldsValues };
    let ret = recursiveSearchFieldToReset(field);
    if (ret.length > 0) {
      for (let fieldToReset of ret) {
        valuesTemp[fieldToReset] = '';
      }
    }

    const itemField = props.fields.find((x) => x.name === field);
    if (itemField.type === 'select') {
      const labelsTemp = { ...fieldsLabels };
      labelsTemp[field] = itemField.values.find((z) => z.key === val)?.label;
      setFieldsLabels(labelsTemp);
    }

    valuesTemp[field] = val;
    if (itemField?.bindForm?.use === true && val !== '') {
      bindForm(valuesTemp, val, itemField?.bindForm?.attributes);
    } else {
      setFieldsValues(valuesTemp);
    }
    props.onChangeField && props.onChangeField(field, val, valuesTemp);
  };

  const onChangeDate = (field, val) => {
    if (isValid(val)) {
      const labelsTemp = { ...fieldsLabels };
      const { dateLabel, dateValue } = calculateLabelDates(val, timeZone);
      labelsTemp[field] = dateLabel;
      setFieldsLabels(labelsTemp);
      onChange(field, dateValue);
    } else {
      // If the date is not valid, clear the input
      const labelsTemp = { ...fieldsLabels };
      labelsTemp[field] = ''; // Set the label to an empty string
      setFieldsLabels(labelsTemp);
      onChange(field, ''); // Set the field value to an empty string
    }
  };

  const onChangeFileUploader = ({ name, statusInfo }) => {
    let valuesTemp = { ...fieldsValues };
    valuesTemp[name] = statusInfo;
    setFieldsValues(valuesTemp);

    props.onChangeField &&
      props.onChangeField(name, valuesTemp[name], valuesTemp);
  };

  const onChangeAssetsSelectList = (val) => {
    let valuesTemp = { ...fieldsValues };
    if (val.rows.length > 0) {
      let rows = [];
      for (let row of val.rows) {
        rows.push(row.id);
      }
      valuesTemp[val.name] = JSON.stringify(rows);
    } else {
      valuesTemp[val.name] = '';
    }
    setFieldsValues(valuesTemp);

    props.onChangeField &&
      props.onChangeField(val.name, valuesTemp[val.name], valuesTemp);
  };

  const onChangeMultiselect = (field, val) => {
    let valuesTemp = { ...fieldsValues };

    let ret = recursiveSearchFieldToReset(field);
    if (ret.length > 0) {
      for (let fieldToReset of ret) {
        valuesTemp[fieldToReset] = '';
      }
    }

    let valTemp = [];
    let labTemp = [];

    const labelsTemp = { ...fieldsLabels };

    const itemField = props.fields.find((x) => x.name === field);

    for (let item of val) {
      valTemp.push(item.id);
      labTemp.push(itemField.values.find((z) => z.key === item.id).label);
    }

    labelsTemp[field] = labTemp.join(',');
    setFieldsLabels(labelsTemp);
    valuesTemp[field] = valTemp.join(',');
    setFieldsValues(valuesTemp);

    if (props.onChangeField !== undefined) {
      props.onChangeField(field, valTemp.join(','), valuesTemp);
    }
  };

  const autocompleteRemplaceFields = (name, value, remplaceFields) => {
    let allFieldsValues = { ...fieldsValues };
    allFieldsValues[name] = value

    if(remplaceFields)
    Object.keys(remplaceFields).forEach(field => {
      allFieldsValues[field] = remplaceFields[field]
    });

    setFieldsValues(allFieldsValues);
  };

  const checkItem = (field, id) => {
    return fieldsValues[field] === '' || fieldsValues[field] === undefined
      ? false
      : fieldsValues[field].split(',').some((item) => item === id);
  };

  const onChangeMultiselectBox = (field, val) => {
    let valuesTemp = { ...fieldsValues };

    let listTemp =
      valuesTemp[field] === '' || valuesTemp[field] == null
        ? []
        : fieldsValues[field].split(',');
    let ret = listTemp.findIndex((item) => item === val.id);
    if (ret === -1) {
      listTemp.push(val.id);
    } else {
      listTemp.splice(ret, 1);
    }

    valuesTemp[field] = listTemp.join(',');
    setFieldsValues(valuesTemp);

    if (props.onChangeField !== undefined) {
      props.onChangeField(field, listTemp.join(','), valuesTemp);
    }
  };

  const activateFirstStepWithError = (field) => {
    if (props?.form?.config?.length > 0) {
      for (const [indexStep, step] of props.form.config.entries()) {
        if (step?.sections?.length > 0) {
          for (const section of step.sections) {
            if (section?.fields?.length > 0) {
              if (section.fields.includes(field.name)) {
                setActiveStep(indexStep);
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  };

  const isValidableField = (
    permissionsDisabled,
    permissionsListDisabled,
    fieldName
  ) => {
    const editPerm = permissionsDisabled[fieldName] ?? false;
    const listPerm = permissionsListDisabled[fieldName] ?? false;
    return !(listPerm || editPerm);
  };

  const validateForm = async () => {
    let fieldErrorTemp = { ...fieldsError };
    let stepErrorFound = false;
    const holder = selectedBox.id;
    for (const field of props.fields) {
      fieldErrorTemp[field.name] = { value: false, messages: [] };

      if(!field.hideCondition?.parentValue.includes(fieldsValues[field.hideCondition?.parentField])){
        const isValidable = isValidableField(
          permissionsDisabled,
          permissionsListDisabled,
          field.name
        );
        if (isValidable) {
          const hasError = await fieldsValidations(
            fieldsValues[field.name],
            field.validations,
            {
              objectType: props.objectType,
              pkObjectType: props.pkObjectType,
              globalModel: props.globalModel,
              context: globalContext,
              mode: props.mode,
              fieldType: field.type,
              fieldName: field.name,
              selectValues: field.values,
              pk: props.pk,
              holder,
            }
          );
          if (hasError.length !== 0) {
            if (stepErrorFound === false) {
              stepErrorFound = activateFirstStepWithError(field);
            }
            fieldErrorTemp[field.name] = { value: true, messages: hasError };
            setFormError(stepErrorFound);
            setFieldsError(fieldErrorTemp);
          }
        }
      } else {
        fieldsValues[field.name] = null
      }
    }

    if (stepErrorFound) {
      return true;
    }
    return false;
  };


  const submit = async (event) => {
    event.preventDefault();

    let error = await validateForm();
    if (error) return;

    let fieldsValuesTemp = {};
    for (let fieldName of Object.keys(fieldsValues)) {
      for (let i = 0; i < props.fields.length; i++) {
        if (props.fields[i].name === fieldName) {
          if(props.fields[i].type === "checkbox"){
            fieldsValuesTemp[fieldName] = fieldsValues[fieldName] === true ? true : false
          } else {
            fieldsValuesTemp[fieldName] = fieldsValues[fieldName];
          }
        }
      }
    }

    let dataObj = {};
    let fieldsLabelsTemp = { ...fieldsLabels };
    if ('_eventDate_' in fieldsValues) {
      const { dateLabel, dateValue } = calculateLabelDates(
        fieldsValues._eventDate_,
        timeZone
      );
      fieldsLabelsTemp._eventDate_ = dateLabel;
      fieldsValuesTemp._eventDate_ = dateValue;
    }
    if(props.pkObjectType === "33" || props.pkObjectType === "34"){
      fieldsLabelsTemp.specie = 'BOVINA';
      fieldsValuesTemp.average_projected_weight = '';
    }

    if (props.mode === 'delete') {
      ApiFetch(
        `objects/delete/${props.objectType}/${props.pk}`,
        globalContext,
        true,
        async (res) => {
          if (res.status === 200) {
            let ids = await res.json();
            props.onUpdated(ids);
            if (props.updateFromChildMassive) {
              props.updateFromChildMassive(props.pk);
            }
          }
          setShowSubmit(false);
        },
        (error) => {
          openModalInfo();
          setShowSubmit(true);
        },
        {
          method: 'DELETE',
        }
      );
      setShowForm(false);
      return;
    }

    let quantityAsset = 1
    if(props.mode === 'new_massive'){
      quantityAsset = fieldsValuesTemp["quantity_assets"]
    }

    if(props.mode === 'new' || props.mode === 'new_massive'){
      Object.entries(fieldsValuesTemp).map(([key, value]) =>
        (!value || (key = "quantity_assets"))
          && delete fieldsValuesTemp[key]
      )
    }
    if(props.mode === 'update'){
      Object.entries(fieldsValuesTemp).map(([key, value]) =>
        fieldsToCompOnUpdate &&
          (!value && !fieldsToCompOnUpdate[key]) && delete fieldsValuesTemp[key]
      )
    }

    if(props.pkObjectType === "35") {
      if(props.mode === 'new' || props.mode === 'new_massive') {
        fieldsValuesTemp["asset_status_change"] = "active"
        fieldsLabelsTemp["asset_status_change"] = "Activo"
      }
    }

    if (props.mode === 'new' || props.mode === 'new_massive') {
      dataObj = {
        values: fieldsValuesTemp,
        labels: fieldsLabelsTemp,
        headerValues: {
          date: eventDate,
          comment: eventComment,
        },
        action: 'add',
        actionContext: {
          context: 'manual_form',
          eventType: props.eventType ? props.eventType : -1,
        },
        pk: null,
        objectType: props.objectType,
        pkObjectType: props.pkObjectType,
        pkReferer: props.pkReferer,
        holder: props.parentId,
        temporalDetailParentId: temporalPkReferer,
        globalModel: props.globalModel,
      };
    }

    if (props.mode === 'update') {
      dataObj = {
        values: fieldsValuesTemp,
        labels: fieldsLabelsTemp,
        headerValues: {
          date: eventDate,
          comment: eventComment,
        },
        action: 'update',
        actionContext: {
          context: 'manual_form',
          eventType: props.eventType ? props.eventType : -1,
        },
        pk: props.pk,
        objectType: props.objectType,
        pkObjectType: props.pkObjectType,
        pkReferer: props.pkReferer,
        holder: props.parentId,
        temporalDetailParentId: temporalPkReferer,
        globalModel: props.globalModel,
      };
    }

    if (props.submitExternalCallBack != null) {
      props.submitExternalCallBack(dataObj);
      return;
    }

    setShowSubmit(false);
    if(props.objectType === 'asset' && props.eventType === 1000){
      const boxReceiver = JSON.parse(dataObj.values?._boxtarget_)
      dataObj = {
        ...dataObj,
        pk: null,
        boxSource: props.parentId,
        boxTarget: boxReceiver.id,
        labels: [dataObj.labels],
        values: [{pk: dataObj.pk, _eventDate_: dataObj.values._eventDate_}],
      }

      ApiFetch(
        'objects/add/assets/transfer/massive',
        globalContext,
        true,
        async (res) => {
          if (res.status === 200) {
            let ids = await res.json();
            props.onUpdated(ids);
            if (props.updateFromChildMassive) {
              props.updateFromChildMassive(props.pk);
            }
          }
          setShowSubmit(false);
        },
        (error) => {
          openModalInfo();
          setShowSubmit(true);
        },
        {
          method: 'POST',
          body: JSON.stringify(dataObj),
        }
      );
    } else if (props.mode === 'new_massive') {
      const valuesDuplicated = []
      const labelDuplicated = []

      for (let index = 0; index < quantityAsset; index++) {
        valuesDuplicated.push(dataObj.values)
        labelDuplicated.push(dataObj.labels)
      }

      dataObj = {
        ...dataObj,
        values: valuesDuplicated,
        labels: labelDuplicated
      }

      ApiFetch(
        'objects/add/massive',
        globalContext,
        true,
        async (res) => {
          if (res.status === 200) {
            let ids = await res.json();
            props.onUpdated(ids);
            if (props.updateFromChildMassive) {
              props.updateFromChildMassive(props.pk);
            }
          }
          setShowSubmit(false);
        },
        (error) => {
          openModalInfo();
          setShowSubmit(true);
        },
        {
          method: 'POST',
          body: JSON.stringify(dataObj),
        }
      );
    } else {
      let updateAlotItems = addMassiveItemsCondition({
        objectType: dataObj.objectType,
        action: dataObj.action,
        values: props.assetsInLot?.length
      })

      ApiFetch(
        'objects/add',
        globalContext,
        true,
        !updateAlotItems ?
        async (res) => {
          if (res.status === 200) {
            let ids = await res.json();
            props.onUpdated(ids);
            if (props.updateFromChildMassive) {
              props.updateFromChildMassive(props.pk);
            }
          }
          setShowSubmit(false);
        } : ()=>{},
        !updateAlotItems ? 
        async (error) => {
          try {
            const errorData = await error.json();
            errorData.length > 0 && setListErrors(errorData)
            openModalInfo();
            setShowSubmit(true);
          } catch (err) {
            openModalInfo();
            setShowSubmit(true);
          }
        }: ()=>{},
        {
          method: 'POST',
          body: JSON.stringify(dataObj),
        }
      );

      if(updateAlotItems){
        props.setToastMsg({
          mode: 'update',
          action: 'wait_for_massive'
        })
        props.onUpdated()
      }
    }
  };

  const renderField = (field, xs, fieldContainer, index) => {
    const isDisabled = field.disabled;
    // debugger
    if (field.dataSourceType === 'api' && field.values?.length === 0) {
      if (field.dataSourceApiEndpoint != null) {
        ApiFetch(
          'custom/form/sources/' + field.dataSourceApiEndpoint + '/' + props.pk,
          globalContext,
          true,
          async (body) => {
            let resJson = await body.json();
            field.values = resJson;
          },
          (error) => {
            console.log('error custom/form/sources', error);
          },
          {
            method: 'GET',
          }
        );
      }
    }

    const labelFiltered = modelLabels?.filter(eventLabel => eventLabel.field === field.name)[0]
    const labelField = !!labelFiltered ? labelFiltered.label : field.label

    let label = field.validations?.mandatory
      ? labelField + ' (*)'
      : labelField;

    return (
      <>
        {(!(field.hideOnSBS === props.objectType) && (field.hideCondition == null ||
          !field.hideCondition?.parentValue.includes(
            fieldsValues[field.hideCondition?.parentField] ?? ''
          ))
        ) && (
          <Grid item sx={{ pr: 2, mb: 2 }} xs={xs} key={index}>
            {field.type === 'text' && (
              <TextField
                size="small"
                multiline={field.validations?.multiline ? true : false}
                rows={field.validations?.multiline ? 5 : 1}
                InputLabelProps={
                  props.bindWithDefault || props.bindNullWithDefault || fieldsValues[field.name]
                    ? { shrink: true }
                    : {}
                }
                label={label}
                sx={{ width: '100%', mr: 2 }}
                disabled={permissionsDisabled[field.name] || isDisabled}
                inputProps={{
                  maxLength:
                    field.validations?.maxLength?.value || MAX_STRING_LENGTH,
                }}
                name={field.name}
                value={fieldsValues[field.name]}
                error={!!fieldsError[field.name]?.value}
                onChange={(val) => onChange(field.name, val.target.value)}
                placeholder={`${field.label}`}
              />
            )}

            {field.type === 'autocomplete' && (
              <AutocompleteField
                disabled={permissionsDisabled[field.name]}
                label={label}
                autocompleteRemplaceFields={autocompleteRemplaceFields}
                value={fieldsValues[field.name]}
                id={field.tableModuleFieldId}
                onlyWhenValues={field.onlyWhenValues}
                fieldsValues={fieldsValues}
                globalModel={props.globalModel}
                name={field.name}
                objectType={props.objectType}
                pkObjectType={props.pkObjectType}
              />
            )}

            {field.type === 'phone' && (
              <MuiTelInput
                size="small"
                value={fieldsValues[field.name]}
                onChange={(val) => onChange(field.name, val)}
                name={field.name}
                label={label}
                placeholder={`${field.label}`}
                disabled={permissionsDisabled[field.name] || isDisabled}
              />
            )}
            {field.type === 'multiline' && (
              <TextField
                multiline
                size="small"
                InputLabelProps={
                  props.bindWithDefault || props.bindNullWithDefault
                    ? { shrink: true }
                    : {}
                }
                label={label}
                sx={{ width: '100%', mr: 2 }}
                disabled={permissionsDisabled[field.name]}
                inputProps={{
                  maxLength:
                    field.validations?.maxLength?.value || MAX_STRING_LENGTH,
                }}
                name={field.name}
                value={fieldsValues[field.name]}
                error={!!fieldsError[field.name]?.value}
                onChange={(val) => onChange(field.name, val.target.value)}
                placeholder={`${field.label}`}
              />
            )}
            {field.type === 'number' && (
              <NumberMaskedField
                size="small"
                disabled={permissionsDisabled[field.name]}
                InputLabelProps={
                  props.bindWithDefault || props.bindNullWithDefault
                    ? { shrink: true }
                    : {}
                }
                error={!!fieldsError[field.name]?.value}
                label={label}
                sx={{ width: '100%', mr: 2 }}
                max={field.validations?.max?.value}
                scale={0}
                mask={Number}
                name={field.name}
                onChange={onChange}
                value={fieldsValues[field.name]}
                placeholder={`${field.label}`}
              />
            )}
            {field.type === 'float' && (
              <NumberMaskedField
                size="small"
                disabled={permissionsDisabled[field.name]}
                InputLabelProps={
                  props.bindWithDefault || props.bindNullWithDefault
                    ? { shrink: true }
                    : {}
                }
                error={!!fieldsError[field.name]?.value}
                label={label}
                sx={{ width: '100%', mr: 2 }}
                max={field.validations?.max?.value}
                scale={2}
                thousandsSeparator=""
                padFractionalZeros={false}
                normalizeZeros={true}
                radix="."
                mapToRadix={['.']}
                mask={Number}
                name={field.name}
                onChange={onChange}
                value={fieldsValues[field.name]}
                placeholder={`${field.label}`}
              />
            )}

            {field.type === 'masked' && (
              <MaskedField
                size="small"
                disabled={permissionsDisabled[field.name]}
                InputLabelProps={
                  props.bindWithDefault || props.bindNullWithDefault
                    ? { shrink: true }
                    : {}
                }
                error={!!fieldsError[field.name]?.value}
                label={label}
                sx={{ width: '100%', mr: 2 }}
                mask={field.validations?.masks.value}
                name={field.name}
                onChange={onChange}
                value={fieldsValues[field.name]}
                placeholder={`${field.label}`}
              />
            )}

            {field.type === 'file' && (
              <FileField
                error={!!fieldsError[field.name]?.value}
                label={label}
                name={field.name}
                onChange={onChangeFileUploader}
                value={fieldsValues[field.name]}
                field={field}
              />
            )}

            {(field.type === 'checkbox') && (
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '-10px'}}>
                <CheckBoxField
                  checked={fieldsValues[field.name]}
                  disabled={permissionsDisabled[field.name]}
                  error={!!fieldsError[field.name]?.value}
                  label={label}
                  name={field.name}
                  onChange={onChange}
                />

                <p style={{margin: 0, display: 'inline'}}>
                  {field.aditional ? (
                    <>
                      {label.split(/(\(\*\))/)[0]}
                      <a href={`${field.aditional.page}`} target="_blank" rel="noopener noreferrer">{field.aditional.label}</a>
                      {" " + label.split(/(\(\*\))/)[1]}
                    </>
                  ) : (
                    <>
                      {label}
                    </>
                  )}
                </p>

              </div>
            )}
            {field.type === 'location' && (
              <LocationField
                error={!!fieldsError[field.name]?.value}
                disabled={permissionsDisabled[field.name]}
                label={label}
                helpiLabel={field.helpiLabel}
                name={field.name}
                onChange={onChange}
                value={fieldsValues[field.name]}
                modeSatelite={field.locationSatelite ? field.locationSatelite : false}
                form={props.form}
                xs={xs}
              />
            )}

            {field.type === 'polygon' && (
              <PolygonField
                error={!!fieldsError[field.name]?.value}
                label={label}
                name={field.name}
                onChange={onChange}
                value={fieldsValues[field.name]}
                modeSatelite={field.polygonSatelite ? field.polygonSatelite : false}
                boxLocation={props.boxData?.localizacion && JSON.parse(props.boxData?.localizacion)}
                xs={xs}
              />
            )}

            {(field.type === 'date' || field.type === 'dateTime') && (
              <DateField
                size="small"
                disabled={permissionsDisabled[field.name]}
                InputLabelProps={
                  props.bindWithDefault || props.bindNullWithDefault
                    ? { shrink: true }
                    : {}
                }
                error={!!fieldsError[field.name]?.value}
                label={label}
                name={field.name}
                value={fieldsValues[field.name]}
                onChange={onChangeDate}
                type={field.type}
                locale={locale}
              />
            )}

            {field.type === 'colorx' && (
              <ColorPicker
                defaultValue="#####"
                disabled={permissionsDisabled[field.name]}
                error={!!fieldsError[field.name]?.value}
                name={field.name}
                style={{ fontWeight: 'bold' }}
                placeholder={'' + field.label}
                onChange={(val) => onChange(field.name, val)}
                value={fieldsValues[field.name]}
              />
            )}

            {field.type === 'detail' && (
              <BasicfieldCollection
                mode={props.mode}
                pkObjectType={field.detailModel}
                disabled={permissionsDisabled[field.name]}
                objectType={props.objectType + '_detail'}
                parentType={props.parentType}
                parentTypeSubType={props.parentTypeSubType}
                parentId={props.parentId}
                fields={field.detailFields}
                gridColSize={field.gridColSize}
                form={field.detailForm}
                pluginsContext={{
                  plugins: field.plugins,
                  fieldsValues: fieldsValues,
                  labels: fieldsLabels,
                }}
                labels={field.detailLabels}
                pk={props.pk}
                model={props.pkObjectType}
                onChange={(val) => onChange(field.name, val)}
                globalModel={props.globalModel}
                temporalPkReferer={temporalPkReferer}
                field={field}
              />
            )}

            {field.type === 'select' && (
              <SelectField
                size="small"
                disabled={permissionsDisabled[field.name] || props.mode === 'delete'}
                fieldValues={field.values}
                label={label}
                name={field.name}
                onChange={onChange}
                value={fieldsValues[field.name]}
                valueFk={fieldsValues[field.fkField]}
                fieldFk={field.fkField}
              />
            )}

            {field.type === 'selectimage' && (
              <SelectImageField
                continued={continued}
                fieldValues={field.values}
                filters={props.fieldsCustomFilterRule}
                label={label}
                name={field.name}
                onChange={onChange}
                value={fieldsValues[field.name]}
                objectType={props.objectType}
                setSkipFirstSelectImage={setSkipFirstSelectImage}
                skipFirstSelectImage={skipFirstSelectImage}
              />
            )}

            {field.type === 'multiselect' && (
              <MultiSelectField
                size="small"
                disabled={permissionsDisabled[field.name]}
                label={label}
                name={field.name}
                fieldFk={field.fkField}
                options={field?.values}
                onChange={onChangeMultiselect}
                value={fieldsValues[field.name]}
                valueFk={fieldsValues[field.fkField]}
              />
            )}

            {field.type === 'multiselectbox' && (
              <MultiSelectBoxField
                checkItem={checkItem}
                fieldValues={field?.values}
                label={label}
                name={field.name}
                onChange={onChangeMultiselectBox}
                fieldFk={field.fkField}
                valueFk={fieldsValues[field.fkField]}
                noRowsLabel={field.noRowsLabel}
                fieldsDisabledOnEdit={props.fieldsDisabledOnEdit}
                fieldsLoad={fieldsValues}
              />
            )}

            {field.type === 'groupedmultiselectbox' && (
              <GroupedMultiSelectBoxField
                checkItem={checkItem}
                fieldValues={field?.values}
                label={label}
                name={field.name}
                groupedField = {field.groupedField}
                filters={props.fieldsCustomFilterRule}
                onChange={onChangeMultiselectBox}
                fieldFk={field.fkField}
                valueFk={fieldsValues[field.fkField]}
                noRowsLabel={field.noRowsLabel}
                fieldsDisabledOnEdit={props.fieldsDisabledOnEdit}
                fieldsLoad={fieldsValues}
              />
            )}

            {field.type === 'assetsSelectList' && (
              <div>
                {false && (
                  <Typography
                    variant="h6"
                    component="p"
                    color="text.secondary"
                    className="font-weight-normal mb-4">
                    {label}
                  </Typography>
                )}
                <Grid item xs={12} style={{ marginTop: '-20px;' }}>
                  <ActivosBasicTable
                    lotPk = {props.objectType === 'lot' ? props.pk : 0}
                    preSelectedRows={
                      fieldsValues[field.name] !== ''
                        ? JSON.parse(fieldsValues[field.name])
                        : []
                    }
                    sessionContext={randomKeyContext}
                    onSelectedRowsChange={(val) => {
                      onChangeAssetsSelectList({
                        name: field.name,
                        rows: val.map((id) => {
                          return { id: id };
                        }),
                      });
                    }}
                    exportConfig={{
                      mode: false,
                      caption: 'Seleccionar',
                    }}
                    exportFilterAction={(ret) => {
                      if (true) {
                        onChangeAssetsSelectList({ name: field.name, ...ret });
                      }
                    }}
                  />
                </Grid>
              </div>
            )}

            {field.type === 'summaryAssetsSelectList' && (
              <div>
                <MassiveSummary
                  dataListPK={fieldsValues[field.fkField]}
                  globalModel={props.globalModel}
                  onFinalSubmit={() => {}}
                  amountErrorsInitial={0}/>
              </div>
            )}

            {field.type === 'boxTarget' && (
              <div>
                <BoxTarget
                  mode="dynamicForm"
                  name={field.name}
                  value={fieldsValues[field.name]}
                  assetsList={props.objectType === "asset" ? [fieldsValues.id] : JSON.parse(fieldsValues.assets)}
                  label={label}
                  onBoxSelected={(boxTarget) => {
                    onChange(field.name, boxTarget);
                  }}
                />
              </div>
            )}

            {fieldsError[field.name]?.value && formError && (
              <ErrorField errors={fieldsError[field.name]?.messages} />
            )}
          </Grid>
        )}
      </>
    );
  };

  const StyledAccordionSummary = withStyles({
    root: {
      minHeight: 40,
      maxHeight: 40,

      '&.Mui-expanded': {
        minHeight: 40,
        maxHeight: 40,
      },
    },
    expandIcon: {
      order: -1,
    },
  })(AccordionSummary);

  return (
    <>
      {showForm && (
        <form
          ref={form}
          onClick={() => {
            setReadonly(false);
          }}>
          {listErrors.length > 0 ? (
              <ModalErrorResponse
                open={modalErrorInfo}
                toggleModal={() => {
                  onModalErrorInfoClosed();
                }}
                modalText={t('component.comErrorList')}
                listErrors={listErrors}
              />
            ) : (
              <ModalErrorInfo
                open={modalErrorInfo}
                toggleModal={() => {
                  onModalErrorInfoClosed();
                }}
                modalText={t('component.comError')}
              />
            )
          }

          {props.show &&
            props.formMode === 'massiveGridRow' &&
            props.form == null && (
              <Grid
                container
                wrap="nowrap"
                sx={{ overflow: 'auto' }}
                spacing={1}>
                {props.fields.map(
                  (field, index) =>
                    (field.onlyEvent === undefined || !field.onlyEvent) &&
                    permissionsListDisabled[field.name] === false &&
                    field.massiveImport && (
                      <Grid item style={{ minWidth: '200px' }} key={index}>
                        {!readonly &&
                          renderField(field, 12, props.fieldContainer)}
                        {readonly && (
                          <div
                            style={{ border: '1px solid black !important;' }}>
                            {fieldsValues[field.name]}
                          </div>
                        )}
                      </Grid>
                    )
                )}

                <Grid item style={{ minWidth: '200px' }}>
                  <Button
                    type="button"
                    onClick={submit}
                    sx={{ mr: 1 }}
                    variant="outlined">
                    {submitButtonText}
                  </Button>
                </Grid>
              </Grid>
            )}
          {props.show && props.formMode === 'vertical' && props.form != null && (
            <Grid container spacing={2}>
              {props.autocompleteSandboxMode && (
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <Typography variant="subtitle2" component="p">
                    <center>
                      {t('stepByStep.msg.exampleValues.0')}
                      <br />
                      {t('stepByStep.msg.exampleValues.1')}
                    </center>
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sx={{ mt: 2 }}>
                {initSteps && (
                  <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                      <Step key={index} completed={completed[index]}>
                        <StepButton
                          disabled={props.form.config[index]?.disabled}
                          color="inherit"
                          onClick={handleStep(index)}>
                          {showStepTitle && <div>{label}</div>}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                )}
                <Divider variant="middle" sx={{ mt: 4 }} />
              </Grid>

              {props.form.config.map(
                (step, index) =>
                  index === activeStep &&
                  step.sections.map(
                    (section, index) =>
                      sectionAccordionMode && (
                        <Grid
                          item
                          xs={12}
                          sx={{ mt: 1, boxShadow: 0 }}
                          key={index}>
                          <Accordion
                            style={{
                              marginBottom: '10px',
                              marginRight: '10px',
                            }}
                            sx={{ marginTop: 1 }}
                            defaultExpanded={true}>
                            <StyledAccordionSummary
                              style={{ height: '40px' }}
                              expandIcon={
                                <FontAwesomeIcon icon={faChevronDown} />
                              }>
                              {showSectionTitle && (
                                <Typography
                                  variant="subtitle1"
                                  component="h3"
                                  color="primary">
                                  &nbsp;
                                  {section.title}
                                  &nbsp;
                                </Typography>
                              )}
                            </StyledAccordionSummary>
                            <AccordionDetails>
                              <Grid container>
                                {/* TODO i18n campos */}
                                {props.fields.map(
                                  (field, index) =>
                                    section.fields.includes(field.name) &&
                                    (field.onlyEvent === undefined ||
                                      !field.onlyEvent) &&
                                    permissionsListDisabled[field.name] ===
                                      false &&
                                    renderField(
                                      field,
                                      field.formColSize
                                        ? field.formColSize
                                        : fieldColSize,
                                      props.fieldContainer,
                                      index
                                    )
                                )}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      )
                  )
              )}
              {props.form.config.map(
                (step, index) =>
                  index === activeStep &&
                  step.sections.map(
                    (section) =>
                      sectionAccordionMode === false && (
                        <Grid item xs={12} sx={{ mt: 1 }}>
                          <Grid container>
                            {props.fields.map(
                              (field, index) =>
                                section.fields.includes(field.name) &&
                                //(field.onlyEvent === undefined || !field.onlyEvent) &&
                                permissionsListDisabled[field.name] === false &&
                                renderField(
                                  field,
                                  fieldColSize,
                                  props.fieldContainer,
                                  index
                                )
                            )}
                          </Grid>
                        </Grid>
                      )
                  )
              )}
              <Grid item xs={12} sx={{ mt: 4 }}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  {showCancelButton && (
                    <Button
                      sx={{ mr: 1 }}
                      onClick={() => {
                        props.onCanceled();
                      }}
                      variant="outlined">
                      {cancelButtontext}
                    </Button>
                  )}

                  {activeStep > 0 &&
                    !props.form.config[activeStep - 1].disabled && (
                      <Button
                        sx={{ ml: 1 }}
                        onClick={() => {
                          back();
                        }}
                        variant="outlined">
                        {t('stepByStep.button.back')}
                      </Button>
                    )}
                  {!props.form.stepValidation && (
                    <>
                      {activeStep !== steps.length - 1 && (
                        <Button
                          sx={{ ml: 1 }}
                          onClick={() => {
                            continued();
                          }}
                          variant="contained">
                          {t('stepByStep.button.next')}
                        </Button>
                      )}
                    </>
                  )}
                  {props.form.stepValidation && (
                    <>
                      {activeStep !== steps.length - 1 && (
                        <Button
                          sx={{ ml: 1 }}
                          onClick={() => {
                            continued();
                          }}
                          variant="contained">
                          {t('stepByStep.button.next')}
                        </Button>
                      )}
                    </>
                  )}

                  {(!props.submitOnlyLastStep ||
                    props.submitOnlyLastStep === false ||
                    (props.submitOnlyLastStep &&
                      activeStep === steps.length - 1)) && (
                    <>
                      <Button
                        sx={{ ml: 3, mr: 2 }}
                        disabled={!showSubmit}
                        type="button"
                        onClick={submit}
                        variant={
                          activeStep === steps.length - 1
                            ? 'contained'
                            : 'outlined'
                        }>
                        {props.mode === 'delete'
                          ? deleteButtonText
                          : submitButtonText}
                      </Button>
                    </>
                  )}
                </Stack>
              </Grid>
            </Grid>
          )}
        </form>
      )}
    </>
  );
}

export default BasicFormStepByStep;
