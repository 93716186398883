import React, { useEffect } from 'react';
import { Dialog, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorOutline } from '@material-ui/icons';

function ModalErrorResponse({ open, toggleModal, modalText, listErrors }) {
  useEffect(() => {
  }, [toggleModal]);

  return (
    <Dialog onClose={() => toggleModal(false)} open={open}>
      <IconButton
        aria-label="close"
        onClick={() => toggleModal(false)}
        sx={{
          position: 'absolute',
          right: '8px',
          top: '8px',
        }}>
        <CloseIcon />
      </IconButton>
      <DialogTitle className="text-center mt-4 p-3 pr-0 h-40vh overflow-hidden">
        <Grid container spacing={2} justifyContent="center" alignItems="center" className="p-2 pr-4">
          <ErrorOutline sx={{ color: "#e91e63" }} fontSize='large'/>
          <Typography className='ps-1 fw-bold text-danger'>{modalText} </Typography>
        </Grid>
        <div
          className="d-flex flex-column align-content-start py-2 col-12"
          style={{
            maxHeight:'40vh',
            overflowY: 'auto',
            color:"red",
            borderTop: '2px solid #e9ecef',
            height: 'auto'
          }}
        >
          {listErrors?.map((asset, i) =>
            <>
              <Typography className="m-0 mr-3" variant="subtitle2" sx={{textAlign: 'left'}}>
                {asset.pk}:
              </Typography>
              {asset.errors?.map(error =>
              <>
                <Typography className="m-0 mr-3" variant="caption" sx={{textAlign: 'left', paddingLeft: 2}}>
                  - {error.message}
                </Typography>
              </>
              )}
            </>
          )}
        </div>
      </DialogTitle>
    </Dialog>
  );
}

export default ModalErrorResponse;
