import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import BaseBasicForm from "../../../Layout/containers/BaseBasicForm";
import leftIcon from '../../../Images/stepbystep/lefticons/empresa.png';
import { useTranslation } from 'react-i18next';
import { ApiFetch } from "../../../Utils/ApiFetch";
import useGlobalContext from '../../../Hooks/useGlobalContext';

function ActivateCompany(props) {

  const { t } = useTranslation();

  const globalContext = useGlobalContext();

  const [id, setId] = useState(0);
  const [skipStep, setSkipStep] = useState(false);

  let history = useHistory();
  useEffect(() => {
    const ids = (props.location && props.location.state && props.location.state.ids) || {};
    if (ids.length > 0) {
      setId(ids[0]);
    }
  }, []);

  const handleOnClickEstablecimiento = () => {
    let args = {
      companyId: id,
    };

    history.push({
      pathname: "/signin/box/stepbystep",
      state: args,
    });
  };

  const handleOnClickDashboard = () => {
    ApiFetch(
      'users/markAsDelegated',
      globalContext,
      true,
      async (res) => {
        if(res.status === 200){
          setSkipStep(true);
        }
        else {
          console.log("Delete error: ",res);
        }
      },
      (error) => {
        console.log('error users/markAsDelegated', error);
      },
      {
        method: 'POST'
      }
    );
  };

  useEffect(() => {
    if (skipStep) {
      globalContext.refreshUserData()
        .then(()=> history.push({ pathname: "/assets/" }))
        .catch((e)=> console.log(e));
    }
  }, [skipStep]);

  return (
    <BaseBasicForm text={t("stepByStep.company.titleLeft.complete")} leftIcon={leftIcon} {...props} showOriginoImage="true">
      <FontAwesomeIcon icon={faCheckCircle} size="3x" color="#3969E8" />
      <Typography variant="h3" component="h4" color="text.primary" align="center" className="mt-2 mb-4">
        {t("stepByStep.company.activateTitle")}
      </Typography>
      <Typography variant="subtitle1" component="h4" color="text.secondary" align="center" className="mb-4">
        {t("stepByStep.company.activateSubtitle.0")}
        <br />
        {t("stepByStep.company.activateSubtitle.1")}
        <br />
        {t("stepByStep.company.activateSubtitle.2")}
      </Typography>
      <Button variant="contained" className="mt-4" onClick={handleOnClickEstablecimiento}>
        {t("stepByStep.button.createFacility")}
      </Button>
      <Button variant="outlined" className="mt-4" onClick={handleOnClickDashboard}>
        {t("stepByStep.button.doItLater")}
      </Button>
    </BaseBasicForm>
  );
}

export default ActivateCompany;
