import React, { useState, useEffect } from "react";
import {
  Stack,
  Grid,
  Card,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Menu,
  Divider,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Radio,
  RadioGroup,
  TextField,
  InputAdornment,
  Slider,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faFileImport, faTrash, faMinusCircle, faEllipsisV, faSearch } from "@fortawesome/pro-light-svg-icons";
import "./styles.css";
import { useTranslation } from 'react-i18next';

import { useHistory } from "react-router-dom";
import BasicGridData from "../../../Components/Generic/basicGridData";
import useGlobalContext from "../../../Hooks/useGlobalContext";
import { ApiFetch } from "../../../Utils/ApiFetch";
import BasicForm from "../../../Components/Generic/basicForm";
import Modal from "@material-ui/core/Modal";
import { BOXES_EDIT } from "../../../constants/paths";

export default function DataTable(props) {
  const { t } = useTranslation()
  let history = useHistory();
  const globalContext = useGlobalContext();
  const { userData } = useGlobalContext();
  const boxContext = userData.userData.userData.box_context;
  const [parentType] = useState(boxContext.parentType);
  const [parentTypeSubType] = useState(boxContext.parentTypeSubType);
  const [globalModel] = useState(boxContext.model);
  const [objectTypeSubType] = useState(boxContext.objectTypeSubType);
  const [parentId] = useState(boxContext.parentId);
  const [showNewAssetModal, setShowNewAssetModal] = useState(false);
  const [menuActionSelectedRow, setMenuActionSelectedRow] = useState(null);

  const [rows, setRows] = useState([]);
  const [fields] = useState([]);
  const [events] = useState([]);
  const [form] = useState(null);

  useEffect(() => {
    getRows();
  }, [events, fields]);

  const [selectedItems] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState("");
  const [expandedFilters, setExpandedFilters] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleChange = (event) => {
    setSelectedFilter(event.target.value);
  };
  const rowMenuActionshandleClick = (event, row) => {
    setMenuActionSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };
  const rowMenuActionshandleClose = () => {
    setAnchorEl(null);
  };
  const toggleChecked = () => setExpandedFilters((value) => !value);

  const moreActionsButton = (row) => {
    return (
      <span id="basic-button" aria-controls="basic-menu" aria-label="More" onClick={(event) => rowMenuActionshandleClick(event, row)}>
        <FontAwesomeIcon style={{ fontSize: "25px" }} icon={faEllipsisV} />
      </span>
    );
  };

  const getRows = () => {
    const objectType = "box";
    const objectTypeSubType = boxContext.objectTypeSubType;
    const model = boxContext.model;
    const parentId = boxContext.parentId;

    ApiFetch(
      "objects/get/all/" + model + "/" + objectType + "/" + objectTypeSubType + "/" + parentId + "/0",
      globalContext,
      true,
      async (body) => {
        let resJson = await body.json();
        setRows(resJson);
      },
      (error) => {
        console.log("error objects/get/all", error);
      }
    );
  };

  const updateMenuActionSelectedRow = () => {
    let args = {
      pk: menuActionSelectedRow.id,
      mode: "update",
      objectTypeSubType: boxContext.objectTypeSubType,
      globalModel: boxContext.model,
      parentType: boxContext.parentType,
      parentTypeSubType: boxContext.parentTypeSubType,
      parentId: boxContext.parentId,
    };

    history.push({
      pathname: BOXES_EDIT,
      state: { refererContext: args },
    });
  };

  return (
    <div>
      <Grid component={Card} container sx={{ p: 2, mb: 4, mt: 2, alignItems: "center" }}>
        <Grid item component={FormControl} sx={{ pr: 2 }} xs={3}>
          <InputLabel id="test">Raza</InputLabel>
          <Select labelId="test" value={selectedFilter} label="Filtro" onChange={handleChange} sx={{ width: "100%" }}>
            <MenuItem value={10}>Bovina</MenuItem>
            <MenuItem value={20}>Angus</MenuItem>
            <MenuItem value={30}>Wagyu</MenuItem>
          </Select>
        </Grid>
        <Grid item component={FormControl} sx={{ pr: 2 }} xs={3}>
          <InputLabel id="test">Sexo</InputLabel>
          <Select labelId="test" value={selectedFilter} label="Filtro" sx={{ width: "100%" }}>
            <MenuItem value={10}>Macho</MenuItem>
            <MenuItem value={20}>Hembra</MenuItem>
            <MenuItem value={30}>NSNC</MenuItem>
          </Select>
        </Grid>
        <Grid item component={FormControl} sx={{ pr: 2 }} xs={3}>
          <InputLabel id="test">Categoria</InputLabel>
          <Select labelId="test" value={selectedFilter} label="Filtro" sx={{ width: "100%" }}>
            <MenuItem value={10}>Ternero</MenuItem>
            <MenuItem value={20}>Categoria 1</MenuItem>
            <MenuItem value={30}>Category 2</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="text" onClick={toggleChecked}>
            {!expandedFilters ? "Ver mas filtros" : "Cerrar filtros"}
          </Button>
        </Grid>
        {expandedFilters && (
          <span>
            <Grid item component={FormControl} sx={{ pr: 2, mt: 3, alignItems: "center" }} xs={12} xl={6}>
              <FormControlLabel control={<Checkbox checked={true} />} label="Checkbox" />
              <FormControlLabel control={<Checkbox checked={false} />} label="Checkbox" />

              <RadioGroup row defaultValue="b" name="radio-buttons-group">
                <FormControlLabel value="a" control={<Radio />} label="Radio 1" />
                <FormControlLabel value="b" control={<Radio />} label="Radio 2" />
              </RadioGroup>
            </Grid>
            <Grid item component={FormControl} sx={{ pr: 2, mt: 3, alignItems: "center" }} xs={12} xl={6}>
              <TextField id="outlined-search" label="Buscar por nombre" type="search" sx={{ mr: 2 }} />
              <TextField
                id="filled-search"
                label="Buscar por nombre"
                type="search"
                sx={{ mr: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faSearch} />
                    </InputAdornment>
                  ),
                }}
              />
              <Slider defaultValue={30} aria-label="Disabled slider" sx={{ width: "200px" }} />
            </Grid>
          </span>
        )}
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Chip
            label="Filter applied"
            color="secondary"
            sx={{ mr: 1 }}
          />
          <Chip
            label="Filter applied"
            color="secondary"
            sx={{ mr: 1 }}
          />
          <Chip
            label="Filter applied"
            color="secondary"
            sx={{ mr: 1 }}
          />
        </Grid>
      </Grid>
      {/* Fin Filtros */}

      <Stack direction="row" justifyContent="flex-start" alignItems="stretch" className={`${selectedItems.length > 0 ? "show" : "hide"}`}>
        <Button sx={{ mr: 2, mb: 4 }} variant="outlined" color="primary" size="small" startIcon={<FontAwesomeIcon icon={faPen} />}>
          EDITAR
        </Button>
        <Button sx={{ mr: 2, mb: 4 }} variant="outlined" color="primary" size="small" startIcon={<FontAwesomeIcon icon={faFileImport} />}>
          TRANSFERIR
        </Button>
        <Button sx={{ mr: 2, mb: 4 }} variant="outlined" color="primary" size="small" startIcon={<FontAwesomeIcon icon={faTrash} />}>
          {t("stepByStep.button.delete")}
        </Button>
      </Stack>
      {/*<Button onClick={()=>{setShowNewAssetModal(true)}}>Nuevo</Button>*/}
      <BasicGridData
        multiSelectMode={true}
        onMultipleSelected={(evt) => {}}
        parentType={parentType}
        parentTypeSubType={parentTypeSubType}
        actions={moreActionsButton}
        show={true}
        onSelected={() => {}}
        rows={rows}
        fields={fields}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={rowMenuActionshandleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            updateMenuActionSelectedRow();
            rowMenuActionshandleClose();
          }}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={faMinusCircle} />
          </ListItemIcon>
          <ListItemText>{ t("component.popoverOption.edit") }</ListItemText>
        </MenuItem>
        <Divider sx={{ mt: "0!important", mb: "0!important" }} />
        {events.map(
          (event) =>
            event.permissions.company.includes(parseInt(parentTypeSubType)) === true && (
              <div>
                <MenuItem
                  onClick={() => {
                    //openEventForm(event.name,event.showDefault);
                    rowMenuActionshandleClose();
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faMinusCircle} />
                  </ListItemIcon>
                  <ListItemText>
                  { t("component.popoverOption.event") } <b>{event.name}</b>
                  </ListItemText>
                </MenuItem>
                <Divider sx={{ mt: "0!important", mb: "0!important" }} />
              </div>
            )
        )}

        <MenuItem
          onClick={() => {
            //reportItem();
            rowMenuActionshandleClose();
          }}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={faMinusCircle} />
          </ListItemIcon>
          <ListItemText>Reporte de Eventos</ListItemText>
        </MenuItem>
      </Menu>

      <Modal
        open={showNewAssetModal}
        //onClose={rowMenuActionshandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <BasicForm
          formMode={"vertical"}
          saveFunc={() => {}}
          show={showNewAssetModal}
          onCanceled={() => {
            setShowNewAssetModal(false);
            getRows();
          }}
          onUpdated={() => {
            setShowNewAssetModal(false);
            getRows();
          }}
          withDefault={false}
          mode={"new"}
          pk={null}
          pkObjectType={objectTypeSubType}
          pkReferer={null}
          globalModel={globalModel}
          objectType={"asset"}
          objectTypeBindForm={"asset"}
          parentType={parentType}
          parentTypeSubType={parentTypeSubType}
          parentId={parentId}
          count={0}
          fields={fields}
          form={form}
        />
      </Modal>
    </div>
  );
}
