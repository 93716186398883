import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Card,
  Typography,
  Divider,
  Link,
} from '@mui/material';
import Icon from '@mui/material/Icon';

import { EVENTS_MASSIVE_MANUAL } from "../../constants/paths";
import { useTranslation } from 'react-i18next'
import useGlobalContext from '../../Hooks/useGlobalContext';

function LoadFile(props) {
  const { t } = useTranslation();
  let history = useHistory();
  const { userData, selectedAsset, selectedBox } = useGlobalContext();

  const filterAssets = () => {
    history.push({
      pathname: EVENTS_MASSIVE_MANUAL,
      state: { refererContext: { from: 'file_upload' } },
    });
  };

  return (
    <>
      {(userData?.userData?.permissions?.boxes[selectedBox.id + "-" + selectedAsset.id]?.includes("ASSET_EDIT") === true) &&
        <Grid container spacing={2}>
          <Grid item xs={6} xl={3} className="d-flex">
            <Card className="d-flex flex-column flex p-3">
              <Typography variant="h6" component="h4" color="text.light">
                {t('stepByStep.massive.step1.filterFile.0')}
                <br />
                {t('stepByStep.massive.step1.filterFile.1')}
              </Typography>
              <Divider variant="middle" className="mr-0 ml-0 mt-2 mb-2" />
              <Typography
                variant="caption"
                component="h6"
                color="text.light"
                className="mb-2">
                {t('stepByStep.massive.step1.filterFileSubtitle')}
              </Typography>

              <Link
                component="button"
                onClick={() => {
                  filterAssets();
                }}>
                <Icon sx={{ fontSize: 90 }} className="align-self-center">
                  add_circle
                </Icon>
              </Link>
            </Card>
          </Grid>
        </Grid>
      }
      {!(userData?.userData?.permissions?.boxes[selectedBox.id + "-" + selectedAsset.id]?.includes("ASSET_EDIT") === true) &&
        <Grid container spacing={2}>
          <Grid item xs={12} className="mt-3">
            <h5>{t("breadcrumb.errorMsg.notAllowed")}</h5>
          </Grid>
        </Grid>
      }
    </>
  );
}

export default LoadFile;
