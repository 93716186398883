import * as L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-geosearch/dist/geosearch.css';
import 'leaflet/dist/leaflet.css';
import './BasicDrawer.css';
import { React, useEffect, useMemo, useRef, useState } from 'react';
import {
  MapContainer,
  Marker,
  TileLayer,
  useMapEvents,
} from 'react-leaflet';

import {
  ATTRIBUTION,
  ATTRIBUTION_STADIA,
  DEFAULT_CENTER,
  DEFAULT_POINT,
  DEFAULT_ZOOM,
  ICON,
  TOOLBAR_CONFIG,
} from '../../constants';

import { OpenStreetMapProvider } from 'react-leaflet-geosearch';
import SearchControl from './SearchControl';
import { useTranslation } from 'react-i18next';

function DraggableMarker({ center, updateLocation }) {
  const [draggable] = useState(true);
  const [position, setPosition] = useState(null);
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          const currentGeoJson = marker.toGeoJSON();
          setPosition(marker.getLatLng());
          updateLocation(currentGeoJson);
        }
      },
    }),
    []
  );

  useEffect(() => {
    setPosition(center);
  }, [center]);

  return (
    <>
      {position && (
        <Marker
          icon={ICON}
          draggable={draggable}
          eventHandlers={eventHandlers}
          position={position}
          ref={markerRef}
        />
      )}
    </>
  );
}

function BasicMarker({
  geoJson,
  markerVisible,
  updateLocation,
  onChangeCenter,
  modeSatelite
}) {
  const [center, setCenter] = useState(null);
  const [mapCenter, setMapCenter] = useState(DEFAULT_CENTER);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [map, setmap] = useState(null);

  const { t } = useTranslation();

  const localeToolBarConfig = TOOLBAR_CONFIG(t);

  L.drawLocal = localeToolBarConfig;

  const updateStates = (geo) => {
    const [lng, lat] = geo?.geometry?.coordinates;
    const currentLocation = { lat: parseFloat(lat), lng: parseFloat(lng) };
    const currentZoom = geo.properties?.zoom;
    setCenter({ ...currentLocation });
    setMapCenter({ ...currentLocation });
    setZoom(currentZoom);
  };

  useEffect(() => {
    if (markerVisible) {
      if (geoJson && geoJson !== '') {
        updateStates(geoJson);
      } else {
        updateStates(DEFAULT_POINT);
      }
    }
  }, [geoJson, markerVisible]);

  useEffect(() => {

    if (map && Number(mapCenter.lat)===mapCenter.lat && Number(mapCenter.lng)===mapCenter.lng) {
      map.flyTo(mapCenter);
      if (onChangeCenter) {
        onChangeCenter({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [mapCenter.lng, mapCenter.lat],
          },
          properties: { center: { lat: mapCenter.lat, lng: mapCenter.lng } },
        });
      }
    }
  }, [mapCenter]);

  const prov = OpenStreetMapProvider();

  function MapHandler() {
    useMapEvents({
      dragend: (e) => {
        setMapCenter(e.target.getCenter());
      },
      zoomend: (e) => {
        setMapCenter(e.target.getCenter());
      },
    });
    return null;
  }

  return (
    <>
      <MapContainer
        style={{ height: '50vh', width: '15wh' }}
        center={mapCenter}
        zoom={zoom}
        whenCreated={setmap}>
        <MapHandler />
        <TileLayer
          attribution={ATTRIBUTION}
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <SearchControl
          provider={prov}
          showMarker={false}
          showPopup={true}
          popupFormat={({ query, result }) => result.label}
          maxMarkers={3}
          retainZoomLevel={false}
          animateZoom={true}
          autoClose={false}
          searchLabel={'Ingresá la ciudad mas cercana'}
          keepResult={true}
        />

        {modeSatelite &&
          <>
            <TileLayer
              attribution={ATTRIBUTION}
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />
            <TileLayer
              attribution={ATTRIBUTION_STADIA}
              url="https://tiles.stadiamaps.com/tiles/stamen_toner_labels/{z}/{x}/{y}{r}.{ext}"
              subdomains={'abcd'}
              minZoom={0}
              maxZoom={20}
              ext={'png'}
            />
          </>
        }

        {markerVisible && (
          <DraggableMarker updateLocation={updateLocation} center={center} />
        )}
      </MapContainer>
    </>
  );
}

export default BasicMarker;
