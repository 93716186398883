import { Box, Button, Typography } from '@mui/material'
import React from 'react'

export const TabStatistics = (props) => {
    const {selectedTab, setSelectedTab} = props
    
    const handleTabClick = (tabName) => {
        setSelectedTab(tabName);
      };

    const tabStyles = (tabId) => ({
        background: selectedTab === tabId ? '#56A664' : '#76CC81',
        height:40,
        borderLeft:2,
        borderRight:2,
        borderBottom:2,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius:10,
        borderBottomRightRadius:10,
        textAlign:'center',
        color: 'white',
        marginX:1,
        ":hover":{
            background: '#56A664'
        }
    });

    return (
        <Box 
            sx={{
                display:'flex', 
                flexDirection:'row', 
                color: '#888888',
                borderTop: 2,
                marginBottom: 2,
                borderColor: '#76CC81',
            }}
        >
            <Button
                sx={tabStyles(1)}
                onClick={() => handleTabClick(1)}
            >
                Por Fecha
            </Button>
            <Button
                sx={tabStyles(2)}
                onClick={() => handleTabClick(2)}
            >
                <Typography>Top 5 Country</Typography>
            </Button>
            <Button
                sx={tabStyles(3)}
                onClick={() => handleTabClick(3)}
            >
                <Typography>Top 5 Devices</Typography> 
            </Button>
        </Box>
    )
}
