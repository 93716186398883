import React, { useEffect, useState } from 'react';
import { Button, Grid, Stack } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import BasicFormStepByStep from '../../Components/Generic/basicFormStepByStep';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { ASSETS } from '../../constants/paths';
import { useTranslation } from 'react-i18next';
import ModalErrorInfo from '../../Components/Feature/ModalErrorInfo';
import { ACTIVE_MODEL } from '../../constants';

//const steps = ['ID de caravana', 'Detalles del activo', 'Datos genealogicos', 'Fotos y videos'];
// ref dg: se inicializa formulario de alta / edición, y recibe desde la pagina anterior parametros (location)
function CargaActivos() {
  // CV
  const { t } = useTranslation();
  let history = useHistory();
  const location = useLocation();
  const globalContext = useGlobalContext();
  const {
    userData,
    selectedAsset,
    selectedBox,
    calculateActualQuota,
    selectedCompany,
  } = useGlobalContext();
  const [fields, setFields] = useState([]);
  const [form, setForm] = useState(null);
  const [modalInfo, setModalInfo] = useState({
    open: false,
    modalText: '',
  });

  useEffect(() => {
    const loadQuotaModal = async () => {
      const { remainingQuota } = await calculateActualQuota({
        id: selectedCompany.id,
        type: 'asset',
      });
      if (remainingQuota <= 0 && location.state.refererContext.mode === 'new') {
        setModalInfo((current) => ({
          ...current,
          open: true,
          modalText: t('quotaExceeded', { type: t('token') }),
        }));
      }
    };
    loadQuotaModal();
  }, []);

  useEffect(() => {
    if(!location.state){
      redirectOnError()
    } else if (selectedAsset.id !== -1) {
      let models = globalContext.getSessionDataByKey('models');
      let model = selectedAsset.id;

      let form = {}
      if(location.state.refererContext.mode === "new_massive"){
        const formTempCopy = JSON.parse(JSON.stringify(models[model].form))
        formTempCopy.config.push(...models[model].alterAddMassive.form.config)
        form = formTempCopy
      } else {
        form = models[model].form
      }

      let attributesInForm = []
      for(const step of form.config){
        for(const section of step.sections){
          for(const field of section.fields){
            const attribute = models[selectedAsset.id].attributes.filter((atrr) => atrr.name === field).map(attr => ({ ...attr }));
            attribute && attributesInForm.push(...attribute)
          }
        }
      }

      // override user permissions
      let attributesTemp = [];
      for (let attributeTemp of attributesInForm) {
        let attributeTempCopy = { ...attributeTemp };

        if (
          userData?.userData?.permissions?.models[model]?.boxs[selectedBox.id]
            ?.fields[attributeTemp.name]
        ) {
          attributeTempCopy.permissions =
            userData?.userData?.permissions?.models[model]?.boxs[
              selectedBox.id
            ]?.fields[attributeTemp.name];
        }
        attributesTemp.push(attributeTempCopy);
      }
      //BLOQUEAMOS EL SELECT DE ESPECIES (TEPORAL)
      if (model === ACTIVE_MODEL.vacaPn || model === ACTIVE_MODEL.vacaAr) {
        const specieIndex = attributesTemp.findIndex(atribute => atribute.name === 'specie')
        if (specieIndex !== -1) attributesTemp[specieIndex].permissions.new.box = []
      }
      //SI ES VACAPN SACAMOS LA VALIDACION DEL uniqueid
      if (model === ACTIVE_MODEL.vacaPn) {
        const uniqueidIndex = attributesTemp.findIndex(atribute => atribute.name === 'uniqueid')
        if (uniqueidIndex !== -1) attributesTemp[uniqueidIndex].type = 'text'
      }

      setFields([...attributesTemp, ...(location.state.refererContext.mode === "new_massive" ? models[model].alterAddMassive.attributes: [])]);
      setForm(form)
      return models;
    }
  }, [selectedAsset]);

  const redirectToAssetList = (action) => {
    history.push({
      pathname: ASSETS,
      state: {
        toast: {
          mode: location.state.refererContext.mode,
          action: action,
        },
      },
    });
  };

  const redirectOnError = () => {
    history.push({
      pathname: ASSETS,
      state: {},
    });
  };

  return (
    //Starts the route view with <Grid container> and add <Grid item 12> for rows.
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          {(process.env.REACT_APP_HIDE_NOT_IMPLEMENTED === 'true'
            ? false
            : true) && (
              <Button sx={{ ml: 2 }} variant="outlined">
                {t('breadcrumb.button.leaveOut')}
              </Button>
            )}
        </Stack>
      </Grid>

      {fields.length > 0 && location.state?.refererContext && (
        <>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <BasicFormStepByStep
              formMode={'vertical'}
              saveFunc={() => { }}
              show={true}
              sectionAccordionMode={true}
              onCanceled={() => {
                redirectToAssetList('canceled');
              }}
              onUpdated={() => {
                redirectToAssetList('updated');
              }}
              withDefault={false}
              bindWithDefault={true}
              mode={location.state.refererContext.mode}
              pk={location.state.refererContext.pk}
              //pkObjectType={location.state.refererContext.objectTypeSubType}
              pkObjectType={selectedAsset.id}
              pkReferer={null}
              //globalModel={location.state.refererContext.globalModel}
              globalModel={selectedAsset.id}
              objectType={'asset'}
              objectTypeBindForm={'asset'}
              parentType={location.state.refererContext.parentType}
              parentTypeSubType={
                location.state.refererContext.parentTypeSubType
              }
              parentId={location.state.refererContext.parentId}
              count={0}
              fields={fields}
              showCancelButton={true}
              cancelButtontext={t('stepByStep.button.cancel')}
              form={form}
            />
          </Grid>

          <ModalErrorInfo
            open={modalInfo.open}
            modalText={modalInfo.modalText}
            toggleModal={redirectOnError}
          />
        </>
      )}
    </Grid>
  );
}

export default CargaActivos;
