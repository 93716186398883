import instance from '../Config/axios';

export const getLotStatus = async (lotId) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `/lots/status/${lotId}`;

  return await instance.get(url, config);
};

export const getAssetsLotData = async (lotId) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `/lots/get/assets/${lotId}`;

  return await instance.get(url, config);
};