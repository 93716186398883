import * as L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-geosearch/dist/geosearch.css';
import './BasicDrawer.css';
import 'leaflet/dist/leaflet.css';

import { useEffect, useState } from 'react';
import {
  FeatureGroup,
  MapContainer,
  TileLayer,
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

import { OpenStreetMapProvider } from 'react-leaflet-geosearch';
import SearchControl from './SearchControl';
import { useLeafletContext } from '@react-leaflet/core';

import {
  ATTRIBUTION,
  ATTRIBUTION_STADIA,
  DEFAULT_CENTER,
  DEFAULT_ZOOM,
  EDITCONTROLDRAWOPTIONS,
  TOOLBAR_CONFIG,
} from '../../constants';
import { useTranslation } from 'react-i18next';

const FigureMarker = ({ geoJson }) => {
  const context = useLeafletContext();

  const figureType = geoJson?.geometry?.type;
  const radius = geoJson?.properties?.radius ?? 1;
  const coordinates =
    getCordinates(figureType, geoJson?.geometry?.coordinates) ?? [];

  const FIGURES = {
    Polygon: ({ coordinates }) =>
      new L.Polygon(coordinates, { color: 'yellow' }),
    Point: ({ coordinates, radius }) => L.Circle(coordinates, radius),
    Rectangle: ({ coordinates }) => new L.Rectangle(coordinates),
    Polyline: ({ coordinates }) => new L.Polyline(coordinates),
  };

  function getCordinates(type, coordinates) {
    if (type === 'Polygon') {
      return coordinates?.map((firstArray) =>
        firstArray.map(([lat, long]) => [long, lat])
      );
    } else {
      return coordinates?.length > 0 ? [coordinates[1], coordinates[0]] : [];
    }
  }

  useEffect(() => {
    const currentFigure = FIGURES[figureType];
    if (currentFigure != null) {
      const current = currentFigure({ coordinates, radius });
      const container = context.layerContainer || context.map;
      container.addLayer(current);
    }
  }, []);

  return null;
};

function BasicDrawer({ geoJson, name, boxLocation, modeSatelite, updateLocation }) {
  const [map, setmap] = useState(null);
  const [center, setCenter] = useState(
    (boxLocation && {lat: boxLocation.geometry.coordinates[1], lng: boxLocation.geometry.coordinates[0]}) ??
    geoJson?.properties?.center ?? DEFAULT_CENTER
  );
  const [zoom, setZoom] = useState(boxLocation ? 14 : geoJson?.properties?.zoom ?? DEFAULT_ZOOM);

  const { t } = useTranslation();

  const localeToolBarConfig = TOOLBAR_CONFIG(t);

  L.drawLocal = localeToolBarConfig;

  useEffect(() => {
    if (geoJson?.properties?.center) {
      if (map) {
        map.flyTo(geoJson.properties.center);
        let coordinates = geoJson.geometry.coordinates[0];

        let minLat = 9999999;
        let minLong = 9999999;
        let maxLat = -9999999;
        let maxLong = -9999999;

        for (let point of coordinates) {
          minLat = point[1] < minLat ? point[1] : minLat;
          minLong = point[0] < minLong ? point[0] : minLong;
          maxLong = point[0] > maxLong ? point[0] : maxLong;
          maxLat = point[1] > maxLat ? point[1] : maxLat;
        }

        map.fitBounds([
          [minLat, maxLong],
          [maxLat, minLong],
        ]);
      }
      setCenter(geoJson.properties.center);
    }
  }, [geoJson, map]);

  function handleCreate({ layer, target }) {
    const layerGeojson = layer.toGeoJSON();
    const locatedMap = target.locate();
    layerGeojson.properties.zoom = locatedMap._zoom;
    layerGeojson.properties.center = locatedMap._lastCenter;

    setZoom(layerGeojson.properties.zoom);
    setCenter(layerGeojson.properties.center);

    if (layer instanceof L.Circle) {
      layerGeojson.properties.radius = layer.getRadius();
    }

    updateLocation(layerGeojson);
  }

  function handleEdit({ layers, target }) {
    layers.eachLayer((layer) => {
      const layerGeojson = layer.toGeoJSON();
      if (layer instanceof L.Circle) {
        layerGeojson.properties.radius = layer.getRadius();
      }
      const locatedMap = target.locate();
      layerGeojson.properties.zoom = locatedMap._zoom;
      layerGeojson.properties.center = locatedMap._lastCenter;
      updateLocation(layerGeojson);
    });
  }

  function handleDelete({ layers }) {
    layers.eachLayer((layer) => {
      updateLocation(null);
    });
  }

  const prov = OpenStreetMapProvider();

  return (
    <>
      <MapContainer
        style={{ height: '50vh', width: '15wh' }}
        zoom={zoom}
        center={center}
        whenCreated={setmap}
      >
        <SearchControl
          provider={prov}
          showMarker={false}
          showPopup={true}
          popupFormat={({ query, result }) => result.label}
          maxMarkers={3}
          retainZoomLevel={false}
          animateZoom={true}
          autoClose={false}
          searchLabel={'Ingresá la ciudad mas cercana'}
          keepResult={true}
        />

        <TileLayer
          attribution={ATTRIBUTION}
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        />

        <TileLayer
          attribution={ATTRIBUTION_STADIA}
          url="https://tiles.stadiamaps.com/tiles/stamen_toner_labels/{z}/{x}/{y}{r}.{ext}"
          subdomains={'abcd'}
          minZoom={0}
          maxZoom={20}
          ext={'png'}
        />

        {!modeSatelite &&
          <TileLayer
            attribution={ATTRIBUTION_STADIA}
            url="https://tiles.stadiamaps.com/tiles/stamen_terrain_background/{z}/{x}/{y}{r}.{ext}"
            subdomains={'abcd'}
            minZoom={0}
            maxZoom={20}
            ext={'png'}
          />
        }

        <FeatureGroup id="features">
          <EditControl
            position="topright"
            onCreated={handleCreate}
            onEdited={handleEdit}
            onDeleted={handleDelete}
            draw={EDITCONTROLDRAWOPTIONS}
          />
          <FigureMarker geoJson={geoJson} />
        </FeatureGroup>
      </MapContainer>
    </>
  );
}

export default BasicDrawer;
