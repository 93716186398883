import {
  faDownload,
  faInfoCircle,
  faSearch,
  faTag,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  InputAdornment,
  Link,
  Popover,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { ApiFetch } from '../../Utils/ApiFetch';

import FileUploader from '../../Components/FileUploader/FileUploader';
import { ASSETS, TRANSFER_ASSETS_FILTER } from '../../constants/paths';
import useGlobalContext from '../../Hooks/useGlobalContext';
import ActivosBasicTable from '../../Layout/components/Activos/ActivosBasicTable';

import { StyledImageSeparator } from './TransferBoxTheme';
import { useTranslation } from 'react-i18next';

const VALID_FORMATS = ['.csv', '.xlsx', '.xls', '.ods'];

const STEPS = [
  'Seleccionar Destino',
  'Elegir Modo de Operación',
  'Seleccionar Activos',
  'Resumen y Confirmación',
];

function TransferBox(props) {
  const { t } = useTranslation();
  const globalContext = useGlobalContext();
  const { selectedBox } = useGlobalContext();
  let history = useHistory();
  const [activeStep, setActiveStep] = useState(-1);
  const [completed] = useState({});
  // For Modal
  const [dialogOpen, setdialogOpen] = React.useState(false);

  const [textProp1, setTextProp1] = useState('');
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const getUploadProgress = (progressInfo) => {
    setUploadInProgress(progressInfo?.status.loading);
    if (uploadInProgress) {
      setTextProp1('');
    }
  };

  const handleOnChange = async (statusInfo) => {
    if (statusInfo.reset) {
      setTextProp1('');
    }
    if (statusInfo.success) {
      const currentVal = statusInfo?.uploadedFileInfo[0];
      if (currentVal) {
        props.onUploadFile(currentVal);
      }
    }
  };

  const handleClose = (value) => {
    setdialogOpen(false);
  };

  return (
    //Starts the route view with <Grid container> and add <Grid item 12> for rows.
    <Grid container spacing={2}>
      <Grid item xs={12} className="mt-2">
        <Stepper nonLinear activeStep={activeStep}>
          {STEPS.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton
                color="inherit"
                onClick={() => {
                  setActiveStep(index);
                }}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Grid>
      {/*<Divider variant="middle" className="mt-4" style={{ marginBottom: '30px' }} />*/}
      {activeStep === 0 && (
        <>
          <Grid item xs={4} className="mt-3">
            <Typography variant="subtitle1" component="p">
              Complete el código RENSPA o ALIAS de Orgino
            </Typography>
            <div className="d-flex align-center mt-2">
              <TextField
                id="filled-search"
                type="search"
                className="mr-2"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faSearch} color="#61B15A" />
                    </InputAdornment>
                  ),
                }}
              />
              <Button variant="contained">VALIDAR</Button>
            </div>
          </Grid>

          <Grid item xs={12} className="mt-3">
            <Grid component={Card} container>
              <Grid item xs={5} className="p-4">
                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      component="p"
                      color="text.primary"
                      className="mb-2">
                      CUIG
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      color="text.primary"
                      className="mb-2">
                      Titular
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      color="text.primary"
                      className="mb-2">
                      CUIT
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      color="text.primary">
                      RENSPA
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" component="p" className="mb-2">
                      PS144 CV 273
                    </Typography>
                    <Typography variant="body1" component="p" className="mb-2">
                      LOPEZ GUILLERMO
                    </Typography>
                    <Typography variant="body1" component="p" className="mb-2">
                      29-23242923-9
                    </Typography>
                    <Typography variant="body1" component="p">
                      01.81230.1237123
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <StyledImageSeparator item xs={2} className="p-0" />
              <Grid item xs={5} className="p-4">
                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      component="p"
                      color="text.primary"
                      className="mb-2">
                      Box
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      color="text.primary"
                      className="mb-2">
                      Partido
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      color="text.primary"
                      className="mb-2">
                      Email
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      color="text.primary">
                      Teléfono
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" component="p" className="mb-2">
                      Las Marias
                    </Typography>
                    <Typography variant="body1" component="p" className="mb-2">
                      Villa Villa, Villa Villa
                    </Typography>
                    <Typography variant="body1" component="p" className="mb-2">
                      email@domain.com
                    </Typography>
                    <Typography variant="body1" component="p">
                      (820) 865 - 8239
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {activeStep === 1 && (
        <Grid item xs={12} className="mt-3">
          <Typography variant="subtitle1" component="p">
            Tipo de transferencia
          </Typography>
          <Typography variant="body2" color="text.primary" component="p">
            Seleccioná como queres transferir los siguientes activos.
          </Typography>
          <div className="d-flex align-center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={true}
                />
              }
              label="Titularidad"
              className="mt-1"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={false}
                />
              }
              label="Posesión"
              className="mt-1"
            />
          </div>
        </Grid>
      )}
      {activeStep === 2 && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} className="mt-3">
              <span className="d-flex flex-row align-center mb-2">
                <Typography variant="body2" component="h3" color="text.primary">
                  {t('stepByStep.massive.step1.downloadFile')}
                </Typography>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="ml-1"
                  color="#61B15A"
                />
                <Popover
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}>
                  <Card className="p-2 d-flex flex-column">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="mb-1"
                      color="#61B15A"
                    />
                    <Typography variant="body2" component="p">
                      Esta disponible un nuevo excel para descargar
                    </Typography>
                    <div className="d-flex mt-2 justify-end">
                      <Button variant="outlined">Entendido</Button>
                    </div>
                  </Card>
                </Popover>
              </span>
              <Button
                variant="contained"
                startIcon={<FontAwesomeIcon icon={faDownload} />}>
                DESCARGAR
              </Button>
            </Grid>

            <Grid item xs={12} className="mt-3">
              <Typography
                variant="body2"
                component="h3"
                color="text.light"
                className="d-flex mb-2">
                El formato permitido para la carga de archivos es
                <Typography variant="body2" component="h3" color="primary">
                  &nbsp;.xlsx,&nbsp;.xls,&nbsp;.odt
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={6} xl={3} className="d-flex">
              <Card className="d-flex flex-column flex p-3">
                <Typography variant="h6" component="h4" color="text.light">
                  Subí tu excel
                  <br />
                  precargado
                </Typography>
                <Divider variant="middle" className="mr-0 ml-0 mt-2 mb-2" />
                <Typography
                  variant="caption"
                  component="h6"
                  color="text.light"
                  className="mb-2">
                  Si ya tenés el archivo completo, cargalo directamente.
                </Typography>
                <FileUploader
                  cancelText={t('component.uploader.cancel')}
                  clearText={t('component.uploader.clear')}
                  defaultText={t('component.uploader.search')}
                  dragDrop={true}
                  getUploadProgress={getUploadProgress}
                  isPrivate={false} //TODO: revisar si va a ser privado o no
                  maxSize={10}
                  name={'uploader_1'}
                  onChange={handleOnChange}
                  uploadedFileText={textProp1}
                  url={process.env.REACT_APP_UPLOADER_HOST + 'file/upload'}
                  validFormats={VALID_FORMATS}
                />
              </Card>
            </Grid>

            <Grid item xs={6} xl={3} className="d-flex">
              <Card className="d-flex flex-column flex p-3">
                <Typography variant="h6" component="h4" color="text.light">
                  Subí las
                  <br />
                  caravanas
                </Typography>
                <Divider variant="middle" className="mr-0 ml-0 mt-2 mb-2" />
                <Typography
                  variant="caption"
                  component="h6"
                  color="text.light"
                  className="mb-2">
                  Si necesitás crear solo un activo, podés hacerlo directamente
                  presionando “+”.
                </Typography>
                <Link component="button" onClick={() => { }}>
                  <Icon sx={{ fontSize: 90 }} className="align-self-center">
                    add_circle
                  </Icon>
                </Link>
              </Card>
            </Grid>

            <Grid item xs={6} xl={3} className="d-flex">
              <Card className="d-flex flex-column flex p-3">
                <Typography variant="h6" component="h4" color="text.light">
                  Creá una plantilla
                  <br />
                  nueva
                </Typography>
                <Divider variant="middle" className="mr-0 ml-0 mt-2 mb-2" />
                <Typography
                  variant="caption"
                  component="h6"
                  color="text.light"
                  className="mb-2">
                  Si ya tenés el archivo completo, cargalo
                  <br />
                  directamente.
                </Typography>
                <Link component="button" onClick={() => { }}>
                  <Icon sx={{ fontSize: 90 }} className="align-self-center">
                    add_circle
                  </Icon>
                </Link>
              </Card>
            </Grid>

            <Grid item xs={6} xl={3} className="d-flex">
              <Card className="d-flex flex-column flex p-3">
                <Typography variant="h6" component="h4" color="text.light">
                  Filtrar Activos
                  <br />
                  del establecimiento
                </Typography>
                <Divider variant="middle" className="mr-0 ml-0 mt-2 mb-2" />
                <Typography
                  variant="caption"
                  component="h6"
                  color="text.light"
                  className="mb-2">
                  Si querés aplicar eventos eligiendo los activos utilizando los
                  filtros de la plataforma
                </Typography>

                <Link
                  component="button"
                  onClick={() => {
                    history.push({
                      pathname: TRANSFER_ASSETS_FILTER,
                      state: {
                        mode: 'filterTransfer',
                        event: { boxTarget: 198 },
                      },
                    });
                  }}>
                  <Icon sx={{ fontSize: 90 }} className="align-self-center">
                    add_circle
                  </Icon>
                </Link>
              </Card>
            </Grid>
          </Grid>

          {/*<Grid item xs={3} className="mt-3 d-flex justify-between" component={Card}>
          <div className="d-flex align-start mt-2 ml-2 mb-2">
            <FontAwesomeIcon icon={faFilePdf} color="#61B15A" size="lg" className="mr-2 mt-1" />
            <Typography variant="caption" component="p">
              ACTIVOS
              <br />
              PDF - 1.2mb
            </Typography>
          </div>
          <FontAwesomeIcon icon={faTrashCan} size="lg" className="mr-1 mt-1" />
        </Grid>
            */}
        </Grid>
      )}
      {activeStep === 3 && (
        <Grid item xs={12}>
          <ActivosBasicTable
            exportConfig={{
              mode: true,
              caption: 'Transferir los Activos seleccionados',
              exportMode: 'editAsset',
              redirectPath: '',
              redirectArgs: { boxTarget: 198 },
            }}
            exportFilterAction={(ret) => {
              // tododromero, ajustar chequeo de consistencia de los datos a enviar
              if (true) {
                let dataObj = {
                  boxSource: selectedBox.id,
                  boxTarget: ret.exportFileDataConfig.redirectArgs.boxTarget,
                  rows: ret.rows,
                };
                ApiFetch(
                  'objects/add/assets/transfer/massive',
                  globalContext,
                  true,
                  async (res) => {
                    if (res.status === 200) {
                      //props.onUploadOk(res);
                      //setWaitModal(false);

                      history.push({
                        pathname: ASSETS,
                        state: {},
                      });
                      window.location.reload();
                    } else {
                      //props.onUploadError(res);
                    }
                  },
                  (error) => {
                    console.log('error objects/add/assets/transfer/massive', error);
                    //props.onUploadError(error);
                  },
                  {
                    method: 'POST',
                    body: JSON.stringify(dataObj),
                  }
                );
              }
            }}
          />
        </Grid>
      )}

      <Dialog onClose={handleClose} open={dialogOpen}>
        <DialogTitle className="d-flex flex-column align-center text-center mr-4 ml-4">
          <FontAwesomeIcon
            icon={faTag}
            color="#61B15A"
            size="lg"
            className="mb-1 mt-2"
          />
          Ingresá un nombre para
          <br />
          agendar este box
        </DialogTitle>
        <DialogContent className="d-flex flex-column align-center">
          <TextField id="name" fullWidth />
          <Button
            className="mt-2 m-2"
            variant="contained"
            onClick={handleClose}>
            Agendar
          </Button>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

export default TransferBox;
