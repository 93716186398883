import { createTheme } from '@mui/material/styles';
import { typography, breakpoints } from '../../Layout/theme/commonTheme.js';

const palette = {
  primary: {
    light: '#3969E8',
    main: '#244CB7',
    dark: '#61B15A',
    contrastText: '#FFFFFF',
  },
  secondary: {
    light: '#DAFFD7',
    main: '#EFFFD2',
    dark: '#616872',
    contrastText: '#438C3D',
  },
  text: {
    primary: "#244CB7",
    secondary: "#3969E8",
    grey: '#616872',
    light: '#999999',
  },
}

const components = {
  MuiListItemButton: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          'color': palette.primary.dark,
          'backgroundColor': palette.primary.light,
          '.MuiIcon-root': {
            color: palette.primary.dark,
          },
          '.MuiTypography-root': {
            fontWeight: 500,
          },
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        color: 'inherit',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        '&.MuiButton-contained.Mui-disabled': {
          color: palette.primary.contrastText,
          boxShadow: 'none',
          background: palette.primary.main,
          opacity: '0.3',
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        '&.MuiTypography-h6': {
          lineHeight: '20px',
          fontSize: '17px',
          fontWeight: 500,
          'p': {
            margin: 0
          }
        },
        '&.MuiTypography-h4': {
          lineHeight: '26.25px',
          fontSize: '25px',
          fontWeight: 700
        },
        '&.MuiTypography-h3': {
          lineHeight: '18px',
          fontSize: '16px',
          fontWeight: 700
        },
        '&.MuiTypography-h2': {
          lineHeight: '24px',
          fontSize: '20px',
          fontWeight: 500
        },
        '&.MuiTypography-h1': {
          lineHeight: '44.1px',
          fontSize: '42px',
          fontWeight: 700
        },
        '&.MuiTypography-subtitle1': {
          lineHeight: '19.2px',
          fontSize: '16px',
          fontWeight: 300
        },
        '&.MuiTypography-subtitle2': {
          lineHeight: '19.2px',
          fontSize: '13px',
          fontWeight: 700
        },
        '&.MuiTypography-body2': {
          lineHeight: '20px',
          fontSize: '13px',
          fontWeight: 400
        },
        '&.MuiTypography-body1': {
          lineHeight: '20px',
          fontSize: '16px',
          fontWeight: 500
        },

      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        '.MuiDataGrid-cell--editable': {
          'outline': 'none!important',
          'boxShadow': 'none!important',
          '&:hover': {
            cursor: 'pointer',
          },
          '> .Mui-focused': {
            'border': '1px solid #3969E8',
            'borderRadius': 0,
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          },
        },
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      root: {
        '.MuiPopover-paper': {
          boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.05)',
        },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        '.MuiDialog-paper': {
          padding: '2rem',
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        'input[type=search]': {
          '&::-webkit-search-cancel-button': {
            '-webkit-appearance': 'none',
            'width': '15px',
            'height': '15px',
            'backgroundImage': `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' width='15' height='15' viewBox='0 0 130 130'%3E%3Cline x1='14' y1='10' x2='120' y2='120' stroke-width='10' stroke='%23999' /%3E%3Cline x1='10' y1='120' x2='120' y2='10' stroke-width='10' stroke='%23999' /%3E%3C/svg%3E")`,
          },
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        height: '38px',
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        marginTop: '1rem',
        marginBottom: '1rem',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        'height': '38px',
        '.MuiButtonBase-root': {
          color: '#40843A',
        },
      },
    },
  },
  MuiTimelineConnector: {
    styleOverrides: {
      root: {
        backgroundColor: '#3969E8',
        paddingTop: '8rem'
      },
    },
  },
  MuiTimelineItem: {
    styleOverrides: {
      root: {
        ':before': {
          display: 'none'
        }
      },
    },
  },
};


export const trazabilidadTheme = createTheme({
  palette: palette,
  typography: typography,
  breakpoints: breakpoints,
  components: components,
});
export const drawerWidth = '16vw';
export default trazabilidadTheme;