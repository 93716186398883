import ReactDOMServer from 'react-dom/server';
import {useState, useEffect} from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Location from '../../../assets/icons/Location';

const ChangeFocus = ({coords}) => {
    const map = useMap()
    map.flyTo(coords, map.getZoom(),{animate: true,duration: 3});
    return null
}

export const Map = ({information}) => {
 
 const [position, setPosition] = useState([information.lat,information.lon]);

 useEffect(()=>{
    setPosition(information)
 },[information])

 const customMarkerIcon = (color) => {
  return new L.divIcon({
   html: ReactDOMServer.renderToString(
    <Location color={color ? color : '#3969E8'} />
   ),
   iconSize: [45, 45],
  });
 };
 return (
  <MapContainer
   center={position}
   zoom={5}
   scrollWheelZoom={false}
   style={{ height: '100%', width: '100%' }}
  >
   <TileLayer
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
   />
   <ChangeFocus coords={position} />
   <Marker position={position} icon={customMarkerIcon(`#3969E8`)}>
    <Popup>
     {
     information?.title
     }
    </Popup>
   </Marker>
  </MapContainer>
 );
};
