import React, { useRef } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useEffect } from 'react';
import Polyline from 'react-leaflet-arrowheads'
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import OriginoQrLogo from '../../../Images/origino_qr.png'

function TransitionCoordinates({ coords ,itemsRef}) {
  const map = useMap();

    const flyAndShowTooltip = () =>{
      const marker = itemsRef.current[0]
      if (marker) marker.openPopup()
      for (var i = 0; i < coords.length; i++) {
        (function (i) {
          setTimeout(function () {
            map.flyTo(coords[i], map.getZoom(),{animate: true,duration: 3});
            const marker = itemsRef.current[i]
            if (marker) marker.openPopup()
          }, 6000 * i);
        })(i);
      };
    }
    
    flyAndShowTooltip()

    setInterval(() => {
      flyAndShowTooltip()
    }, 6000 * coords.length);

  return null;
}

export const MapJourney = ({ information }) => {
  const [journey, setJourney] = React.useState([])
  const itemsRef = useRef([]);

  useEffect(() => {
    let journeyAux = []
    information.forEach((i) => {
      if (i.lat !== null && i.lon !== null) {
        journeyAux.push([i.lat, i.lon])
      }
    })
    setJourney(journeyAux)
  }, [])

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, information.length);
 }, [information]);

  const getLogoImage = (data) => {
    try {
      let jsonValue = JSON.parse(data);
      return (jsonValue.publicFileURI);
    } catch (e) {
      return OriginoQrLogo
    }
  }

  const customMarkerIcon = (logoUrl) => {
    return new L.icon({
      iconUrl: logoUrl,
      iconSize: [45, 45],
    });
  };

  return (
    <>
      {journey.length > 0 &&
        <MapContainer
          center={[information[0].lat, information[0].lon]}
          zoom={10}
          scrollWheelZoom={false}
          style={{ height: '100%', width: '100%' }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          <Polyline smoothFactor={1} color="darkgray" positions={journey} >
          </Polyline>
          <TransitionCoordinates coords={information} itemsRef={itemsRef}/>
          {information.map((m, index) => (
            <Marker ref={el => itemsRef.current[index] = el}  position={[m.lat, m.lon]} icon={customMarkerIcon(getLogoImage(m.logo))} key={index}>
              <Popup> {m.title} </Popup>
            </Marker>
          ))}
        </MapContainer>
      }
    </>
  );
};
