import React from 'react';

export const TrazabilidadBackground = () => {
  return (
    <>
      <img
        src='/images/vectores/vectores-2.svg'
        className='vector-left'
        alt='vectores'
      />
      <img
        src='/images/vectores/vectores-1.svg'
        className='vector-right'
        alt='vectores'
      />

      <img
        src='/images/vectores/vectores-3.svg'
        className='vector-midel'
        alt='vectores'
      />
      <img
        src='/images/vectores/vectores-4.svg'
        className='vector-midel-right'
        alt='vectores'
      />
    </>
  );
};
