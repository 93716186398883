import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import FileUploader from '../../Components/FileUploader/FileUploader';

const VALID_FORMATS = [
  '.jpg',
  '.png',
  '.jpeg',
  '.gif',
  '.pdf',
  '.docx',
  '.doc',
];

function LoadTransfer(props) {
  const { t } = useTranslation();

  const [textProp1, setTextProp1] = useState('');
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const getUploadProgress = (progressInfo) => {
    setUploadInProgress(progressInfo?.status.loading);
    if (uploadInProgress) {
      setTextProp1('');
    }
  };

  const handleOnChange = async (statusInfo) => {
    if (statusInfo.reset) {
      setTextProp1('');
    }
    if (statusInfo.success) {
      // const currentVal = statusInfo?.uploadedFileInfo[0];
      //TODO: dejo comentado esta parte porque se ve que esta a medio hacer,
      // faltaria hacer algo con la info del archivo
      // if (currentVal) {
      //   props.onUploadFile(currentVal);
      // }
    }
  };

  return (
    //Starts the route view with <Grid container> and add <Grid item 12> for rows.
    <Grid container spacing={2}>
      <Grid item xs={4} className="mt-3">
        <Typography variant="subtitle1" component="p">
          {t('stepByStep.massive.target.title')}
        </Typography>
        <div className="d-flex align-center mt-2">
          <TextField
            id="filled-search"
            type="search"
            className="mr-2"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained">
            {t('stepByStep.button.transfer.validate')}
          </Button>
        </div>
      </Grid>

      <Grid item xs={12} className="mt-3">
        <Typography variant="subtitle1" component="p">
          {t('stepByStep.massive.target.typeTitle')}
        </Typography>
        <Typography variant="body2" color="text.primary" component="p">
          {t('stepByStep.massive.target.typeSubtitle')}
        </Typography>
        <div className="d-flex align-center">
          <FormControlLabel
            control={
              <Checkbox
                checked={true}
              />
            }
            label="Titularidad"
            className="mt-1"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={false}
              />
            }
            label="Posesión"
            className="mt-1"
          />
        </div>
      </Grid>

      <Grid item xs={12} className="mt-3">
        <Typography variant="subtitle1" component="p">
          {t('stepByStep.massive.target.fileTitle')}
        </Typography>
        <Typography variant="body2" color="text.primary" component="p">
          {t('stepByStep.massive.target.fileSubtitle')}
        </Typography>
        <Grid item xs={3} className="mt-3">
          <FileUploader
            cancelText={t('component.uploader.cancel')}
            clearText={t('component.uploader.clear')}
            defaultText={t('component.uploader.search')}
            dragDrop={true}
            getUploadProgress={getUploadProgress}
            isPrivate={false} //TODO: revisar si va a ser privado o no
            maxSize={10}
            name={'uploader'}
            onChange={handleOnChange}
            uploadedFileText={textProp1}
            url={process.env.REACT_APP_UPLOADER_HOST + 'file/upload'}
            validFormats={VALID_FORMATS}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} className="mt-4">
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Button className="mr-1" variant="contained">
            {t('stepByStep.button.transfer.confirm')}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default LoadTransfer;
