import { Grid, InputLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import BasicDrawer from '../BasicDrawer';
import { useTranslation } from 'react-i18next';

function PolygonField({ error, label, onChange, name, value, modeSatelite, boxLocation, xs }) {
  const { t } = useTranslation();
  const [geoJson, setGeoJson] = useState(null);

  const handleChange = (locationTemp) => {
    locationTemp && onChange(name, JSON.stringify(locationTemp));
    !locationTemp && onChange(name, '');
  };

  useEffect(() => {
    if (value !== '' && value !== undefined) {
      const geo = JSON.parse(value);
      setGeoJson(geo);
    }
  }, [value]);

  return (
    <>
      <Grid item sx={{ pr: 2, mb: 2 }} xs={xs}>
        <InputLabel id={'demo-controlled-open-select-label-' + name}>
          {label} <small>{t('stepByStep.msg.selectFormAndDraw')}</small>
        </InputLabel>

        <BasicDrawer
          updateLocation={handleChange}
          name={name}
          modeSatelite={modeSatelite}
          boxLocation={boxLocation}
          geoJson={geoJson}
        />
      </Grid>
    </>
  );
}

export default PolygonField;
