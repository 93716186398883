import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import React from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import 'moment/min/locales'

function DateField({
  label,
  name,
  disabled,
  value,
  onChange,
  size,
  InputLabelProps,
  locale,
  type,
  error,
}) {

  if (moment.locale() !== locale) {
    moment.locale(locale);
  }

  const Component = type === 'dateTime' ? DateTimePicker : DatePicker;

  const isValidDate = (d) => d instanceof Date && !isNaN(d);

  const handleOnChange = (val) => {
    try {
      val = val.toDate();
      if (isValidDate(val)) {
        if (type === 'date') val.setHours(0, 0);
        return onChange(name, val);
      }
    } catch (e) {

    }
    return onChange(name, val);
  };

  return (
    <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterMoment}>
      <Component
        label={label}
        sx={{ width: '100%', mr: 2 }}
        disabled={disabled}
        name={name}
        error={error}
        value={value == null ? null : value}
        onChange={handleOnChange}
        renderInput={(props) => (
          <TextField
            {...props}
            helperText={null}
            size={size}
            InputLabelProps={InputLabelProps}
            sx={{ width: '100%', mr: 2 }}
            error={error}
            disabled={disabled}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default DateField;
