import React, {useState} from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import BaseBasicForm from "../../../Layout/containers/BaseBasicForm";
import leftIcon from '../../../Images/stepbystep/lefticons/bienvenido.png';
import { useTranslation } from 'react-i18next';
import { ReValidateButton } from "../../Access/ReValidateButton";
import LanguageSelect from "../../../Components/LanguageSelect";

function SignUpEmailVerify() {
  const { t } = useTranslation();
  const location = useLocation();
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const email = location?.state?.email;

  return (
    <BaseBasicForm leftIcon={leftIcon} text={t('signUp.title')} showOriginoImage="true">
      <Box position={'absolute'} top={20} right={40} sx={{
        right: { lg: 75, xl: 100 }, 
        display: 'flex', 
        justifyContent: 'flex-end', 
        width: '100%',
      }}>
        <LanguageSelect />
      </Box>
      <FontAwesomeIcon icon={faCheckCircle} size="3x" color="#3969E8" />
      <Typography variant="h3" component="h4" color="text.primary" align="center" className="mt-2 mb-4">
        {t('signUp.verify.title')}
      </Typography>
      <Typography variant="subtitle1" component="h4" color="text.secondary" align="center" className="mb-4">
        <br />
        {t('signUp.verify.subtitle.0') + email + t('signUp.verify.subtitle.1')}
      </Typography>
      <ReValidateButton
                setShowError={(e)=>setShowError(e)}
                setMsgError={(e)=>setMsgError(e)}
                isLogin={false}
                email={email}
                labelVisible={true}
              />
      {showError &&
          <span style={{ textAlign: "center", color: "red" }} className="m-3">
            {t("signIn.errorMsg." + msgError)}
          </span>
      }
    </BaseBasicForm>
  );
}

export default SignUpEmailVerify;
