import React from "react";
import { BrowserRouter as Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import { signInTheme } from "./signInTheme";

import {
  ActivateCompany,
  ActivateUser,
  ActivateEstablecimiento,
  StepByStepRegistroUser,
  StepByStepRegistroUserEdit,
  StepByStepRegistroCompany,
  StepByStepRegistroBox,
  EstablecimientoImagenes,
  Cadenas,
  SignUpEmailVerify,
  Localizacion,
  DatosPersonales,
  SignUpUser
} from './index'

import PrivateRoute from "../../Components/Router/PrivateRoute";
import GlobalContextProvider from "../../Contexts/globalContext";

import {
  USER_ACTIVATE,
  USER_REGISTER,
  USER_REGISTER_COMPLETE,
  COMPANY_REGISTER,
  COMPANY_ACTIVATE,
  BOX_REGISTER,
  BOX_ACTIVATE,
  CREATE_ACCOUNT,
  PERSONAL_ACCOUNT_DATA,
  ACCOUNT_LOCATION,
  EMAIL_SENT,
  COMPANY_CHAINS,
  ESTABLISHMENT_IMAGES
} from "../../constants/paths"

function Main() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={signInTheme}>
        <Switch>
          <Route path={CREATE_ACCOUNT} component={SignUpUser} exact />
          <Route path={PERSONAL_ACCOUNT_DATA} component={DatosPersonales} exact />
          <Route path={ACCOUNT_LOCATION} component={Localizacion} exact />
          <Route path={EMAIL_SENT} component={SignUpEmailVerify} exact />
          <Route path={COMPANY_CHAINS} component={Cadenas} exact />
          <Route path={ESTABLISHMENT_IMAGES} component={EstablecimientoImagenes} exact />

          <GlobalContextProvider>
            <PrivateRoute path={USER_REGISTER} component={StepByStepRegistroUser} exact></PrivateRoute>
            <PrivateRoute path={USER_REGISTER_COMPLETE} component={StepByStepRegistroUserEdit} exact></PrivateRoute>
            <PrivateRoute path={USER_ACTIVATE} component={ActivateUser} exact></PrivateRoute>

            <PrivateRoute path={COMPANY_REGISTER} component={StepByStepRegistroCompany} exact></PrivateRoute>
            <PrivateRoute path={COMPANY_ACTIVATE} component={ActivateCompany} exact></PrivateRoute>

            <PrivateRoute path={BOX_REGISTER} component={StepByStepRegistroBox} exact></PrivateRoute>
            <PrivateRoute path={BOX_ACTIVATE} component={ActivateEstablecimiento} exact></PrivateRoute>
          </GlobalContextProvider>
        </Switch>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default Main;
