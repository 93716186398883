import React, { useState } from 'react'
import BaseBasicForm from '../../Layout/containers/BaseBasicForm'
import leftIcon from '../../Images/stepbystep/lefticons/bienvenido.png';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { ApiFetch } from '../../Utils/ApiFetch';
import { GlobalContext } from '../../Contexts/globalContext';
import { LoadingButton } from '@mui/lab';
import { LOGIN } from '../../constants/paths';
import useGlobalContext from '../../Hooks/useGlobalContext';
import LanguageSelect from '../../Components/LanguageSelect';
const { REACT_APP_URL } = process.env;

const SendMailChangePassword = () => {
  const { t } = useTranslation();
  const globalContext = useGlobalContext();
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [emailSended, setEmailSended] = useState(false)
  const [error, setError] = useState('')
  const loginPath = `${REACT_APP_URL}${LOGIN.substring(1)}`

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    ApiFetch(
      'users/sendMailResetPassword',
      GlobalContext,
      false,
      (res) => {
        if (res.ok) {
          setEmailSended(true)
        }
        setIsLoading(false)
      },
      (err) => {
        if (err.status === 404) {
          setError(t("passwordReset.errorMsg.notFound"))
        } else {
          setError(t("passwordReset.errorMsg.serverError"))
        }
        setIsLoading(false)
      }
      , {
        method: 'POST',
        body: JSON.stringify({ "email": email })
      })
  }

  return (
    <BaseBasicForm text={t("passwordReset.title")} leftIcon={leftIcon} showOriginoImage="true">
      <Box position={'absolute'} top={20} right={40} sx={{
        right: { lg: 90, xl: 130 }, 
        display: 'flex', 
        justifyContent: 'flex-end', 
        width: '100%',
      }}>
        <LanguageSelect />
      </Box>
      {emailSended
        ? <>
          <Typography variant="subtitle1" component="h4" color="text.secondary" className="mb-4">
            {t("passwordReset.subtitleAfterSend")}
          </Typography>
          <Link href={loginPath}>{t("passwordReset.backToLogin")}</Link>
        </>
        :
        <>
          <Typography variant="subtitle1" component="h4" color="text.secondary" className="mb-4">
            {t("passwordReset.subtitleBeforeSend")}
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit}
            autoComplete='off'
            style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'flex-end' }}>
            <TextField
              id="email"
              label="email"
              type='email'
              required
              value={email}
              onChange={(val) => { setEmail(val.target.value) }}
              fullWidth
              sx={{ mb: 3 }}
            />
            <Link href={loginPath}>{t("passwordReset.backToLogin")}</Link>
            <LoadingButton
              variant='contained'
              loading={isLoading}
              type='submit'
              loadingIndicator={<CircularProgress color="primary" size={16} />}>
              {t("passwordReset.continue")}
            </LoadingButton>
          </Box>
          <br />
          {error &&
            <Typography color={'red'}>
              {error}
            </Typography>
          }
        </>
      }
    </BaseBasicForm >
  )
}

export default SendMailChangePassword