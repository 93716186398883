import React, { memo } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AttributeTokens } from './AttributeTokens';

const QrAccordion = ({ title, data, t }) => {
  return (
    <Box sx={{ marginTop: 3 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h3" sx={{ color: 'text.secondary', marginBottom: 1 }}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AttributeTokens tt={t} data={data} hideLabel={true} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default memo(QrAccordion);
