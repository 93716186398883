import { Grid, Alert, Button } from '@mui/material';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { COMPANY_EDIT } from '../../constants/paths';
const ENTITY_TYPES = {
    COMPANY: 'COMPANY',
    USER: 'USER',
}

function RequiredFieldsError() {
    const { t } = useTranslation();
    const { mandatoryFieldsErrorStack, selectedCompany } = useGlobalContext();
    const history = useHistory();

    const handleRedirect = (entityType) => {
      if(entityType === ENTITY_TYPES.USER){
          history.push('/users/stepbystep/edit');
      } else {
        history.push({
          pathname: COMPANY_EDIT,
          state: {
            companyId: selectedCompany.id,
          },
        });
      }
    }

    return(
      <Grid container spacing={2} gap={1}>
        {mandatoryFieldsErrorStack.map((error, index) => (
          <Grid item xs={12}>
            <Alert key={index} severity="error">
              {t(`component.alert.requiredFields.${error.entity_type.toLowerCase()}`,{field_label: error.field})}
                <Button sx={{padding: 0}} onClick={() => handleRedirect(error.entity_type)}>{t('component.link_button')}</Button>
            </Alert>
          </Grid>
        ))}
      </Grid>
    )
}

export default RequiredFieldsError;