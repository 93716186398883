import React, { useState, useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import BasicFormStepByStep from "../../../Components/Generic/basicFormStepByStep";
import BaseBasicForm from "../../../Layout/containers/BaseBasicForm";
import useGlobalContext from "../../../Hooks/useGlobalContext";
import { BOX_ACTIVATE, BOXES_ALL, COMPANY_ALL } from "../../../constants/paths";
import leftIcon from '../../../Images/stepbystep/lefticons/establecimiento.png'
import { ApiFetch } from '../../../Utils/ApiFetch';
import { useTranslation } from 'react-i18next';

function StepByStepRegistroEstablecimiento(props) {

  const { t } = useTranslation()
  let history = useHistory();
  const globalContext = useGlobalContext();

  const [fields, setFields] = useState([]);
  const [form, setForm] = useState(null);
  const [bindValues, setBindValues] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [company, setCompany] = useState(null);
  const [parentId, setParentId] = useState(null);
  const [boxId, setBoxId] = useState(null);
  const [mode, setMode] = useState("new");
  const [route, setRoute] = useState([]);
  const [fromList, setFromList] = useState(false);

  const [globalModel, setGlobalModel] = useState(0); // dromerodemo
  const [firstBoxType, setFirstBoxType] = useState(0); // dromerodemo
  const [objectTypeSubType, setObjectTypeSubType] = useState(1); // dromero
  const [parentSubtype, setParentSubtype] = useState(null); // dromero

  const setModels = (boxType, model) => {

    let models = globalContext.getSessionDataByKey("models");
    let fields = models[model]["boxTypes"][boxType].attributes;
    let form = models[model]["boxTypes"][boxType].form;
    setGlobalModel(model);
    setObjectTypeSubType(boxType);
    setFields(fields);
    setForm(form);

    return models;
  };

  const getGlobalModelFromBoxType = async (boxType) => {
    let models = await globalContext.getSessionDataByKey("models");
    for (let boxTypeTemp of models.boxSuplyHolder.basic) {
      if (boxTypeTemp.id === boxType.toString()) {
        return boxTypeTemp.id_asset_type;
      }
    }
    return -1;
  }

  useEffect(() => {
    const boxIdParam = (props.location && props.location.state && props.location.state.boxId) || null;
    const firstBoxTypeTemp = (props.location && props.location.state && props.location.state.subtype) || firstBoxType;

    setBoxId(boxIdParam);

    if (boxIdParam != null) {
      setMode("update");
      setFirstBoxType(props.location.state.subtype);
    } else {
      setMode("new");
    }

    const getGlobalModel = async () => {
      const globalModelTemp = await getGlobalModelFromBoxType(firstBoxTypeTemp);

      if (globalModelTemp !== -1) {
        setModels(firstBoxTypeTemp, globalModelTemp); // dromerodemo
        setCompanyId(props.location && props.location.state && props.location.state.companyId);
        setParentId((props.location && props.location.state && props.location.state.parentId) || null);
        setRoute((props.location && props.location.state && props.location.state.route) || []);
        setFromList((props.location && props.location.state && props.location.state.fromList) || false);
        setParentSubtype((props.location && props.location.state && props.location.state.parentSubtype) || 1);
      }
    }

    getGlobalModel().catch(error => console.log('error getGlobalModelFromBoxType', error));
  }, []);

  useEffect(() => {
    if (companyId != null) {
      ApiFetch(
        'objects/get/company/' + companyId,
        globalContext,
        true,
        async (res) => {
          if (res.status === 200) {
            let data = await res.json();
            if (data?.values?.cadena !== '') {
              setCompany(data.values);
            }
          }
        },
        (error) => {
          console.log('error objects/get/company', error);
        },
        {
          method: 'GET',
        }
      );
    }
  }, [companyId]);

  const onChangeField = async (field, value, fullValuesTemp) => {
    if (field === "_boxtype_") {
      let globalModelTemp = await getGlobalModelFromBoxType(value);

      if (globalModelTemp !== -1) {
        setModels(value, globalModelTemp);
        // vuelvo a bindear los valores comunes que tenía el formulario del nuevo modelo.
        setBindValues(fullValuesTemp);
      }
    }
  };

  const redirectStepByStepBox = (ids) => {
    if (!fromList) {
      let args = {
        // todo: normalizar respuesta.
        ids: ids,
        route: route,
        parentId: parentId,
      };

      history.push({
        pathname: BOX_ACTIVATE,
        state: args,
      });
    } else {
      let args = {
        ids: ids,
        route: route,
        parentId: parentId,
        parentSubtype: parentSubtype
      };
      history.push({
        pathname: BOXES_ALL,
        state: args,
      });
    }
  };

  return (
    <BaseBasicForm text={t("stepByStep.facility.titleLeft.register")} leftIcon={leftIcon} showOriginoImage="true">
      {company !== null &&
        <BasicFormStepByStep
          formMode={"vertical"}
          saveFunc={() => { }}
          show={true}
          showStepTitle={false}
          showSectionTitle={false}
          sectionAccordionMode={false}
          fieldColSize={12}
          onUpdated={(res) => { redirectStepByStepBox(res) }}
          withDefault={false}
          bindWithDefault={process.env.REACT_APP_SANDBOX_AUTOCOMPLETE === 'true' ? true : false}
          autocompleteSandboxMode={process.env.REACT_APP_SANDBOX_AUTOCOMPLETE === 'true' ? true : false}
          objectTypeBindForm={"box"}
          bindValues={bindValues}
          mode={mode}
          objectType={"box"}
          pk={boxId}
          pkObjectType={objectTypeSubType}
          pkReferer={parentId}
          globalModel={globalModel}
          parentType={"company"}
          parentTypeSubType={1}
          parentId={companyId}
          count={0}
          fields={fields}
          fieldsCustomFilterRule={{
            '_boxtype_': {
              'conditionValue':
                [(parentId == null)
                ?
                  null
                :
                  parentSubtype, company?.l1, company?.suplychain, company?.rol]
              ,
              'conditionField': ['fk_collection', 'l1', 'id_suplychain', 'key'],
              'func': (conditionValue, values) => {
                let flagFkCollection = false;
                let flagCountry = false;
                let flagSuplyChain = false;
                let flagRol = false;

                if (conditionValue[0] == null && values[0] == null) {
                  flagFkCollection = true;
                }
                if (conditionValue[0] != null && values[0] != null) {
                  if (values[0].includes(parseInt(conditionValue[0]))) {
                    flagFkCollection = true;
                  }
                }
                if (conditionValue[1] != null && values[1] != null) {
                  if (values[1].includes((conditionValue[1]))) {
                    flagCountry = true;
                  }
                }
                if (conditionValue[2] != null && values[2] != null) {
                  let spTemp = conditionValue[2].split(",");
                  if (spTemp.includes(values[2])) {
                    flagSuplyChain = true;
                  }
                }
                if (conditionValue[3] != null && values[3] != null) {
                  let spTemp = conditionValue[3].split(",");
                  if (spTemp.includes(values[3])) {
                    flagRol = true;
                  }
                }
                if(conditionValue[0] != null && conditionValue[3] != null){
                  let spTemp = conditionValue[3].split(",");
                  if (spTemp.includes(conditionValue[0])) {
                    flagRol = true;
                  }
                }

                return flagFkCollection && flagCountry && flagSuplyChain && flagRol;
              }
            }
          }}
          form={form}
          onChangeField={(field, value, fullValues) => {
            onChangeField(field, value, fullValues);
          }}
          submitOnlyLastStep={true}

          cancelButtontext={t("stepByStep.button.company.cancel")}
          onCanceled={() => {
            if (mode === 'update') history.push({ pathname: COMPANY_ALL, state: {} })
          }}
          showCancelButton={mode === 'update' ? true : false}
        />
      }
    </BaseBasicForm>
  );
}

export default StepByStepRegistroEstablecimiento;
