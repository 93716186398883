export const selectAsset35StatusMassive = (optionsKeys, selectedBox, relationUserEntity, assetInit, option, eventType) => {
  let options = []
  let optionAsset = {...assetInit.find(asst => asst.id === option.id)}

  if(eventType === 5) {
    if ((selectedBox.subtype === "27" || selectedBox.subtype === "28" || selectedBox.subtype === "29")
      && optionAsset.asset_status_change === "active" && relationUserEntity === "EXPERT_USER")
      options = optionsKeys.filter(e => e.key === "validated" || e.key === "observed" );

    if ((selectedBox.subtype === "27" || selectedBox.subtype === "28" || selectedBox.subtype === "29")
      && optionAsset.asset_status_change === "observed" && relationUserEntity === "EXPERT_USER")
      options = optionsKeys.filter(e => e.key === "validated");

  } else if (eventType === 6) {
    if (selectedBox.subtype === "28" && optionAsset.asset_status_change === "active")
      options = optionsKeys.filter(e => e.key === "active" || e.key === "inactive" );

    else if ((selectedBox.subtype === "27" || selectedBox.subtype === "28" || selectedBox.subtype === "29") &&
    optionAsset.asset_status_change === "validated" && relationUserEntity === "EXPERT_USER")
      options = optionsKeys.filter(e => e.key === "validated" || e.key === "invalidated");

    else if (selectedBox.subtype === "28" && optionAsset.asset_status_change === "validated" &&
    (relationUserEntity === "OWNER" || relationUserEntity === "EXPERT_USER" || relationUserEntity === "COMPANY_DELEGATE"))
      options = optionsKeys.filter(e => e.key === "validated" || e.key === "inactive");

    else if (selectedBox.subtype === "28" && optionAsset.asset_status_change === "expired" &&
    (relationUserEntity === "OWNER" || relationUserEntity === "EXPERT_USER" || relationUserEntity === "COMPANY_DELEGATE"))
      options = optionsKeys.filter(e => e.key === "inactive" || e.key === "expired");

    else if (selectedBox.subtype === "28" && optionAsset.asset_status_change === "invalidated" && relationUserEntity === "EXPERT_USER")
      options = optionsKeys.filter(e => e.key === "inactive" || e.key === "invalidated" || e.key === "active");

    else if (selectedBox.subtype === "28" && optionAsset.asset_status_change === "inactive" && relationUserEntity === "EXPERT_USER")
      options = optionsKeys.filter(e => e.key === "inactive" || e.key === "active");

    else {
      options = optionsKeys.filter(e => e.key === optionAsset.asset_status_change)
    }
  }

  return options
}

export const selectAsset35Status = (optionsKeys, selectedBox, relationUserEntity, assetStatus, eventType) => {
  let options = []

  if(eventType === 5) {
    if ((selectedBox.subtype === "27" || selectedBox.subtype === "28"  || selectedBox.subtype === "29") &&
    assetStatus === "active" && relationUserEntity === "EXPERT_USER")
      options = optionsKeys.filter(e => e.key === "validated" || e.key === "observed" );

    else if ((selectedBox.subtype === "27" || selectedBox.subtype === "28"  || selectedBox.subtype === "29") &&
    assetStatus === "observed" && relationUserEntity === "EXPERT_USER")
      options = optionsKeys.filter(e => e.key === "validated");

  } else if(eventType === 6) {
    if (selectedBox.subtype === "28" && assetStatus === "active")
      options = optionsKeys.filter(e => e.key === "active" || e.key === "inactive" );

    else if ((selectedBox.subtype === "27" || selectedBox.subtype === "28" || selectedBox.subtype === "29")
      && assetStatus === "validated" && relationUserEntity === "EXPERT_USER")
      options = optionsKeys.filter(e => e.key === "validated" || e.key === "invalidated");

    else if (selectedBox.subtype === "28" && assetStatus === "validated" &&
    (relationUserEntity === "OWNER" || relationUserEntity === "EXPERT_USER" || relationUserEntity === "COMPANY_DELEGATE"))
      options = optionsKeys.filter(e => e.key === "validated" || e.key === "inactive");

    else if (selectedBox.subtype === "28" && assetStatus === "expired" &&
    (relationUserEntity === "OWNER" || relationUserEntity === "EXPERT_USER" || relationUserEntity === "COMPANY_DELEGATE"))
      options = optionsKeys.filter(e => e.key === "inactive" || e.key === "expired");

    else if (selectedBox.subtype === "28" && assetStatus === "invalidated" && relationUserEntity === "EXPERT_USER")
      options = optionsKeys.filter(e => e.key === "inactive" || e.key === "invalidated" || e.key === "active" );

    else if (selectedBox.subtype === "28" && assetStatus === "inactive" && relationUserEntity === "EXPERT_USER")
      options = optionsKeys.filter(e => e.key === "inactive" || e.key === "active" );

    else {
      options = optionsKeys.filter(e => e.key === assetStatus)
    }
  }

  return options
}


