import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { initModel } from '../../Utils/Model'
import Loading from '../Generic/Loading';

const PublicRoute = (props) => {

  const { initiatedItemsComplete } = useGlobalContext();
  const globalContext = useGlobalContext();

  useEffect(() => {
    initModel(globalContext);
  }, [])

  if (initiatedItemsComplete === false) return (
    <Loading />
  )

  return <Route {...props} />;
}

export default PublicRoute;