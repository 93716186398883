import React from 'react';
import TextMaskedField from './TextMaskedField';

function MaskedField({
  disabled,
  error,
  label,
  mask,
  name,
  onChange,
  value,
  sx,
  size,
}) {
  const handleAccept = (value, mask) => {
    //TODO: aca esta el nombre de la mask por si se quiere cambiar un icono.
    onChange(name, value.replace(/_/g, ''));
  };

  const defaultProps = {
    disabled: disabled,
    error: error,
    label: label,
    mask: mask,
    name: name,
    sx: sx,
    onAccept: handleAccept,
    value: value,
    size: size,
  };

  return <TextMaskedField {...defaultProps} />;
}

export default MaskedField;
