import { Checkbox, Typography, Card } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import useGlobalContext from '../../../Hooks/useGlobalContext';

function GroupedMultiSelectBoxField(props) {
  const { label, name, checkItem, groupedField, filters = [], onChange, noRowsLabel, fieldsLoad, fieldsDisabledOnEdit = []} = props;
  const [fieldValues, setFieldValues] = useState([]);
  const { userData } = useGlobalContext();

  useEffect(() => {
    let filteredOptions = props.fieldValues.filter(item => {
      if (props.fieldFk) {
        return props.valueFk.split(",").includes(item.fk);
      }
      return true;
    }).filter(item => {
      if (filters && filters[name]) {
        let filter = filters[name];
        let filtersFieldsValues = filter.conditionField.map(conditionFieldTemp => item[conditionFieldTemp]);
        return filter.func(filter.conditionValue, filtersFieldsValues);
      }
      return true;
    }).map(attr => ({ ...attr }))

    if(fieldsDisabledOnEdit && fieldsDisabledOnEdit[name]){
      let funcDisabled = fieldsDisabledOnEdit[name]

      filteredOptions.map(item => {
        return funcDisabled.func(fieldsLoad, item)
      });
    }

    let groupedOptions = filteredOptions.reduce((acc, obj) => {
      const key = obj[groupedField];
      acc[key] = acc[key] || [];
      acc[key].push(obj);
      return acc;
    }, {});

    setFieldValues(Object.entries(groupedOptions));
  }, [props.fieldValues, props.fieldFk, props.valueFk]);

  return (
    <>
      <Typography
        variant="h6"
        component="p"
        color="text.secondary"
        className="font-weight-normal mb-2"
      >
        {label}
      </Typography>
      {fieldValues?.map((item) => {
        return (
          <>
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              className="font-weight-normal mb-2"
            >
              {item[0]}
            </Typography>

            {item[1].map(box => {
              let StyledCard = styled(Card)`
                border: 1px solid #3969e8;
                box-shadow: none;
                border-radius: 14px;
              `;
              if (box.active==0) {
                StyledCard = styled(Card)`
                  border: 1px solid lightgray;
                  box-shadow: none;
                  border-radius: 14px;
                `;
              }

              return(
                <StyledCard className="d-flex flex-row w-100 align-center justify-between pt-1 pb-1 pl-2 pr-2 mb-2">
                  <div className="d-flex flex-row align-center">
                    <div className="d-flex flex-row">
                      <Typography variant="body1" component="p" color="text.primary" className="mr-2">
                        {box.img != null
                        ?
                          <>
                            <img style={{maxWidth:"150px",marginRight:"20px"}} src={box.img} alt='itemImage'/>
                          </>
                        :
                          ''
                        }
                        {box.label}
                      </Typography>
                      <Typography variant="body1" component="p" color="text.secondary">
                        {''}
                      </Typography>
                    </div>
                  </div>

                  <Checkbox
                    disabled={(box.active==0
                      // || (box.key == 27 && userData.userData.id != 636) TODO: En prod, descomentar y cambiar el numero del usuario MtR
                    )?true:false}
                    checked={checkItem(name, box.key)}
                    onChange={() => onChange(name, { name: box.label, id: box.key })}
                  />
                </StyledCard>
              )
            })}
          </>
      )})}
      {
        noRowsLabel!=null && fieldValues?.length===0 &&
        <small>{noRowsLabel}</small>
      }
    </>
  );
}

export default GroupedMultiSelectBoxField;
