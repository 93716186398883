import React, { useState, useEffect } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { StyleBoxFilter } from './CardSummaryFilter.styles';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import { useTranslation } from 'react-i18next';

export const CardSummaryFilter = (props) => {
  const { t } = useTranslation();
  const globalContext = useGlobalContext();
  const [dataRace, setDataRace] = useState([]);
  const { selectedAsset } = useGlobalContext();

  const setModels = () => {
    if (selectedAsset.id !== -1 && props.dataList.length > 0) {
      let models = globalContext.getSessionDataByKey('models');
      let model = selectedAsset.id;

      let chartsData = [];
      chartsData.push({
        title: "stepByStep.massive.spreadsheet.chart.0",
        value: props.dataList.length
      })

      let minValRef = 99999999999;
      let maxValRef = -99999999999;
      for (let attr of models[model].attributes) {
        if (attr.summaryRules) {
          for (let rule of attr.summaryRules) {
            if (rule === 'MIN') {
              let min = minValRef;
              for (let row of props.dataList) {
                if (isNaN(row[attr.name]) === false) {
                  if (Number(row[attr.name]) < min) {
                    min = row[attr.name];
                  }
                }
              }

              if (min !== minValRef) {
                chartsData.push({
                  title: attr.label + " Mínimo",
                  value: min
                })
              }
            }

            if (rule === 'MAX') {
              let max = maxValRef;
              for (let row of props.dataList) {
                if (isNaN(row[attr.name]) === false) {
                  if (Number(row[attr.name]) > max) {
                    max = row[attr.name];
                  }
                }
              }
              if (max !== maxValRef) {
                chartsData.push({
                  title: attr.label + " Máximo",
                  value: max
                })
              }

            }

            if (rule === 'MEAN') {
              let avgAc = 0;
              let count = 0;
              for (let row of props.dataList) {
                if (isNaN(row[attr.name]) === false) {

                  avgAc += Number(row[attr.name]);
                  count++;
                }
              }
              if (count !== 0) {
                chartsData.push({
                  title: attr.label + " Promedio",
                  value: (avgAc / count).toFixed(2)
                })
              }
            }
          }
        }
      }
      setDataRace(chartsData);
    }
  }

  useEffect(() => {
    setModels();
  }, [props.dataList, selectedAsset]);

  return (
    <CardContent>
      <Grid container spacing={1}>
        {dataRace.map((en, index) => (
          en.value &&
          <Grid item key={index}>
            <Card className="pb-1 pt-1">
              <Grid item className="d-flex justify-center">
                <StyleBoxFilter color="#DAF1DE" />
              </Grid>
              <Grid item padding={1} className="pt-1 align-self-center text-center">
                <Typography variant="subtitle1">{t(en.title)}</Typography>
              </Grid>
              <Grid item className="align-self-center text-center">
                <Typography className="font-weight-medium" variant="h5">
                  {en.value}
                </Typography>
              </Grid>
            </Card>
          </Grid>
        ))
        }
      </Grid>
    </CardContent>
  );
};
