const SyncApiFetch = async ( uri, globalContext, isAuth, customOptions) => {

    let token = {};

    if (isAuth) {
        let tokenTemp = await globalContext.bindValidToken();        
        if (tokenTemp==null) {            
            return;
        } else {            
            token = {'Authorization':tokenTemp};
        }
    }
    
    const defaultOptions = {
        headers: new Headers({ 
            'Content-Type': 'application/json',       
            ...token
        })
    }   

    const options = {
        ...defaultOptions,
        ...customOptions
    }
        
    const completeUri = process.env.REACT_APP_HOST + uri;

    try {
        const response = await fetch(completeUri, options);
        return (await response.json());        
    }
    catch(error){
        throw (error);
    }    

}

const ApiFetch = async ( uri, globalContext, isAuth, successCallback, failCallback, customOptions) => { 
            
    let token = {};

    if (isAuth) {                
        let tokenTemp = await globalContext.bindValidToken();        
        if (tokenTemp==null) {            
            return;
        } else {            
            token = {'Authorization':tokenTemp};
        }
    }
    
    const defaultOptions = {
        headers: new Headers({ 
            'Content-Type': 'application/json',       
            ...token
        })
    }   

    const options = {
        ...defaultOptions,
        ...customOptions
    }

        
    const completeUri = process.env.REACT_APP_HOST + uri;

    fetch(completeUri, options).then(
        res => {
            if(res.status >= 200 && res.status < 300){
                successCallback(res);                
            }
            else {                
                failCallback(res);                
            }
        },
        res => {
            failCallback(res); 
        }
    );

}

const ApiFetchWithoutSession = async ( uri, successCallback, failCallback, customOptions) => { 
            
    
    const defaultOptions = {
        headers: new Headers({ 
            'Content-Type': 'application/json',       
        })
    }   

    const options = {
        ...defaultOptions,
        ...customOptions
    }

        
    const completeUri = process.env.REACT_APP_HOST + uri;

    fetch(completeUri, options).then(
        res => {
            if(res.status >= 200 && res.status < 300){
                successCallback(res);                
            }
            else {                
                failCallback(res);                
            }
        },
        res => {
            failCallback(res); 
        }
    );

}

const DBSessionDataApiFetch = async ( uri, tokenTemp, isAuth, successCallback, failCallback, customOptions) => { 
            
    let token = {};

    if (isAuth) {                        
        if (tokenTemp==null) {            
            return;
        } else {            
            token = {'Authorization':tokenTemp};            
        }
    }
    
    const defaultOptions = {
        headers: new Headers({ 
            'Content-Type': 'application/json',                   
            ...token
        })
    }   

    const options = {
        ...defaultOptions,
        ...customOptions
    }

        
    const completeUri = process.env.REACT_APP_HOST + uri;

    fetch(completeUri, options).then(
        res => {
            if(res.status >= 200 && res.status < 300){
                successCallback(res);                
            }
            else {                
                failCallback(res);                
            }
        },
        res => {
            failCallback(res); 
        }
    );

}

export { ApiFetch, ApiFetchWithoutSession, SyncApiFetch, DBSessionDataApiFetch };