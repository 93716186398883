import instance from '../Config/axios'

export const getUserByEmail = async (email) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `/users/searchFromEmail/${email}`;

  return instance.get(url, config);
};

export const assignCompanyDelegate = async (user, rol, company) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `/users/delegations/company/asign`;

  return instance.post(url, { user, rol, company }, config);
};

export const getDelegationsMade = async () => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `/users/delegations/all`;

  return instance.get(url, config);
};

export const getDelegationsReceived = async () => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `/users/delegations/received/all`;

  return instance.get(url, config);
};