import { ApiFetch } from '../Utils/ApiFetch';
import instance from '../Config/axios'

export const initModel = async (globalContext) => {
  // ref dg: aqui se trae el model al front.
  ApiFetch(
    'models/get/all/' + globalContext.selectedBox.id,
    globalContext,
    false,
    async (body) => {
      let resJson = await body.json();
      globalContext.updateSessionData({ models: resJson });
      globalContext.addInitiatedItem('models');
    },
    (error) => {
      console.log('error models/get/all', error);
    }
  );

  return true;
};

export const initModelUser = async (globalContext) => {
  // ref dg: aqui se trae el model al front.
  ApiFetch(
    'models/get/user/all/' + globalContext.selectedBox.id,
    globalContext,
    true,
    async (body) => {
      let resJson = await body.json();
      globalContext.updateSessionData({ models: resJson });
      globalContext.addInitiatedItem('models');
    },
    (error) => {
      console.log('error models/get/user/all', error);
    }
  );

  return true;
};

export const SyncInitModelUser = async (boxId) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `/models/get/user/all/${boxId}`;

  return instance.get(url, config)
};
