import React from 'react';
import { Grid, Stack, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPlus } from '@fortawesome/pro-light-svg-icons';
import LogicalDeletedAssetsTable from './components/LogicalDeletedAssetsTable';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { useTranslation } from 'react-i18next';
import { useHistory, Redirect } from 'react-router-dom';
import { ACTIVE_MODEL } from '../../constants';
import { EVENTS_MASSIVE } from '../../constants/paths';

function LogicalDeletedAssets(props) {
  const { t } = useTranslation();
  let history = useHistory();

  const {
    userData,
    selectedBox,
    selectedAsset,
    selectedChain,
    selectedCompany,
  } = useGlobalContext();

  if (
    selectedAsset?.id !== ACTIVE_MODEL.vacaPn
    &&
    selectedAsset?.id !== ACTIVE_MODEL.vacaAr
  ) {
    return <Redirect to="/assets" state={{ from: history.location }} />
  }

  const redirectToMassiveEvents = () =>  {
      history.push({
      pathname: EVENTS_MASSIVE, 
      state: { source: 'logicalDeletedAssets' },
    });
  };

  return (
      <>
     <Button
              sx={{ ml: 2 }}
              variant="contained"
              disabled={!(userData?.userData?.permissions?.boxes[selectedBox.id + "-" + selectedAsset.id]?.includes("TAG_ADD"))}
              onClick={redirectToMassiveEvents}
              startIcon={<FontAwesomeIcon icon={faPlus} />}>
              {t("stepByStep.button.newDelete")}
        </Button>
      {(userData?.userData?.permissions?.boxes[selectedBox.id + "-" + selectedAsset.id]?.includes("ASSET_LIST") === true) &&
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {selectedChain.id !== -1 &&
              selectedBox.id !== -1 &&
              selectedCompany.id !== -1 &&
              selectedAsset.id !== -1 && (
                <>
                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <div>
                        {(process.env.REACT_APP_HIDE_NOT_IMPLEMENTED === 'true' ? false : true) && (
                          <Button
                            sx={{ ml: 2 }}
                            variant="outlined"
                            startIcon={<FontAwesomeIcon icon={faDownload} />}>
                            {t('breadcrumb.asset.button.download')}
                          </Button>
                        )}
                      </div>
                    </Stack>
                  </Grid>
                  <LogicalDeletedAssetsTable />
                </>
              )}
          </Grid>
        </Grid>
      }
      {!(userData?.userData?.permissions?.boxes[selectedBox.id + "-" + selectedAsset.id]?.includes("ASSET_LIST") === true) &&
        <Grid container spacing={2}>
          <Grid item xs={12} className="mt-3">
            <h5>{t("breadcrumb.errorMsg.notAllowed")}</h5>
          </Grid>
        </Grid>
      }
    </>
  );
}

export default LogicalDeletedAssets;
