const initialState = {
  progressInfo: [],
  errorMessage: undefined,
  source: undefined,
  uploadedFileInfo: [],
  selectedFileInfo: undefined,
  restoredFileInfo: undefined,
  loading: false,
  success: false,
  restored: false,
  error: false,
  reset: false,
};

const uploaderReducer = (state, action) => {
  switch (action.type) {
    case 'ON_SELECT_FILE': {
      return {
        ...state,
        selectedFileInfo: action.payload.selectedFileInfo,
        progressInfo: action.payload.progressInfo,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        reset: action.payload.reset,
      };
    }
    case 'ON_RESTORE_FILE': {
      return {
        ...state,
        restoredFileInfo: action.payload.restoredFileInfo,
        restored: action.payload.restored,
        success: action.payload.success,
        loading: action.payload.loading,
      };
    }

    case 'ON_UPLOAD_FILE': {
      return {
        ...state,
        source: action.payload.source,
        progressInfo: action.payload.progressInfo,
        restored: action.payload.restored,
        loading: action.payload.loading,
      };
    }
    case 'ON_UPLOAD_SUCCESS': {
      return {
        ...state,
        uploadedFileInfo: action.payload.uploadedFileInfo,
        progressInfo: action.payload.progressInfo,
        restored: action.payload.restored,
        loading: action.payload.loading,
        success: action.payload.success,
      };
    }

    case 'ON_UPLOAD_ERROR': {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        error: action.payload.error,
        loading: action.payload.loading,
        restored: action.payload.restored,
        success: action.payload.success,
      };
    }

    case 'ON_RESET': {
      return {
        ...initialState,
        reset: action.payload.reset,
      };
    }

    case 'ON_INIT': {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export { initialState, uploaderReducer };
