import instance from '../Config/axios'

export const getCompaniesRoles = async (companyId) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `/companies/roles/${companyId}`;

  return instance.get(url, config);
};