import React, { useEffect } from "react";
import { IconButton, Popover, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from 'react-i18next';
import useGlobalContext from '../../../Hooks/useGlobalContext'

// SubEstablecimiento
function PopoverOptions(props) {

    const { t } = useTranslation()
    const [dropdown, setDropdown] = React.useState(false);
    const [disabledEdit, setDisabledEdit] = React.useState(true);
    const dropdownOpen = Boolean(dropdown);
    const dropdownId = dropdownOpen ? "new-file-popover" : undefined;
    const globalContext = useGlobalContext();
    const { selectedCompany, userData } = globalContext;


    useEffect(() => {        
        if (userData?.userData?.permissions?.companies[selectedCompany.id]?.includes('BOX_EDIT')) {
            setDisabledEdit(false);
        } else {
            setDisabledEdit(true);
        }
      }, []);    

    const closeDropdown = () => {
        setDropdown(null);
    };
    const showDropdown = (event) => {
        setDropdown(event.currentTarget);
    };


    return (
        <>
            <IconButton aria-label="settings" onClick={(event) => { showDropdown(event) }}>
                <MoreVertIcon />
            </IconButton>

            <Popover
                id={dropdownId}
                open={dropdown}
                anchorEl={dropdown}
                onClose={closeDropdown}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {/*<MenuItem divider onClick={() => onClickAddSubBox(data.id,data.subtype)}>
            Crear Sub.Establecimiento
        </MenuItem>*/}
                <MenuItem divider disabled={disabledEdit} onClick={() => { props.onClickEditBox(props.data.id, props.data.subtype) }}>
                { t("component.popoverOption.edit") }
                </MenuItem>
                <MenuItem disabled divider>{ t("component.popoverOption.delete") }</MenuItem>
            </Popover>
        </>
    )
}



export default PopoverOptions;
