import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import BaseBasicForm from "../../../Layout/containers/BaseBasicForm";
import useGlobalContext from "../../../Hooks/useGlobalContext";
import leftIcon from '../../../Images/stepbystep/lefticons/nueva-cuenta.png';
import { useTranslation } from 'react-i18next';

function ActivateEstablecimiento(props) {
  const { t } = useTranslation();
  const [redirectToDash] = useState(false);

  const { refreshUserData } = useGlobalContext();
  useEffect(async () => {
    await refreshUserData();
  }, []);

  const handleOnClickDashboard = () => {
    window.location.href = "/assets";
    return;
  };

  return (
    <BaseBasicForm text={t("stepByStep.facility.titleLeft.complete")} leftIcon={leftIcon} {...props} showOriginoImage="true">
      {(redirectToDash) &&
        <Redirect
          to={{
            pathname: "/assets",
            state: {}
          }}
        />
      }
      <FontAwesomeIcon icon={faCheckCircle} size="3x" color="#3969E8" />
      <Typography variant="h3" component="h4" color="text.primary" align="center" className="mt-2 mb-4">
        {t("stepByStep.facility.facilityActivated")}
      </Typography>

      <Button variant="contained" className="mt-4" onClick={() => { handleOnClickDashboard() }}>
        {t("stepByStep.button.goDashboard")}
      </Button>
    </BaseBasicForm>
  );
}

export default ActivateEstablecimiento;
