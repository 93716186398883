import React, { useEffect, useState } from 'react';
import { Grid, Stepper, Step, StepButton, Divider } from '@mui/material';
import { ApiFetch } from '../../../Utils/ApiFetch';
import GridMassiveEdit from '../GridMassiveEdit';
import MassiveSummary from '../MassiveSummary';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import { useHistory } from 'react-router-dom';
import { ASSETS } from '../../../constants/paths';
import ModalMessage from '../../../Components/Feature/ModalMessage';
import EventsAll from '../../../pages/Events/EventsAll';
import ActivosBasicTable from '../../../Layout/components/Activos/ActivosBasicTable';
import { useTranslation } from 'react-i18next';
import { calculateLabelDates } from '../../../Utils/gridDefaultValues';

const ManualContainerEvent = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const steps = [
    t("stepByStep.massive.spreadsheet.eventStep.selectEvent"),
    t("stepByStep.massive.spreadsheet.eventStep.selectAssets"),
    t("stepByStep.massive.spreadsheet.eventStep.loadData"),
    t("stepByStep.massive.spreadsheet.eventStep.manualSummary")];
  const [assets, setAssets] = React.useState([]);
  const [pairColumns, setPairColumns] = React.useState([]);
  const [maxStepAvailable, setMaxStepAvailable] = React.useState(0);
  const [activeStep, setActiveStep] = useState(-1);
  const [waitModal, setWaitModal] = React.useState(false);
  const [modeEdit, setModeEdit] = useState('Add');
  const [excelUploadId, setExcelUploadId] = useState(-1);
  const [dataList, setDataList] = useState({});
  const [randomKeyContext, setRandomKeyContext] = useState(0);
  const [filterAttributeModel, setFilterAttributeModel] = useState([])
  const [eventTypeSelected, setEventTypeSelected] = useState(-1);

  const globalContext = useGlobalContext();
  const { userData } = globalContext;
  const timeZone = userData?.userData?.timezone;

  const handleStep = (step) => () => {
    if (step < maxStepAvailable) setActiveStep(step);
  };

  useEffect(() => {
    setRandomKeyContext(new Date().valueOf());
  }, []);

  useEffect(() => {
    if (props.action === 'update') {
      setModeEdit('Edit');
    } else {
      setModeEdit('Add');
      if (assets.length === 0) addRowForManual();
    }
  }, [props.action]);

  const addRowForManual = () => {
    ApiFetch(
      'parseExcel/addForManualUpload',
      globalContext,
      true,
      async (result) => {
        if (result.status === 200) {
          let resJson = await result.json();

          if (resJson?.ExcelUploadId && !isNaN(resJson?.ExcelUploadId)) {
            setExcelUploadId(resJson.ExcelUploadId);
          }
        }
      },
      (error) => {
        console.log('error addRowForManual', error);
      },
      {
        method: 'POST',
      }
    );
  };

  useEffect(() => {
    if (excelUploadId !== -1 || modeEdit === 'Edit') setActiveStep(0);
  }, [excelUploadId, modeEdit]);

  const handleSubmit = (list) => {
    setDataList(list);
    setActiveStep(3);
    setMaxStepAvailable(3);
  };

  const handleDataChange = (dataList, dataPairColumns) => {
    setAssets(dataList);
    setPairColumns(dataPairColumns);
  };

  const onSelectEvent = (event) => {
    setFilterAttributeModel(event.fields);
    setEventTypeSelected(event.eventType);
    setActiveStep(1);
  };

  const handleFinalSubmit = () => {
    let dataListTemp = [];

    if (props.action === 'update') {
      dataList.map((data) => {
        let dataTemp = { ...data };
        delete dataTemp.id;
        let dataTempNoValuesFilter = {};
        for (let key of Object.entries(dataTemp)) {
          if (dataTemp[key[0]] != null) {
            dataTempNoValuesFilter[key[0]] = dataTemp[key[0]];
          }
        }
        dataListTemp.push({ ...dataTempNoValuesFilter, pk: data.id });
      });
    }

    let attr =
      globalContext.getSessionDataByKey('models')[props.globalModel].attributes;
    if (props.action === 'add') {
      dataListTemp = [...dataList];
    }

    let dataListTempLabels = [];
    dataListTemp.map((value, index) => {
      let labelsTemp = {};
      Object.entries(value).map((it) => {
        const rowAttr = attr.find(
          (x) =>
            x.name === it[0] &&
            (x.type === 'select' || x.type === 'date' || x.type === 'dateTime' || x.type === 'multiselect')
        );
        if (rowAttr) {
          if (rowAttr.type === 'select') {
            let selectedItem = rowAttr.values.find((i) => i.key === it[1]);
            if (selectedItem) {
              labelsTemp[rowAttr.name] = selectedItem.label;
            }
          }
          if (rowAttr.type === 'multiselect') {
            let selectedItemLabels = [];
            it[1].split(',').map((i) => {
              let item = rowAttr.values.find((x) => x.key === i);
              if (item) {
                selectedItemLabels.push(item.label);
              }
            });
            labelsTemp[rowAttr.name] = selectedItemLabels.join(',');
          }
          if (rowAttr.type === 'date' || rowAttr.type === 'dateTime') {
            const { dateLabel, dateValue } = calculateLabelDates(
              it[1],
              timeZone
            );
            labelsTemp[rowAttr.name] = dateLabel;
            dataListTemp[index][it[0]] = dateValue;
          }
        }
        if (it[0] === '_eventDate_') {
          const { dateLabel, dateValue } = calculateLabelDates(it[1], timeZone);
          labelsTemp[it[0]] = dateLabel;
          dataListTemp[index][it[0]] = dateValue;
        }
      });
      dataListTempLabels.push(labelsTemp);
    });

    let dataObj = {
      values: dataListTemp,
      labels: dataListTempLabels,
      headerValues: {},
      action: props.action,
      pk: props.pk,
      objectType: props.objectType,
      pkObjectType: props.pkObjectType,
      pkReferer: props.pkReferer,
      holder: props.parentId,
      temporalDetailParentId: props.temporalDetailParentId,
      globalModel: props.globalModel,
      actionContext: { context: 'massive_manual_form', eventType: eventTypeSelected },

    };

    setWaitModal(true);
    ApiFetch(
      'objects/add/massive',
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          props.onUploadOk(res);
          setWaitModal(false);
          history.push({
            pathname: ASSETS,
            state: {
              toast: {
                mode: 'update',
                action: 'updated_massive',
              },
            },
          });
        } else {
          props.onUploadError(res);
        }
      },
      (error) => {
        props.onUploadError(error);
      },
      {
        method: 'POST',
        body: JSON.stringify(dataObj),
      }
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="mt-0">
        <Grid item xs={12} className="mt-2">
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={index < maxStepAvailable}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Divider
          variant="middle"
          className="mt-4"
          style={{ marginBottom: '5px' }}
        />
      </Grid>
      {activeStep === 0 && <EventsAll onSelect={onSelectEvent} />}
      {activeStep === 1 && (
        <Grid item xs={12}>
          <ActivosBasicTable
            preSelectedRows={[]}
            sessionContext={randomKeyContext}
            onSelectedRowsChange={(ret) => {}}
            exportConfig={{
              mode: true,
              caption: t('stepByStep.button.select'),
            }}
            exportFilterAction={(ret) => {
              if (true) {
                setAssets(ret.rows);
                setActiveStep(2);
              }
            }}
          />
        </Grid>
      )}

      {activeStep === 2 && (
        <GridMassiveEdit
          mode={modeEdit}
          data={assets}
          entityMode={props.entityMode}
          enableEventDate={true}
          eventType={props.eventType}
          pairColumns={pairColumns}
          excelUploadId={excelUploadId}
          filterAttributeModel={filterAttributeModel}
          onSubmit={handleSubmit}
          onSetAmountErrorsInitial={() => { }}
          globalModel={props.globalModel}
          pkObjectType={props.pkObjectType}
          onDataChange={handleDataChange}
          manualUpload={true}
        />
      )}
      {activeStep === 3 && (
        <MassiveSummary
          showControls={true}
          dataList={dataList}
          globalModel={props.globalModel}
          onFinalSubmit={handleFinalSubmit}
          amountErrorsInitial={0}></MassiveSummary>
      )}

      <ModalMessage
        open={waitModal}
        toggleModal={() => { }}
        modalText={t('component.processing')}
      />
    </Grid>
  );
};

export default ManualContainerEvent;
