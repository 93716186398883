import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ApiFetch } from '../../../Utils/ApiFetch';
import useGlobalContext from '../../../Hooks/useGlobalContext';

function AutocompleteField(props) {
  const globalContext = useGlobalContext();
  const { label, disabled, value } = props
  const [options, setOptions] = useState([])
  const [autocompleteDisabled, setAutocompleteDisabled] = useState(false)

  useEffect(() => {
    props.onlyWhenValues && Object.keys(props.onlyWhenValues).forEach(key => {
      if(!autocompleteDisabled && !props.onlyWhenValues[key].includes(props.fieldsValues[key])){
        setAutocompleteDisabled(true)
      }
    })
  },[])

  useEffect(() => {
    if(value?.length >= 4 && !autocompleteDisabled) {
      ApiFetch(
        `autocomplete/${props.id}/${props.objectType}/${props.pkObjectType}/${props.id}/${props.name}/${value}`,
        globalContext,
        true,
        async (res) => {
          if (res.status === 200) {
            let data = await res.json();
            setOptions(data)
          }
        },
        (error) => {
          console.log('error /autocomplete', error);
        },
        {
          method: 'GET'
        }
      );
    } else {
      setOptions([])
    }
  }, [globalContext, props.id, props.name, props.objectType, props.pkObjectType, value])

  const handleAutocomplete = (targetValue) => {
    const fields = options.filter(option => option.value === targetValue)

    if(fields.length > 0){
      const { value, ...remplacedFields} = fields[0]
      props.autocompleteRemplaceFields(props.name, targetValue, remplacedFields);
    } else {
      props.autocompleteRemplaceFields(props.name, targetValue, null);
    }
  }

  return (
    <>
      <Autocomplete
        freeSolo
        disabled={disabled}
        options={value?.length >= 4 ? options.map((option) => option.value) : []}
        value={value}
        onChange={(event, newValue) => {
          handleAutocomplete(newValue)
        }}
        renderInput={(params) =>
          <TextField
            {...params}
            size="small"
            label={label}
            value={value}
            onChange={(val) => handleAutocomplete(val.target.value)}
          />
        }
      />
    </>
  );
}

export default AutocompleteField;
